import request from '@/utils/request'

//车船预定 - 车船预定列表   /api/boat/boat_list
export  function boat_list(data){
    return request({
        url:'/api/boat/boat_list',
        method:'post',
        data
    })
}

//车船预定 - 获取车船.预定对象  /api/boat/load_boats
export  function load_boats(data){
    return request({
        url:'/api/boat/load_boats',
        method:'post',
        data
    })
}

//车船预定 - 取消车船预定   /api/boat/cancel_boats
export  function cancel_boats(data){
    return request({
        url:'/api/boat/cancel_boats',
        method:'post',
        data
    })
}

//车船预定 - 保存车船预定  /api/boat/save_boats
export  function save_boats(data){
    return request({
        url:'/api/boat/save_boats',
        method:'post',
        data
    })
}