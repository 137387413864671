/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true            是否在左侧菜单栏可见.一般404页面啊.或者一些权限的才隐藏       if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true     一般只有首页才会设置成总是可见          if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect      是否允许调回这个面包屑导航     if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'            必填项.跳转路径必须的  the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']   哪些角色可以显示本菜单  control the page roles (you can set multiple roles)
    title: 'title'        显示在面包屑跟左侧菜单栏的名字       the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'          左侧图标 id   the icon show in the sidebar
    noCache: true          是否缓存页面内容       if set true, the page will no be cached(default is false)
    affix: true         是否固定页签         if set true, the tag will affix in the tags-view
    breadcrumb: false        是否显示在面包屑导航    if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  高亮的菜单路径  if set path, the sidebar will highlight the path you set
  }
 */
const orderRouter = {
    path: '/order',
    component: Layout,
    redirect: 'noRedirect',
    name: 'order',
    meta: {
        title: '订单管理',
        right: ['1000']
    },
    children: [
        {
            path: "product_order",
            // component: ()=>import('@/views/conf/rate/index'),
            name: "ProductOrder",
            meta: { title: "产品订单",right:['1011'] },
            children: [
                {
                    path: 'room',
                    component: () => import('@/views/order/room/roomrs-list'),
                    name: 'RoomrsList',
                    meta: {title: ' 客房订单', right: ['1001']}
                },
                {
                    path: 'ticket',
                    component: () => import('@/views/order/ticket/ticket'),
                    name: 'order_ticket',
                    meta: {title: ' 票务订单', right: ['1002']}
                },
                {
                    path: 'catering',
                    component: () => import('@/views/order/catering/cateringlist'),
                    name: 'CateringList',
                    meta: {title: ' 餐饮订单', right: ['1006']}
                },
                {
                    path: 'meeting',
                    component: () => import('@/views/order/meeting/meetinglist'),
                    name: 'MeetingList',
                    meta: {title: ' 会议订单', right: ['1012']}
                },
                {
                    path: 'guide',
                    component: () => import('@/views/order/guide/guidelist'),
                    name: 'guidelist',
                    meta: { title: '导游订单',right:['1008'] }
                },
                {
                    path: 'spa',
                    component: () => import('@/views/order/spa/spalist'),
                    name: 'spalist',
                    meta: { title: 'SPA订单',right:['1009'] }
                },
                {
                    path: 'boat',
                    component: () => import('@/views/order/boat/boatlist'),
                    name: 'boatlist',
                    meta: { title: '车船订单' ,right:['1010']}
                },
                {
                    path: 'mall',
                    component: () => import('@/views/order/mall/malllist'),
                    name: 'MallList',
                    meta: { title: '商超订单' ,right:['1014']}
                }
            ]
        },
        {
            path: 'col',
            component: () => import('@/views/order/col/col'),
            name: 'order_col',
            meta: {title: '综合预订', right: ['1004']}
        },
        {
            path: 'kit',
            component: () => import('@/views/order/col/kitorder'),
            name: 'order_kit',
            meta: {title: '套餐预订', right: ['1003']}
        },
        {
            path: 'group',
            component: () => import('@/views/order/col/group'),
            name: 'order_group',
            meta: {title: '团队预订', right: ['1013']}
        },
        {
            path: 'cti',
            component: () => import('@/views/order/cti/cti-main'),
            name: 'CtiMain',
            meta: {title: 'CTI坐席' , right: ['1007']}
        },
        {
            path: 'ctioauth/:telno',
            component: () => import('@/views/order/cti/cti-main'),
            name: 'cticallin',
            meta: {title: 'CTI 电话呼叫'},
            hidden:true,
            props: true  //如果 props 被设置为 true，route.params 将会被设置为组件属性
        },
        // {
        //     path: 'auditing',
        //     component: () => import('@/views/order/col/auditing'),
        //     name: 'order_auditing',
        //     meta: {title: ' 审核列表',right:['1005']}
        // }
    ]
};

export default orderRouter
