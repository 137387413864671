<template>
  <div class="main-div">
    <el-tabs v-model="activeName">
      <el-tab-pane label="短信列表" name="logs">
        <el-table v-loading="loading" :data="tableData" style="width: 100%" max-height="400" border>
          <el-table-column prop="mobile" label="手机号" min-width="100"/>
          <el-table-column prop="date" label="日期" min-width="140"/>
          <el-table-column prop="content" label="内容" min-width="300"/>
        </el-table>
        <el-pagination :current-page="tableForm.pages.currentpage" :page-size="tableForm.pages.pagesize"
                       layout="sizes,total, prev, pager, next, jumper" :total="tableForm.pages.total" style="margin-top: 10px;float: right;"
                       background @current-change="handleCurrentChange" @size-change="handleSizeChange"/>
      </el-tab-pane>
      <el-tab-pane label="发送短信" name="send">
        <el-form ref="msgForm" :model="msgForm" :rules="msgRules" label-width="auto" class="search-form">
          <el-row :gutter="20">
            <el-col :md="6" :sm="12" :xs="24">
              <el-form-item prop="mobile" label="手机号码">
                <el-input v-model="msgForm.mobile" placeholder="请输入电话号码" clearable/>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="12" :xs="24">
              <el-form-item label="模板">
                <el-select v-model="msgForm.templateName" placeholder="请选择模板" @change="changeTemplate">
                  <el-option v-for="item in templates" :key="item.code" :label="item.code" :value="item.code"/>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :md="12" :sm="24" :xs="24">
              <el-form-item prop="content" label="短信内容">
                <el-input v-model="msgForm.content" type="textarea" :autosize="{ minRows: 5, maxRows: 5}"
                          :readonly="msgForm.templateName.length>0" placeholder="请输入内容"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button type="primary" icon="el-icon-s-comment" :loading="sendLoading" @click="handleSend">发送</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
  import {log_list, send_msg} from '@/api/common/msg';
  import {select_data} from '@/api/common/custom-data';
  import {select_res} from "@/utils/select_data_res";
  // import {load_content,send_msg} from '@/api/conf/templatesetup';
  import {load_content} from '@/api/common/msg';

  export default {
        name: "MsgView",
        props: {
            regno:{ type: String,default: ''},
            type:{ type: String,default: ''},
            id:{ type: Number,default: 0},
            mobile:{ type: String,default: ''},
            visible:{type: Boolean,default: true}
        },
        data(){
            let checkPhone = (rule, value, callback) => {
              if(value!=''){
                if (/^1[3|4|5|7|8][0-9]\d{8}$/.test(value)) {
                  callback();
                } else {
                  return callback(new Error('请输入正确的手机号'));
                }
              }
            };
            return {
                activeName:'logs',
                loading:false, sendLoading:false,
                tableData:[],
                templates:[],
                msgForm:{
                    templateName:'',mobile:this.mobile,content:'',type:this.type,regno:this.regno
                },
                tableForm:{
                  type:this.type,regno:this.regno,
                  pages:{total:0, currentpage:1, pagesize:20, sortname:'date', sortorder:'descending'}
                },
                msgRules: {
                    mobile: [{ required: true, message: '请输入手机号码', trigger: 'change' },
                      { validator: checkPhone, trigger: 'blur' }],
                    content: [{ required: true, message: '请输入内容', trigger: 'change' }]
                }
            }
        },
        watch:{
          visible: function(val){
            if(val){
              this.refreshTable();
            }
          },
          regno: function (val) {
            this.tableForm.regno = val;
          }
        },
        mounted: function(){
            this.loadTable();
            select_data({"keyname":"template","params":[{'key':'template','value':this.type}]}).then(response => {
                this.templates = select_res(response.data,"template");
            })
        },
        methods:{
            refreshTable(){
              this.tableForm.pages.currentpage = 1;
              this.loadTable();
            },
            loadTable(){
                this.loading = true;
                log_list(this.tableForm).then(response => {
                    this.tableData = response.data.records;
                    this.tableForm.pages.total = response.data.total;
                }).finally(() => { this.loading = false;})
            },
            handleCurrentChange(val){
              this.tableForm.pages.currentpage = val;
              this.loadTable();
            },
            handleSizeChange(val){
              this.tableForm.pages.pagesize = val;
              this.refreshTable();
            },
            changeTemplate(val){
              load_content({'template':val,'type':this.type,'id':this.id}).then(response => {
                this.msgForm.content = response.data.content;
              })
            },
            handleSend(){
              this.$refs['msgForm'].validate((valid) => {
                if (valid) {
                  this.sendLoading = true;
                  send_msg(this.msgForm).then(response => {
                    this.loadTable();
                    this.$msgbox({ title: '消息', message: response.data.message, confirmButtonText: '关闭'}).catch(() => {});
                  }).finally(()=>{
                    this.sendLoading = false;
                  });
                } else {
                  return false;
                }
              });
            }
        }
    }
</script>

<style scoped>

</style>