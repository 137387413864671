import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import { getToken } from '@/utils/auth'
import Vue from "vue"

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000, // request timeout
  headers: { 'Content-Type': 'application/json' }
});

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent

    config.baseURL=Vue.prototype.BASE_API;
    if (store.getters.token) {
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers['Authorization'] = getToken();
    }
    return config
  },
  error => {
    // do something with request error
    // console.log(JSON.stringify(error)); // for debug
    return Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data;
    if (res.code !== 200) {
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 10 * 1000
      });

      // if (res.code ==402) {
      //
      // }

      if (res.code === 401 ) {
        // to re-login
        //不选择了 直接重新登录
        MessageBox.alert("凭证失效.请重新登陆", "提示", {
          confirmButtonText: "确定",
          callback: () => {
            store.dispatch('user/resetToken').then(() => {
              location.reload()
            })
          }
        });
      }
      return Promise.reject(new Error(res.msg || 'Error'));
    } else {
      return res;
    }
  },
  error => {
    if(error.message!=null && error.message.indexOf('timeout')>=0){//timeout就重新登录
      MessageBox.confirm('操作超时.请重新登陆', '确认', {
        confirmButtonText: '重新登陆',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        store.dispatch('user/resetToken').then(() => {
          location.reload();
        })
      });
    }else{
      Message({
        message: error.message,
        type: 'error',
        duration: 10 * 1000
      })
    }
    return Promise.reject(error);
  }
);

export default service
