import request from '@/utils/request'

export function ticketrs_list(data) {
    return request({
        url: '/api/ticket/ticketrs_list',
        method: 'post',
        data
    })
}

export function load_ticketrs(data) {
    return request({
        url: '/api/ticket/load_ticketrs',
        method: 'post',
        data
    })
}

export function save_ticketrs(data) {
    return request({
        url: '/api/ticket/save_ticketrs',
        method: 'post',
        data
    })
}

export function cancel_ticketrs(data) {
    return request({
        url: '/api/ticket/cancel_ticketrs',
        method: 'post',
        data
    })
}

export function query_ticketinfo(data) {
    return request({
        url: '/api/ticket/query_ticketinfo',
        method: 'post',
        data
    })
}

export function resend_ticket(data) {
    return request({
        url: '/api/ticket/resend_ticket',
        method: 'post',
        data
    })
}

export function pay_ticketrs(data) {
    return request({
        url: '/api/ticket/pay_ticketrs',
        method: 'post',
        data
    })
}
//=============团购票操作API==========
export function gticketrs_list(data) {
    return request({
        url: '/api/gticket/gticketrs_list',
        method: 'post',
        data
    })
}

export function load_gticketrs(data) {
    return request({
        url: '/api/gticket/load_gticketrs',
        method: 'post',
        data
    })
}

export function gticketrs_change_channel(data) {
    return request({
        url: '/api/gticket/gticketrs_change_channel',
        method: 'post',
        data
    })
}

export function gticket_insert_sub(data) {
    return request({
        url: '/api/gticket/gticket_insert_sub',
        method: 'post',
        data
    })
}

export function save_gticketrs(data) {
    return request({
        url: '/api/gticket/save_gticketrs',
        method: 'post',
        data
    })
}

export function modify_gticketrs(data) {
    return request({
        url: '/api/gticket/modify_gticketrs',
        method: 'post',
        data
    })
}

export function gticketrs_cancel_one(data) {
    return request({
        url: '/api/gticket/gticketrs_cancel_one',
        method: 'post',
        data
    })
}

export function gticketrs_cancel_all(data) {
    return request({
        url: '/api/gticket/gticketrs_cancel_all',
        method: 'post',
        data
    })
}

export function print_gticketrs(data) {
    return request({
        url: '/api/gticket/print_gticketrs',
        method: 'post',
        data
    })
}

export function gticketrs_sub_list(data) {
    return request({
        url: '/api/gticket/gticketrs_sub_list',
        method: 'post',
        data
    })
}

export function gticketrs_profileinfo(data) {
    return request({
        url: '/api/gticket/gticketrs_profileinfo',
        method: 'post',
        data
    })
}

export function checkfail_gticketrs(data) {
    return request({
        url: '/api/gticket/checkfail_gticketrs',
        method: 'post',
        data
    })
}

