import request from '@/utils/request'

export function guide_list(data) {
    return request({
        url: '/api/guidesetup/guide_list',
        method: 'post',
        data
    })
}

export function delete_guide(data) {
    return request({
        url: '/api/guidesetup/delete_guide',
        method: 'post',
        data
    })
}

export function load_guide(data) {
    return request({
        url: '/api/guidesetup/load_guide',
        method: 'post',
        data
    })
}

export function save_guide(data) {
    return request({
        url: '/api/guidesetup/save_guide',
        method: 'post',
        data
    })
}
