<template>
  <div>
    <el-dialog v-dialogDrag title="BLOCK 资源查询"
               :visible.sync="visible"
               :show="show"
               width="70%"
               top="2vh"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :modal-append-to-body="false"
               :append-to-body="true"
               @close="$emit('update:show', false)"
               @opened="initDialog"
    >
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="日期">
          <el-date-picker
            v-model="dateselect"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :clearable="false"
          />
        </el-form-item>
        <el-form-item label="锁房">
          <el-select v-model="searchForm.block" placeholder="请选择锁房" filterable clearable>
            <el-option v-for="item in blocks" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
              <span style="float: left">{{ item.code }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="loadTable">查询</el-button>
          <el-button type="primary" :disabled="selectBlock===''" @click="doSelect">选择</el-button>
        </el-form-item>
      </el-form>

      <el-table ref="blocksTable" v-loading="loading" :data="blockdatas" max-height="350px" border highlight-current-row @current-change="loadBlockDetTable" >
        <el-table-column prop="code" label="锁房代码" min-width="120"/>
        <el-table-column prop="desc" label="锁房描述" min-width="300"/>
        <el-table-column prop="startdate" label="开始日期" min-width="100"/>
        <el-table-column prop="enddate" label="结束日期" min-width="100"/>
        <el-table-column prop="pmscode" label="PMS锁房代码" min-width="120"/>
      </el-table>

      <el-divider content-position="left"><strong>资源可用表</strong></el-divider>

      <el-table v-loading="detloading" :data="blockdets" style="width: 100%" max-height="400" border>
        <el-table-column prop="sku" label="房型" min-width="100"/>
        <el-table-column prop="skudesc" label="产品描述" min-width="200"/>
        <el-table-column prop="avl" label="剩余最小可用数" min-width="80"/>
      </el-table>

    </el-dialog>
  </div>
</template>

<script>
  import {select_data} from '@/api/common/custom-data';
  import {select_res} from "@/utils/select_data_res";
import {queryBlocks,queryBlockSku} from "@/api/order/order";
export default {
  name: "ZjBlockquery",
  components: {},
  props: {
    calcform: {
      type: Object,
      default: ()=>({
        block:'',
        productgroup:'',
        type:'R',
        arrdate:'2020-01-08',
        deptdate:'2020-01-10',
        num:1,
      })
    },
    block:{type:String,defult:''},
    products:{type:Array},  //对应外部组件要查询的房型
    type:{type:String,default:'R'},
    policy:{type:String,default:''},
    arrdate:{type:String},
    deptdate:{type:String},
    person:{type:Number,default:1},
    child:{type:Number,default:0},
    extrabad: {type:Number,default:0},
    show: {type: Boolean, default: false}
  },
  data() {
    return {
      searchForm:{
        block:this.block,
        arrdate:this.arrdate,
        deptdate:this.deptdate,
      },//Object.assign(this.defaultform,this.calcform), //
      blockdatas:[],
      blockdets:[],
      queryProducts:this.products,  //对应外部组件要查询的房型
      selectBlock:'',
      visible:this.show,
      loading:false,
      detloading:false,
      blocks:[]
    };
  },
  computed:{
    dateselect:{
      get:function(){
        return this.searchForm.arrdate!=null && this.searchForm.deptdate!=null?[this.searchForm.arrdate,this.searchForm.deptdate]:[];
      },
      set:function(dateValue){
        this.searchForm.arrdate = dateValue!=null&&dateValue.length>0?dateValue[0]:'';
        this.searchForm.deptdate = dateValue!=null&&dateValue.length>1?dateValue[1]:'';
      }
    }
  },
  watch:{
    calcform(){
      this.searchForm=this.calcform;
    },
    arrdate(){
      this.searchForm.arrdate=this.arrdate;
    },
    deptdate(){
      this.searchForm.deptdate=this.deptdate;
    },
    products(){
      this.queryProducts=this.products;
    },
    show: function (val) {
      this.visible=this.show;
    }
  },
  mounted: function() {
    // this.loadTable();
    // this.reindex();
    select_data({"keyname": "block"}).then(response => {
      this.blocks = select_res(response.data,"block");
    });
  },
  methods: {
    initDialog(){
      // Objects.assign(this.searchForm,this.calcform)
      // this.searchForm.arrdate=this.calcform.arrdate;
      // this.searchForm.deptdate=this.calcform.deptdate;
      this.dateselect=[this.calcform.arrdate,this.calcform.deptdate];
      this.searchProducts=this.products;
      this.loadTable();
    },
    loadTable() {
      let _searchForm={...this.searchForm};
      // startLoading('请等待.查询中');
      this.loading = true;
      queryBlocks(_searchForm).then(
          response=>{
            this.blockdatas=response.data.dataList;
            if (this.blockdatas.length>0) {
              this.$refs.blocksTable.setCurrentRow(this.blockdatas[0]);
            }
          }
      ).finally(() => {
        // hideLoading();
        this.loading = false;
      });
    },
    loadBlockDetTable(row){
      let loadblock=row!=null?row.code:'';
      this.selectBlock=row.code;
      queryBlockSku({
        "block":loadblock,
        "arrdate":this.searchForm.arrdate,
        "deptdate":this.searchForm.deptdate,
        "skus":this.products,
        "num":1
      }).then(response => {
        this.blockdets = response.data.resources;
      }).finally(() => { this.kitdetailloading = false;})
    },
    // selectRate(row,index) { //返回查询的日期范围.产品.价格码
    //   let pushitem={
    //     block:this.selectBlock
    //   };
    //   this.$emit("selectRate", pushitem);
    //   this.$emit('update:show',false);
    //
    // },
    doSelect(){
      // this.$emit('update:visible',false);
      let pushitem={
        block:this.selectBlock
      };
      this.$emit("selectRate", pushitem);
      this.visible=false; //触发dialog 关闭的同时. 也触发外层变量关闭
      this.$emit('update:show',false);
    }
  }
};
</script>

<style scoped>

</style>
