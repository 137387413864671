<template>
  <div class="main-div">
    <el-container>
      <el-header height="40px">{{ showTitle }}<span style="float:right">{{ showStatus }}</span></el-header>
      <el-main>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px" class="base-form">
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="联系人" prop="name">
                <el-input ref="nameinput" v-model="form.name" maxlength="60" @blur="checkNameExist">
                  <i slot="suffix" class="el-input__icon el-icon-s-grid" @click="showProfileDialog=true"/>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="电话号码" prop="telephone">
                <el-input ref="telinput" v-model="form.telephone" maxlength="20" clearable/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="渠道" prop="subchannel">
                <el-select v-model="form.subchannel" placeholder="请选择渠道" filterable>
                  <el-option v-for="item in subchannels" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="SPA站点" prop="spasite">
                <el-select v-model="form.spasite" placeholder="请选择站点" filterable @change="changeSite">
                  <el-option v-for="item in spasites" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!--
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="SPA服务" prop="ticket">
                <el-select v-model="form.ticket" placeholder="请选择SPA服务" filterable @change="calcPrice">
                  <el-option v-for="item in tickets" :key="item.code" :label="item.desc" :value="item.code">
                    <span style="float: left">{{ item.desc }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.group }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            -->
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="付款方式">
                <el-select v-model="form.payment" placeholder="请选择付款方式" filterable>
                  <el-option v-for="item in payments" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <!--
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="价格">
                <el-input v-model="form.totalprice" :readonly="!form.fix">
                  <template slot="append">
                    <el-tooltip class="item" effect="dark" content="固定价" placement="top-start">
                      <el-checkbox v-model="form.fix" @change="calcPrice"/>
                    </el-tooltip>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            -->
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="创建信息">
                <el-input v-model="createDateTime" readonly/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="服务日期" prop="date">
                <el-date-picker v-model="form.date" align="right" type="date" :picker-options="pickerOptions"
                                :clearable="false" value-format="yyyy-MM-dd"/>
              </el-form-item>
            </el-col>
            <!--
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="开始时间">
                <el-time-select v-model="form.starttime" :picker-options="{ start: '00:00', step: '00:01', end: '23:59' }"/>
              </el-form-item>
            </el-col>
            -->
            <el-col :md="16" :sm="16" :xs="24">
              <el-form-item label="备注">
                <el-input v-model="form.info" type="textarea" :rows="1" placeholder="请输入备注内容"/>
              </el-form-item>
            </el-col>
          </el-row>
          <!--
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="结束时间">
                <el-time-select v-model="form.endtime" :picker-options="{ start: '00:00', step: '00:01', end: '23:59',minTime: form.starttime }"/>
              </el-form-item>
            </el-col>
          </el-row>
          -->
          <el-row :gutter="10">
            <el-col :md="24" :sm="24" :xs="24">
              <el-form-item label="总价">
                <span class="pricelabel">{{ form.totalprice }}</span>
                <el-button style="float: right;" type="primary" icon="el-icon-edit-outline" :disabled="form.spasite==null||form.spasite.length<=0||form.status!='N'||history" @click="handleNewService">新增服务</el-button>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-table v-loading="loading" :data="tableData" style="width: 100%" max-height="300" border :row-class-name="tableRowClassName" >
              <el-table-column prop="code" label="服务代码" min-width="80"/>
              <el-table-column prop="desc" label="服务描述" :show-overflow-tooltip="true" min-width="120"/>
              <el-table-column prop="starttime" label="开始时间" min-width="80"/>
              <el-table-column prop="endtime" label="结束时间" min-width="80"/>
              <el-table-column prop="totalprice" label="价格" min-width="80"/>
              <el-table-column prop="status" label="状态" min-width="80" :formatter="statusFormatter"/>
              <el-table-column fixed="right" prop="operation" label="操作" min-width="180">
                <template slot-scope="operatescope">
                  <el-button size="mini" type="primary" :disabled="form.status!='N'||history||operatescope.row.status=='C'"
                             @click="handleEditService(operatescope.$index,operatescope.row)">编辑</el-button>
                  <el-button size="mini" type="danger" :disabled="form.status!='N'||history||operatescope.row.status!='N'||form.history"
                             @click="handleCancelService(operatescope.$index,operatescope.row)">取消</el-button>
                  <el-button size="mini" type="danger" :disabled="form.status!='N'||history||operatescope.row.status=='F'||form.history"
                             @click="handleDeleteService(operatescope.$index)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-row>
        </el-form>
      </el-main>
      <el-footer height="40px">
        <el-button type="primary" icon="el-icon-edit-outline" :loading="buttonLoading" :disabled="form.status!='N'||history" @click="handleSave">保存</el-button>
        <el-button type="danger" icon="el-icon-warning-outline" :loading="buttonLoading"
                   :disabled="form.sqlid<=0||form.status!='N'||history" @click="handleCancel">取消</el-button>
        <el-button v-if="gotocol" type="primary" icon="el-icon-refresh-left" :disabled="form.sqlid<=0||form.colno==''"
                   @click="gotoCol">综合预定</el-button>
      </el-footer>
    </el-container>
    <!-- 窗口Dialog -->
    <el-dialog v-dialogDrag title="选择档案" :visible.sync="showProfileDialog" :close-on-click-modal="false" width="85%" append-to-body>
      <ProfileSelect :profileid="form.profileid" :name="form.name" :telno="form.telephone" :type="['']" :visible="showProfileDialog" @selectValue="selectProfile"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>

    <el-dialog v-dialogDrag title="SPA服务" :visible.sync="showServiceView" :close-on-click-modal="false" append-to-body>
      <el-form ref="serviceForm" :model="serviceForm" :rules="serviceRules" label-width="auto">
        <el-form-item label="服务代码" prop="code">
          <el-select v-model="serviceForm.code" placeholder="请选择服务代码" filterable :disabled="!serviceNew" @change="calcPrice">
            <el-option v-for="item in tickets" :key="item.code" :label="item.desc" :value="item.code">
              <span style="float: left">{{ item.desc }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.group }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开始时间">
          <el-time-select v-model="serviceForm.starttime" :picker-options="{ start: '00:00', step: '00:01', end: '23:59' }"/>
        </el-form-item>
        <el-form-item label="结束时间">
          <el-time-select v-model="serviceForm.endtime" :picker-options="{ start: '00:00', step: '00:01', end: '23:59',minTime: serviceForm.starttime }"/>
        </el-form-item>
        <el-form-item label="价格">
          <el-input v-model="serviceForm.totalprice" style="width: 50%" :readonly="!serviceForm.fix">
            <template slot="append">
              <el-checkbox v-model="serviceForm.fix" @change="calcPrice">固定价</el-checkbox>
            </template>
          </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSaveService">保存</el-button>
        <el-button @click="showServiceView=false">退出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {select_data} from '@/api/common/custom-data';
import {getTicketStatus} from '@/utils/status-format';
import {select_res} from "@/utils/select_data_res";
import '@/styles/pagecontainer.scss';
import ProfileSelect from "@/views/common/profile-select";
import {load_spas,save_spas,cancel_spas} from '@/api/order/spa';
import {name_exist} from '@/api/profile/profile';

export default {
  name:'spadetail',
  components: {ProfileSelect},
  props: {
    colno:{ type: String,default: ''},
    spaid:{ type: Number,default: 0},
    history:{type: Boolean,default: false},
    visible:{type: Boolean,default: true},
    gotocol:{type: Boolean,default: false}//是否显示跳转综合预定按钮
  },
  data() {
    return {
      form:{},
      pickerOptions: {
        disabledDate(time) { return time.getTime() < Date.now() - 8.64e7; },//不能选之前的日期
        shortcuts: [{ text: '今天', onClick(picker) { picker.$emit('pick', new Date()); } }]
      },
      rules: {
        name: [{ required: true, message: '请输入联系人姓名', trigger: 'change' }],
        telephone: [{ required: true, message: '请输入电话号码', trigger: 'change' }],
        spasite: [{ required: true, message: '请选择站点', trigger: 'change' }],
        // ticket: [{ required: true, message: '请选择SPA服务', trigger: 'change' }],
        subchannel: [{ required: true, message: '请选择渠道', trigger: 'change' }],
        date: [{ required: true, message: '请选择服务日期', trigger: 'change' }]
      },
      serviceRules: {
        code: [{ required: true, message: '请选择SPA服务', trigger: 'change' }]
      },
      subchannels:[],payments:[],spasites:[],tickets:[],
      showProfileDialog:false,buttonLoading:false,tableData:[],loading:false,
      showServiceView:false,serviceNew:true,editIndex:0,
      serviceForm:{}
    }

  },
  computed:{
    showTitle(){ return this.form.sqlid!=null&&this.form.sqlid>0?'编辑SPA订单  订单号: '+this.form.bookingid+' 综合订单号: '+this.form.colno+
            (this.form.interfaceid!=''?'  线下订单号: '+this.form.interfaceid:''):'新建SPA订单'},
    showStatus(){ return this.form.sqlid!=null&&this.form.sqlid>0?getTicketStatus(this.form.status):''},
    createDateTime:{
      get:function(){
        return this.form.createdate==null||this.form.createdate=='1900-01-01'?this.form.creator
                :this.form.createdate+' '+this.form.createtime+ ' '+this.form.creator;
      }
    }
  },
  watch:{
    visible: function(val){
      if(val){
        this.initDialog();
      }
    }
  },
  mounted: function(){
    this.$refs.nameinput.focus();
    select_data({"keyname":"subchannel,payment,spaticket,spasite"}).then(response => {
      this.subchannels = select_res(response.data,"subchannel");
      this.payments = select_res(response.data,"payment");
      this.spasites = select_res(response.data,"spasite");
    });
    this.initDialog();
  },
  methods: {
    initDialog(){
      this.loading = true;
      load_spas({"sqlid":this.spaid,"colno":this.colno,"history":this.history}).then(response => {
        this.form = response.data;
        this.tableData = this.form.services!=null&&this.form.services.length>0?eval(this.form.services):[];
        if(this.form.spasite.length>0){
          select_data({"keyname":"spaticket","params":[{'key':'spaticket','value':this.form.spasite}]}).then(response => {
            this.tickets = select_res(response.data,"spaticket");
          });
        }
      }).catch(() => {
        this.$emit('update:visible',false);
      }).finally(() => { this.loading = false;})
    },
    statusFormatter(row){
      return getTicketStatus(row.status);
    },
    checkNameExist(){
      if(this.form.profileid==''&&this.form.name.length>0){
        name_exist({"name":this.form.name}).then(response => {
          if(response.data){
            this.showProfileDialog = true;
          }
        })
      }
    },
    changeSite(val){
      if(this.tableData.length>0){
        this.$message({ message: '服务已经生成,不允许修改站点', type: 'warning'});
      }else{
        if(val.length>0){
          select_data({"keyname":"spaticket","params":[{'key':'spaticket','value':val}]}).then(response => {
            this.tickets = select_res(response.data,"spaticket");
          })
        }else{
          this.tickets = [];
        }
        // this.form.ticket = '';
      }
    },
    selectProfile(selectValue){
      this.showProfileDialog = false;
      this.form.profileid = selectValue.profileid;
      this.form.name = selectValue.name;
      this.form.telephone = selectValue.mobile;
      this.form.idnumber = selectValue.idnum;
    },
    calcPrice(){
      if(!this.serviceForm.fix) {
        let price = 0.00;
        let spaTicket = this.serviceForm.code;
        if(spaTicket.length>0){
          this.tickets.forEach(function(val){
            if(val.code==spaTicket){
              price = val.group;
            }
          });
        }
        this.serviceForm.totalprice = price;
      }
    },
    handleSave(){
      if(this.tableData.length<=0){
        this.$message({ message: '服务不能为空', type: 'warning'});
      }else{
        this.form.services = JSON.stringify(this.tableData);
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.buttonLoading = true;
            save_spas(this.form).then(response => {
              this.$msgbox({ title: '消息', message: '保存成功! 订单号:'+response.data.bookingid, confirmButtonText: '关闭'}).catch(() => {});
              this.$emit('refresh');
              if(!this.gotocol){//如果可以跳转到综合预定,保存后界面不关
                this.$emit('update:visible', false);
              }else{
                this.form = response.data;
              }
            }).finally(()=>{
              this.buttonLoading = false;
            })
          } else {
            return false;
          }
        });
      }
    },
    handleCancel(){
      this.$confirm('确定取消?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
        this.buttonLoading = true;
        cancel_spas({"sqlid":this.form.sqlid}).then(() => {
            this.$message({ message: '取消成功!', type: 'success'});
           this.$emit('refresh');
           this.$emit('update:visible',false);
        }).finally(()=>{
          this.buttonLoading = false;
        })
      }).catch(() => {});
    },
    tableRowClassName({row}){
      if(row.status==='C'){
        return 'cancel-row';
      }
      return null;
    },
    calcTotalPrice(){
      let price = 0.00;
      this.tableData.forEach(function(row){
        if(row.status!='C'){
          price += parseFloat(row.totalprice);
        }
      });
      this.form.totalprice = price;
    },
    handleNewService(){
      this.serviceNew = true;
      this.serviceForm = {code:'',desc:'',starttime:'',endtime:'',totalprice:0.00, fix:false, status: 'N'};
      this.showServiceView = true;
    },
    handleEditService(index,row){
      this.serviceNew = false;
      this.editIndex = index;
      this.serviceForm = {code:row.code,desc:row.desc,starttime:row.starttime,endtime:row.endtime,
        totalprice:row.totalprice, fix:row.fix, status: row.status};
      // this.serviceForm = row; //这样会连引用也改 在form修改时同时修改表格
      this.showServiceView = true;
    },
    handleSaveService(){
      this.$refs['serviceForm'].validate((valid) => {
        if (valid) {
          if(this.serviceNew){
            let spaDesc = '';
            let spaTicket = this.serviceForm.code;
            if(spaTicket.length>0){
              this.tickets.forEach(function(val){
                if(val.code==spaTicket){
                  spaDesc = val.desc;
                }
              });
            }
            this.serviceForm.desc = spaDesc;
            this.tableData.splice(this.tableData.length,0,this.serviceForm);
          }else{
            this.tableData.splice(this.editIndex,1,this.serviceForm);
          }
          this.calcTotalPrice();
          this.showServiceView = false;
        }
      });
    },
    handleCancelService(index,row){
      row.status = 'C';
      this.tableData.splice(index,1,row);
      this.calcTotalPrice();
    },
    handleDeleteService(index){
      this.tableData.splice(index,1);
      this.calcTotalPrice();
    },
    gotoCol(){
      this.$emit('update:rscolno',this.form.colno);
      this.$emit('gotoCol');
    }
  }
}
</script>

<style>
  .pricelabel{
    color:#c11a25;
    font-size: 22px;
    font-weight: bolder;
  }
  .el-table .cancel-row {
    background: #FFEBEE;
  }
</style>
