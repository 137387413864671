import request from '@/utils/request'

export function roomrs_list(data) {
    return request({
        url: '/api/order/roomrslist',
        method: 'post',
        data
    })
}

export function load_roomrs(data) {
    return request({
        url: '/api/order/loadroomrs',
        method: 'post',
        data
    })
}

export function save_roomrs(data) {
    return request({
        url: '/api/order/saveroomrs',
        method: 'post',
        data
    })
}

export function cancel_roomrs(data) {
    return request({
        url: '/api/order/cancelroomrs',
        method: 'post',
        data
    })
}

export function queryrp(data) {
    return request({
        url: '/api/order/queryrp',
        method: 'post',
        data
    })
}


export function queryrptabs(data) {
    return request({
        url: '/api/order/queryrptabs',
        method: 'post',
        data
    })
}


export function batchorder(data) {
    return request({
        url: '/api/order/batchorder',
        method: 'post',
        data
    })
}


export function calcbatchprice(data) {
    return request({
        url: '/api/order/batchprice',
        method: 'post',
        data
    })
}

export function loadCtiData(data) {
    return request({
        url: '/api/order/loadctidata',
        method: 'post',
        data
    })
}

export function queryBlocks(data) {
    return request({
        url: '/api/order/queryblocks',
        method: 'post',
        data
    })
}

export function queryBlockSku(data) {
    return request({
        url: '/api/order/queryblocksku',
        method: 'post',
        data
    })
}

