<template>
  <div class="colpay">
    <div style="margin-bottom: 10px">
      <el-button type="primary" icon="el-icon-edit-outline" :disabled="history" @click="openPay(0)">新增</el-button>
      <el-button icon="el-icon-refresh" @click="refresh(true)">刷新</el-button>
    </div>
    <el-table v-loading="loading" :data="tableData" style="width: 100%" :summary-method="tableSummary" :show-summary="summary!=''"
              :row-class-name="tableRowClassName" max-height="500" border @sort-change="handleSortChange">
      <el-table-column prop="status" label="状态" min-width="80" sortable="custom">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status==='R' ? 'danger' : 'primary'" disable-transitions>{{ statusFormatter(scope.row.status) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="姓名" min-width="120" :show-overflow-tooltip="true" sortable="custom"/>
      <el-table-column prop="telephone" label="电话" min-width="100"/>
      <el-table-column prop="amount" label="预付金额" min-width="90"/>
      <el-table-column prop="hbamount" label="实付金额" min-width="90"/>
      <el-table-column prop="refund" label="退款金额" min-width="90"/>
      <el-table-column prop="debit" label="扣款金额" min-width="90"/>
      <!--
            <el-table-column prop="payname" label="付款人" min-width="120"/>
            -->
      <el-table-column prop="payment" label="付款方式" min-width="100" sortable="custom"/>
      <el-table-column prop="paydate" label="付款时间" min-width="100" sortable="custom"/>
      <!--
            <el-table-column prop="type" label="支付渠道" min-width="80" :formatter="payChannelFormatter"/>
            <el-table-column prop="channel" label="渠道" min-width="100"/>
            -->
      <el-table-column prop="subchannel" label="二级渠道" min-width="100"/>
      <el-table-column prop="colno" label="综合订单号" min-width="150"/>
      <!--
            <el-table-column prop="serialno" label="流水号" min-width="230"/>
            -->
      <el-table-column prop="payno" label="付款单号" min-width="150"/>
      <el-table-column fixed="right" prop="operation" label="操作" min-width="80">
        <template slot-scope="operatescope">
          <el-button size="mini" type="primary" @click="openPay(operatescope.row.sqlid)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :current-page="form.pages.currentpage" :page-size="form.pages.pagesize"
                   layout="total, prev, pager, next, jumper" :total="form.pages.total" style="margin-top: 10px;float: right;"
                   background @current-change="handleCurrentChange"/>

    <el-dialog v-dialogDrag :visible.sync="showPayDetailDialog" :close-on-click-modal="false" width="70%" append-to-body>
      <PayDetail ref="paydetail" :payid="payid" :colno="form.colno" :history="form.history" :visible.sync="showPayDetailDialog" @refresh="refresh(false)"/>
    </el-dialog>
  </div>
</template>

<script>
    import {pay_list} from '@/api/order/pay';
    import {getPrepayStatus} from '@/utils/status-format';
    import PayDetail from '@/views/prepay/paydetail';

    export default {
        name: "ColPay",
        components: { PayDetail },
        props: {
            colno:{ type: String,default: ''},
            custom:{ type: String,default: ''},//不为空可以自定义搜索框
            summary:{ type: String,default: ''},//不为空可以进行统计
            history:{type: Boolean,default: false}
        },
        data() {
            return {
                form:{
                    // paydate:formatDate(new Date()), name:'', serialno:'', status:['P','A'],
                    // payment:'', subchannel:'',type:'',
                    colno:this.colno, history:this.history,
                    pages:{total:0, currentpage:1, pagesize:10, sortname:'paydate,colno', sortorder:'ascending'}
                },
                // payments:[],subchannels:[],
                tableData: [],
                loading:false, showPayDetailDialog:false, payid:0
            }
        },
        watch: {
            colno: function(val){
                this.form.colno = val;
                if(val!=null && val.length>0){
                    this.form.status = [];
                }
            },
            history: function(val){ this.form.history = val; }
        },
        mounted: function(){
            // if(this.custom==''){
            //     this.$refs.serialnoinput.focus();
            //     select_data({"keyname":"payment,subchannel"}).then(response => {
            //         this.payments = select_res(response.data,"payment");
            //         this.subchannels = select_res(response.data,"subchannel");
            //     });
            // }
            this.loadTable();
        },
        methods:{
            statusFormatter(val){
                return getPrepayStatus(val);
            },
            tableSummary(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    if (column.property==='hbamount'||column.property==='refund'||column.property==='debit') {//预付金额不用累加
                        const values = data.map(function(item){
                            if(column.property==='hbamount'||column.property==='refund'||column.property==='debit'){
                                return Number(item[column.property]);
                            }else{
                                return 0;
                            }
                        });
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        if (column.property==='hbamount'||column.property==='refund'||column.property==='debit') {//总价列合计保留两位小数
                            sums[index] = sums[index].toFixed(2);
                        }
                    }
                });
                return sums;
            },
            tableRowClassName({row}){
                if(row.status==='R'){
                    return 'cancel-row';
                }
                return null;
            },
            handleCurrentChange(val){
                this.form.pages.currentpage = val;
                this.loadTable();
            },
            handleSortChange(column){
              this.form.pages.sortname = column.prop;
              this.form.pages.sortorder = column.order;
              this.loadTable();
            },
            loadTable(){
                this.loading = true;
                pay_list(this.form).then(response => {
                    this.tableData = response.data.records;
                    this.form.pages.total = response.data.total;
                }).finally(() => { this.loading = false;})
            },
            refresh(search){//刷新的时候也把col_rs刷一下
                if(search||!this.form.history){
                    this.loadTable();
                    this.$emit('refreshCol');
                }
            },
            openPay(id) {
                this.payid = id;
                this.showPayDetailDialog = true;
            }
        }
    }
</script>

<style scoped>
    .el-table .cancel-row {
        background: #FFEBEE;
    }
</style>