<template>
  <div class="hotelpointDiv">
    <el-form :inline="true" :model="form">
      <el-form-item label="指标">
        <el-select v-model="form.index" placeholder="请选择指标" multiple collapse-tags clearable>
          <el-option label="出租率" value="3"/>
          <el-option label="占用房间数" value="1"/>
          <el-option label="占用人数" value="2"/>
          <el-option label="平均房价" value="9"/>
          <el-option label="总收入" value="77"/>
          <el-option label="房费收入" value="7"/>
          <el-option label="餐饮收入" value="62"/>
          <el-option label="门票收入" value="900"/>
          <el-option label="商超收入" value="901"/>
          <el-option label="会议收入" value="75"/>
          <el-option label="当前占用(房数)" value="59"/>
          <el-option label="当前占用(人数)" value="60"/>
          <el-option label="当前占用率" value="61"/>
          <el-option label="可卖房" value="31"/>
          <el-option label="总房数" value="28"/>
          <el-option label="预计到达(房数)" value="35"/>
          <el-option label="实际到达(房数)" value="10"/>
          <el-option label="预计到达(人数)" value="36"/>
          <el-option label="实际到达(人数)" value="11"/>
          <el-option label="预计离店(房数)" value="45"/>
          <el-option label="实际离店(房数)" value="43"/>
          <el-option label="预计离店(人数)" value="46"/>
          <el-option label="实际离店(人数)" value="44"/>
          <el-option label="散客(房数)" value="22"/>
          <el-option label="散客(人数)" value="23"/>
          <el-option label="团体成员(房数)" value="24"/>
          <el-option label="团体成员(人数)" value="25"/>
          <el-option label="纯预留(房数)" value="26"/>
          <el-option label="纯预留(人数)" value="27"/>
          <el-option label="已分房(房数)" value="37"/>
          <el-option label="已分房(人数)" value="38"/>
          <el-option label="当天预订当天到(房数)" value="39"/>
          <el-option label="当天预订当天到(人数)" value="40"/>
          <el-option label="上门客人(房数)" value="41"/>
          <el-option label="上门客人(人数)" value="42"/>
          <el-option label="续住(房数)" value="47"/>
          <el-option label="续住(人数)" value="48"/>
          <el-option label="提前离店(房数)" value="49"/>
          <el-option label="提前离店(人数)" value="50"/>
          <el-option label="日用房(房数)" value="51"/>
          <el-option label="日用房(人数)" value="52"/>
        </el-select>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker v-model="dateselect" type="daterange" align="right" unlink-panels
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        value-format="yyyy-MM-dd" :clearable="false"/>
      </el-form-item>
      <el-form-item label="对比">
        <el-switch v-model="form.showCompare" active-color="#13ce66" inactive-color="#ff4949"/>
      </el-form-item>
      <el-form-item v-if="form.showCompare">
        <el-date-picker v-model="comparedateselect" type="daterange" align="right" unlink-panels
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        value-format="yyyy-MM-dd" :clearable="false" :picker-options="pickerOptions"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="loadTable">查询</el-button>
        <el-button type="primary" icon="el-icon-download" :disabled="pointData.length<=0" @click="exportExcel">导出</el-button>
      </el-form-item>
      <el-table id="hotelPointTable" v-loading="loading" :data="pointData" style="width: 100%" :row-class-name="tableRowClassName" max-height="500" border>
        <el-table-column v-for="(item,index) in pointHead" :key="index" :fixed="index===0" :prop="item.colcode" :label="item.colname" width="120"/>
      </el-table>
    </el-form>
  </div>
</template>

<script>
    import {formatDate, lastYearDate} from '@/utils/calcdate';
    import {hotelpoint_data} from '@/api/statistic/statistic';
    import FileSaver from "file-saver";
    import XLSX from "xlsx";

    export default {
        name: "sum_hotelpoint",
        props: {
            visible:{type: Boolean,default: true},
        },
        data(){
            return {
                form:{
                    begin: formatDate(new Date()),
                    end: formatDate(new Date()),
                    index:['3','1','2','9','7','62','900','901','75','77'],
                    showCompare:false,
                    comparebegin: lastYearDate(),
                    compareend: lastYearDate(),
                },
                pickerOptions: {
                    // shortcuts: [{ text: '前一个月', onClick(picker) {
                    //   picker.$emit('pick', [reckonDayStr(this.form.begin,-30),reckonDayStr(this.form.end,-30)]);
                    // } }]
                  disabledDate(time) { return time.getTime() > Date.now() - 8.64e7; }
                },
                loading:false,pointData:[],pointHead:[]
            }
        },
        computed:{
            dateselect:{
                get:function(){
                    return this.form.begin!=null && this.form.end!=null?[this.form.begin,this.form.end]:[];
                },
                set:function(dateValue){
                    this.form.begin = dateValue!=null&&dateValue.length>0?dateValue[0]:'';
                    this.form.end = dateValue!=null&&dateValue.length>1?dateValue[1]:'';
                }
            },
            comparedateselect:{
              get:function(){
                return this.form.comparebegin!=null && this.form.compareend!=null?[this.form.comparebegin,this.form.compareend]:[];
              },
              set:function(dateValue){
                this.form.comparebegin = dateValue!=null&&dateValue.length>0?dateValue[0]:'';
                this.form.compareend = dateValue!=null&&dateValue.length>1?dateValue[1]:'';
              }
            }
        },
        watch:{
            visible: function(val){
                if(val){
                    this.form = {
                        begin: formatDate(new Date()),
                        end: formatDate(new Date()),
                        index:['3','1','2','9','7','62','900','901','75','77']
                    };
                    this.pointData = [];
                    this.pointHead = [];
                }
            }
        },
        methods:{
            loadTable(){
                if(this.form.index.length>0 && this.form.begin.length>0 && this.form.end.length>0){
                    this.loading = true;
                    hotelpoint_data(this.form).then(response => {
                        this.pointData = response.data.pointData;
                        this.pointHead = response.data.pointHead;
                    }).finally(() => { this.loading = false;})
                }else{
                    this.pointData = [];
                    this.pointHead = [];
                }
            },
            exportExcel(){
                let xlsxParam = { raw: true };//转换成excel时，使用原始的格式
                /* 从表生成工作簿对象 */
                let fix = document.querySelector('.el-table__fixed');
                let wb;
                if (fix) {
                  wb = XLSX.utils.table_to_book(document.querySelector('#hotelPointTable').removeChild(fix),xlsxParam);
                  document.querySelector('#hotelPointTable').appendChild(fix);
                } else {
                  wb = XLSX.utils.table_to_book(document.querySelector('#hotelPointTable'),xlsxParam);
                }
                /* 从表生成工作簿对象 */
                // let wb = XLSX.utils.table_to_book(document.querySelector("#hotelPointTable"),xlsxParam);
                /* 获取二进制字符串作为输出 */
                let wbout = XLSX.write(wb, {
                    bookType: "xlsx",
                    bookSST: true,
                    type: "array"
                });
                try {
                    FileSaver.saveAs(
                        //Blob 对象表示一个不可变、原始数据的类文件对象。
                        //Blob 表示的不一定是JavaScript原生格式的数据。
                        //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
                        //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
                        new Blob([wbout], { type: "application/octet-stream" }),
                        //设置导出文件名称
                        "酒店指标"+this.form.begin+".xlsx"
                    );
                } catch (e) {
                    this.$message.error("导出失败");
                }
                return wbout;
            },
            tableRowClassName({ row, rowIndex }) {
              if(this.form.showCompare && (rowIndex-2)%3===0) {
                return 'total-row';
              }
              return null;
            }
        }
    }
</script>

<style>
  .el-table .total-row {
    background: #E8F5E9;
  }
</style>