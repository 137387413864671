<template>
  <div class="gridlogs-div">
    <el-form :inline="true" :model="logForm" class="gridlogform-search">
      <el-form-item label="房型">
        <el-select v-model="logForm.roomtype" placeholder="请选择房型" filterable clearable>
          <el-option v-for="item in roomtypes" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code" >
            <span style="float: left">{{ item.code }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker v-model="logForm.date" align="right" type="date" placeholder="选择日期"
                        value-format="yyyy-MM-dd" :picker-options="pickerOptions"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="refreshLogTable">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="userlogData" style="width: 100%" max-height="350" border>
      <el-table-column prop="userid" label="用户名" min-width="100"/>
      <el-table-column prop="opdate" label="操作时间" min-width="140"/>
      <el-table-column prop="optype" label="操作" min-width="80"/>
      <el-table-column prop="content" label="内容" min-width="300"/>
    </el-table>
    <el-pagination :current-page="logForm.pages.currentpage" :page-size="logForm.pages.pagesize"
                   layout="total, prev, pager, next, jumper" :total="logForm.pages.total" style="margin-top: 10px;float: right;" background
                   @current-change="handleCurrentChange"/>
  </div>
</template>

<script>
  import {gridlog_list} from '@/api/common/userlog';
  import {select_data} from '@/api/common/custom-data';
  import {select_res} from "@/utils/select_data_res";

  export default {
        name: "GridLogs",
        props: {
          block:{ type: String,default: ''},//锁房
          visible:{type: Boolean,default: true}
        },
        data(){
            return {
              logForm:{
                block:this.block,roomtype:'',date:'',
                pages:{total:0, currentpage:1, pagesize:10, sortname:'opdate', sortorder:'descending'}
              },
              pickerOptions: {
                shortcuts: [{ text: '今天', onClick(picker) { picker.$emit('pick', new Date()); } }]
              },
              loading: false, userlogData:[], roomtypes:[]
            }
        },
        watch: {
          block: function(val){
            this.logForm.block = val;
          },
          visible: function(val){
            if(val){
              // this.loadLogTable();//手动刷
              this.emptyRow();
            }
          }
        },
        mounted: function(){//调用时自己刷新 防止数据不更新
          // this.loadLogTable();//手动刷
          this.emptyRow();
        },
        methods:{
          handleCurrentChange(val){
            this.logForm.pages.currentpage = val;
            this.loadLogTable();
          },
          refreshLogTable(){
            this.logForm.pages.currentpage = 1;
            this.loadLogTable();
          },
          loadLogTable(){
            if(this.logForm.block.length>0){
              if(this.logForm.date.length<=0 || this.logForm.roomtype.length<=0){
                this.$message({ message: '请选择房型和日期', type: 'warning'});
              }else{
                this.loading = true;
                gridlog_list(this.logForm).then(response => {
                  this.userlogData = response.data.records;
                  this.logForm.pages.total = response.data.total;
                }).finally(() => { this.loading = false;})
              }
            }else{
              this.emptyRow();
            }
          },
          emptyRow(){
            this.userlogData = [];
            this.logForm.pages.total = 0;
            select_data({"keyname":"roomtype"}).then(response => {
              this.roomtypes = select_res(response.data,"roomtype");
            });
          }
        }
    }
</script>

<style scoped>

</style>