<template>
  <div class="cti-ticket-div">
    <el-dialog v-dialogDrag title="票务资源" :visible.sync="visible" width="80%" :close-on-click-modal="false" top="1vh"
               :close-on-press-escape="false" :modal-append-to-body="false" :append-to-body="true"
               @close="$emit('update:show', false)" @opened="initDialog">
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="日期">
          <el-date-picker v-model="searchForm.date" align="right" type="date" placeholder="选择日期"
                          value-format="yyyy-MM-dd" :picker-options="pickerOptions" :clearable="false"/>
        </el-form-item>
        <el-form-item label="票型">
          <el-input ref="ticketcodeinput" v-model="searchForm.ticket" @keyup.enter.native="refreshTable">
            <i slot="suffix" class="el-input__icon el-icon-s-grid" @click="showSelectDialog=true"/>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="refreshTable">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="ctiTicketTable" v-loading="loading" :data="tableData" style="width: 100%" highlight-current-row
                max-height="360" border @current-change="handleSelectRowChange">
        <el-table-column prop="radio" label="" min-width="50">
          <template slot-scope="scope">
            <el-radio v-model="radioSelect" :label="scope.row.code">&nbsp;</el-radio>
          </template>
        </el-table-column>
        <el-table-column prop="code" label="票型代码" min-width="80"/>
        <el-table-column prop="desc" label="票型描述" min-width="250"/>
        <el-table-column prop="group" label="票型组" min-width="70"/>
        <el-table-column prop="num" label="剩余票数" min-width="80" :formatter="numFormatter"/>
        <el-table-column prop="price" label="单价" min-width="80"/>
      </el-table>
      <el-pagination :current-page="searchForm.pages.currentpage" :page-size="searchForm.pages.pagesize"
                     layout="sizes,total, prev, pager, next, jumper" :total="searchForm.pages.total" style="margin-top: 10px;float: right;" background
                     @current-change="handleCurrentChange" @size-change="handleSizeChange"/>
      <el-form v-if="isCti" :inline="true" :model="saveForm" class="save-form" style="margin-top: 20px">
        <el-form-item>
          <el-select v-model="saveForm.ticket" filterable clearable>
            <el-option v-for="item in tickets" :key="item.code" :label="item.desc" :value="item.code">
              <span style="float: left">{{ item.code }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="价格">
          <el-input-number ref="priceinput" v-model="saveForm.price" :precision="2" :step="10" :min="0.00" controls-position="right" />
        </el-form-item>
        <el-form-item label="数量">
          <el-input-number v-model="saveForm.num" :min="1" controls-position="right" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-edit-outline" @click="createRes">生成预定</el-button>
          <el-button @click="visible=false">退出</el-button>
        </el-form-item>
      </el-form>
      <div v-else slot="footer" class="dialog-footer">
        <el-button type="primary" icon="el-icon-edit-outline" @click="selectValue">选择</el-button>
        <el-button @click="visible=false">退出</el-button>
      </div>
    </el-dialog>

    <el-dialog v-dialogDrag title="选择票型" :visible.sync="showSelectDialog" :close-on-click-modal="false" width="50%" append-to-body @opened="openSelectDialog">
      <el-form :inline="true" class="search-form">
        <el-form-item label="票型">
          <el-input v-model="searchTicket" clearable/>
        </el-form-item>
        <el-form-item label="票型组">
          <el-select v-model="searchGroup" filterable clearable>
            <el-option v-for="item in ticketgroups" :key="item.code" :label="item.code" :value="item.code">
              <span style="float: left">{{ item.code }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <el-table ref="selectTable" :data="tickets.filter(data => (!searchTicket || data.code.toLowerCase().startsWith(searchTicket.toLowerCase())) &&
                  (!searchGroup || data.group===searchGroup))"
                style="width: 100%" max-height="350" @selection-change="handleSelectChange">
        <el-table-column type="selection" width="55"/>
        <el-table-column property="code" label="代码" width="150"/>
        <el-table-column property="desc" label="描述" width="200"/>
        <el-table-column property="group" label="组" width="150"/>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="selectCode">选择</el-button>
        <el-button @click="showSelectDialog=false">退出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {query_ticketinfo} from '@/api/order/ticket';
  import {formatDate} from '@/utils/calcdate';
  import {select_data} from '@/api/common/custom-data';
  import {select_res} from "@/utils/select_data_res";

  export default {
    name: "CtiCater",
    props:{
      arrdate:{type:String,default:formatDate(new Date())},
      channel:{type:String,default:''},
      show: {type: Boolean, default: false},
      cti: {type: Boolean, default: false}
    },
    data() {
      return {
        searchForm:{
          date:this.arrdate, ticket:'',channel:this.channel,
          pages:{total:0, currentpage:1, pagesize:20,sortname:'group,code', sortorder:'ascending'}
        },
        saveForm:{
          ticket:'',price:0,num:1
        },
        pickerOptions: {
          shortcuts: [{ text: '今天', onClick(picker) { picker.$emit('pick', new Date()); } }]
        },
        visible:this.show,loading:false,tableData:[],currentRow :null, isCti:this.cti,
        showSelectDialog:false, searchTicket:'', searchGroup:'', ticketgroups:[], tickets:[],
        ticketSelect:[], radioSelect:''
      }
    },
    watch:{
      show(){
        this.visible=this.show;
      },
      arrdate(){
        this.searchForm.date=formatDate(this.arrdate);
      },
      channel(){
        this.searchForm.channel = this.channel;
      },
      cti(){
        this.isCti = this.cti;
      }
    },
    mounted: function(){
      this.$nextTick(()=>{
        this.$refs.ticketcodeinput.focus();
      });
      select_data({"keyname":"ticketgroup,ticket"}).then(response => {
        this.ticketgroups = select_res(response.data,"ticketgroup");
        this.tickets = select_res(response.data,"ticket");
      });
    },
    methods:{
      initDialog(){
        this.$refs.ticketcodeinput.focus();
        this.refreshTable();
      },
      numFormatter(row){
        if(row.num==-1){
          return '不限量';
        }
        return row.num;
      },
      refreshTable(){
        this.searchForm.pages.currentpage = 1;
        this.loadTable();
      },
      loadTable(){
        this.loading = true;
        query_ticketinfo(this.searchForm).then(response => {
          this.tableData = response.data.records;
          this.searchForm.pages.total = response.data.total;
          if(this.tableData.length>0){
            this.$refs.ctiTicketTable.setCurrentRow(this.tableData[0]);//默认选中第一条记录
          }
        }).finally(() => { this.loading = false;})
      },
      handleCurrentChange(val){
        this.searchForm.pages.currentpage = val;
        this.loadTable();
      },
      handleSizeChange(val){
        this.searchForm.pages.pagesize = val;
        this.refreshTable();
      },
      handleSelectRowChange(val) {
        this.radioSelect = val.code;
        this.currentRow = val;
        this.saveForm.ticket = val.code;
        this.saveForm.price = val.price;
      },
      openSelectDialog(){
        if(this.tickets!=null && this.tickets.length>0){
          this.tickets.forEach(row => {
            this.$refs.selectTable.toggleRowSelection(row,this.searchForm.ticket.toUpperCase().indexOf(row.code)>=0);
          });
        }
      },
      handleSelectChange(val) {
        this.ticketSelect = val;
      },
      selectCode(){
        this.showSelectDialog = false;
        let selectCode = '';
        this.ticketSelect.forEach(selectValue => {
          selectCode = selectCode.length<=0?selectValue.code:selectCode+","+selectValue.code;
        });
        this.searchForm.ticket = selectCode;
      },
      createRes(){
        if(this.saveForm.ticket.length<=0){
          this.$message({ message: '请选择预定票型', type: 'warning'});
          return;
        }/*else if(this.saveForm.price<=0){ //有免费票 所以这里不需要校验
          this.$msgbox({ title: '提示', message: '请输入预订价格', confirmButtonText: '关闭'}).catch(() => {});
          this.$refs.priceinput.focus();
          return;
        }*/
        let productsdesc = this.saveForm.ticket;
        for(let i=0; i<this.tickets.length; i++){
          if(this.saveForm.ticket==this.tickets[i].code){
            productsdesc = this.tickets[i].desc;
            break;
          }
        }
        let items=[{
          ratecode: '',
          product: this.saveForm.ticket,
          productdesc: productsdesc,
          arrdate: this.searchForm.date,
          deptdate: this.searchForm.date, //如果是客房产品.根据房晚数计算离店日期.
          producttype: 'T',
          num: this.saveForm.num,
          price: this.saveForm.price,
          lfix:1 // 1为固定价格
        }];
        this.$emit("selected", items);
        this.$emit('update:show',false);
      }
    }
  }
</script>

<style scoped>

</style>
