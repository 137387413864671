<template>
  <div class="el-main">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item v-if="lcheck" label="旧密码" prop="oldpwd">
        <el-input v-model="form.oldpwd" type="password" autocomplete="off"/>
      </el-form-item>
      <el-form-item label="新密码" prop="pwd">
        <el-input v-model="form.pwd" type="password" autocomplete="off"/>
      </el-form-item>
      <el-form-item label="确认密码" prop="checkpwd">
        <el-input v-model="form.checkpwd" type="password" autocomplete="off"/>
      </el-form-item>
    </el-form>
  </div>

</template>

<script>
    import {updpwd} from "@/api/conf/userconf";

    export default {
        name: "UserPwd",
        components: {},
        props: {
            id: {type: Number, default: 0},
            userid: {type: String, default: ""},
            lcheck: {type: Boolean, default: true}
        },
        data() {
            let  validatePass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请输入新密码'));
                } else {
                    if (this.form.checkpwd !== '') {
                        this.$refs.form.validateField('checkpwd');
                    }
                    callback();
                }
            };
            let validatePass2 = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error('请再次输入密码'));
                } else if (value !== this.form.pwd) {
                    callback(new Error('两次输入密码不一致!'));
                } else {
                    callback();
                }
            };
            return {
                form: {
                    oldpwd:'', pwd:'', checkpwd: '', needcheck:this.lcheck,
                    sqlid:this.id, userid:this.userid
                },
                rules: {
                  pwd: [{ required: true, validator: validatePass, trigger: 'blur' }],
                  checkpwd: [{ required: true, validator: validatePass2, trigger: 'blur' }],
                  oldpwd: [{ required: true, message: '请输入原密码', trigger: 'blur' }]
                }
             }
        },
        watch: {
          id: function(val){
            this.form.sqlid = val;
            this.form.oldpwd = this.form.pwd = this.form.checkpwd = '';
          },
          userid: function(val){
            this.form.userid = val;
            this.form.oldpwd = this.form.pwd = this.form.checkpwd = '';
          }
        },
        methods: {
            save(){
                if(!this.form.needcheck){
                  this.form.oldpwd = '1';
                }
                this.$refs.form.validate((valid)=>{
                    if(!valid){
                        return;
                    }
                    updpwd(this.form).then(()=>{
                        this.$message({ message: '密码修改成功', type: 'success'});
                        this.$emit('success');
                    });
                })
            }
        }
    }
</script>

<style scoped>

</style>
