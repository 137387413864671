<template>
  <div class="userlogs-div">
    <el-form v-show="type==='GRID'" :inline="true" :model="logForm" class="logform-search">
      <el-form-item label="房型">
        <el-input v-model="logForm.roomtype" placeholder="输入房型"/>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker v-model="logForm.date" align="right" type="date" placeholder="选择日期"
                        value-format="yyyy-MM-dd" :picker-options="pickerOptions"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="refreshLogTable">查询</el-button>
      </el-form-item>
    </el-form>
    <el-form v-show="type==='SUBATTR'" :inline="true" :model="logForm" class="logform-search">
      <el-form-item label="二级渠道">
        <el-select v-model="logForm.regno" placeholder="请选择二级渠道" filterable clearable>
          <el-option v-for="item in subchannels" :key="item.code" :label="item.code" :value="item.code">
            <span style="float: left">{{ item.code }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="refreshLogTable">查询</el-button>
      </el-form-item>
    </el-form>
    <el-form v-show="type==='KIT'||type==='CHANNEL'||type==='BLOCK'" :inline="true" :model="logForm" class="logform-search">
      <el-form-item label="关键字">
        <el-input v-model="logForm.regno" placeholder="输入关键字" clearable/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="refreshLogTable">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="userlogData" style="width: 100%" max-height="350" border>
      <el-table-column prop="userid" label="用户名" min-width="100"/>
      <el-table-column prop="date" label="时间" min-width="140"/>
      <el-table-column prop="regno" label="订单号/组" min-width="100"/>
      <el-table-column prop="optype" label="操作" min-width="80"/>
      <el-table-column prop="content" label="内容" min-width="300"/>
    </el-table>
    <el-pagination :current-page="logForm.pages.currentpage" :page-size="logForm.pages.pagesize"
                   layout="total, prev, pager, next, jumper" :total="logForm.pages.total" style="margin-top: 10px;float: right;" background
                   @current-change="handleCurrentChange"/>
  </div>
</template>

<script>
  import {userlog_list} from '@/api/common/userlog';
  import {select_data} from '@/api/common/custom-data';
  import {select_res} from "@/utils/select_data_res";

  export default {
        name: "UserLogs",
        props: {
          type:{ type: String,default: ''},//类型
          bookingid:{ type: String,default: ''},//订单号
          showall:{type: Boolean,default: false},//显示所有
          visible:{type: Boolean,default: true}
        },
        data(){
            return {
              logForm:{
                type:this.type,regno:this.bookingid,
                roomtype:'',date:'',
                pages:{total:0, currentpage:1, pagesize:30, sortname:'date', sortorder:'descending'}
              },
              pickerOptions: {
                shortcuts: [{ text: '今天', onClick(picker) { picker.$emit('pick', new Date()); } }]
              },
              loading: false, userlogData:[], subchannels:[]
            }
        },
        watch: {
          bookingid: function(val){
            this.logForm.regno = val;
          },
          visible: function(val){
            if(val){
              this.loadLogTable();
            }
          }
        },
        mounted: function(){//调用时自己刷新 防止数据不更新
          if(this.type==='SUBATTR'){
            select_data({"keyname":"subchannel"}).then(response => {
              this.subchannels = select_res(response.data,"subchannel");
            });
          }
          this.loadLogTable();
        },
        methods:{
          handleCurrentChange(val){
            this.logForm.pages.currentpage = val;
            this.loadLogTable();
          },
          refreshLogTable(){
            this.logForm.pages.currentpage = 1;
            this.loadLogTable();
          },
          loadLogTable(){
            if(this.logForm.regno.length>0 || this.showall){
              this.loading = true;
              userlog_list(this.logForm).then(response => {
                this.userlogData = response.data.records;
                this.logForm.pages.total = response.data.total;
              }).finally(() => { this.loading = false;})
            }else{
              this.userlogData = [];
              this.logForm.pages.total = 0;
            }
          }
        }
    }
</script>

<style scoped>

</style>