<template>
  <div class="meetingdetaildiv">
    <el-container>
      <el-header height="40px">{{ showTitle }}<span style="float:right">{{ showStatus }}</span></el-header>
      <el-main>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px" class="base-form">
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="预定单位" prop="name">
                <el-input ref="nameinput" v-model="form.name" maxlength="60" @blur="checkNameExist">
                  <i slot="suffix" class="el-input__icon el-icon-s-grid" @click="showProfileDialog=true"/>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="联系人">
                <el-input v-model="form.linkname" maxlength="60" clearable/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="电话号码">
                <el-input ref="telinput" v-model="form.telephone" maxlength="20" clearable/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="会议名称">
                <el-input v-model="form.meetingname" maxlength="60" clearable/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="会议类型">
                <el-select v-model="form.type" placeholder="请选择会议类型" filterable>
                  <el-option v-for="item in meetingtypes" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="付款方式">
                <el-select v-model="form.payment" placeholder="请选择付款方式" filterable>
                  <el-option v-for="item in payments" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="会议场地" prop="site">
                <el-select v-model="form.site" placeholder="请选择会议场地" filterable :disabled="form.status!=='N'" @change="changeSite">
                  <el-option v-for="item in sites" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="会议室" prop="room">
                <el-select v-model="form.room" placeholder="请选择会议室" filterable :disabled="form.status!='N'" @change="getPrice">
                  <el-option v-for="item in rooms" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="会议场次" prop="time">
                <el-select v-model="timeSelect" placeholder="请选择场次" filterable multiple collapse-tags
                           :disabled="form.status!='N'" @change="changeTime">
                  <el-option v-for="item in times" :key="item.code" :label="item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="会议日期" prop="date">
                <el-date-picker v-model="form.date" align="right" type="date" :picker-options="pickerOptions"
                                :clearable="false" value-format="yyyy-MM-dd"/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="会议时间">
                <el-time-picker v-model="form.starttime" arrow-control placeholder="开始时间" style="width: 50%" format="HH:mm" value-format="HH:mm" :readonly="timeSelect.length>1"/>
                <el-time-picker v-model="form.endtime" arrow-control placeholder="结束时间" style="width: 50%" format="HH:mm" value-format="HH:mm" :readonly="timeSelect.length>1"/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="会议设备">
                <el-select v-model="equipSelect" placeholder="请选择设备" filterable multiple collapse-tags
                           :disabled="form.status!='N'&&form.status!='A'" @change="changeEquip">
                  <el-option v-for="item in equips" :key="item.code" :label="item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc+' '+item.group }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="总价">
                <el-popover placement="bottom-start" trigger="hover">
                  <p>场租价格: {{ form.roomprice }}</p>
                  <p>设备价格: {{ form.equipprice }}</p>
                  <el-input slot="reference" v-model="form.totalprice" :readonly="!form.fix" class="priceInput">
                    <i slot="suffix" class="el-input__icon el-icon-view"/>
                    <template slot="append">
                      <el-checkbox v-model="form.fix" @change="getPrice">固定价</el-checkbox>
                    </template>
                  </el-input>
                </el-popover>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="人数">
                <el-input-number v-model="form.num" :min="0"/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="创建信息">
                <el-input v-model="createInfo" readonly/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="24" :sm="24" :xs="24">
              <el-form-item label="备注">
                <el-input v-model="form.info" type="textarea" :rows="3" placeholder="请输入备注内容"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
      <el-footer height="40px">
        <el-button type="primary" icon="el-icon-edit-outline" :disabled="(form.status!='N'&&form.status!='A')||dateIsExpire||history"
                   :loading="buttonLoading" @click="handleSave">保存</el-button>
        <el-button type="primary" icon="el-icon-s-grid" :disabled="form.status!='N'||history" @click="showResourceDialog=true">资源</el-button>
        <el-button type="primary" icon="el-icon-bell" :disabled="form.sqlid<=0||(form.status!='N'&&form.status!='A')||history"
                   :loading="buttonLoading" @click="handleChange">{{ form.status=='N'?'开始':'完成' }}</el-button>
        <el-button type="danger" icon="el-icon-warning-outline" :disabled="form.sqlid<=0||(form.status!='N'&&form.status!='A')||history"
                   :loading="buttonLoading" @click="handleCancel">{{ form.status=='A'?'取消开始':'取消' }}</el-button>
        <el-button v-if="gotocol" type="primary" icon="el-icon-refresh-left" :disabled="form.sqlid<=0||form.colno==''"
                   @click="gotoCol">综合预定</el-button>
        <el-button type="primary" icon="el-icon-tickets" @click="showLogDialog = true">日志</el-button>
      </el-footer>
    </el-container>
    <!-- 窗口Dialog -->
    <el-dialog v-dialogDrag title="选择档案" :visible.sync="showProfileDialog" :close-on-click-modal="false" width="85%" append-to-body>
      <ProfileSelect :profileid="form.profileid" :name="form.name" :telno="form.telephone" :type="['C','T','G']" :visible="showProfileDialog" @selectValue="selectProfile"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <el-dialog v-dialogDrag title="查看日志" :visible.sync="showLogDialog" :close-on-click-modal="false" width="70%" append-to-body>
      <UserLogs ref="userlogs" :type="'MEETING'" :bookingid="form.bookingid" :visible.sync="showLogDialog"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <el-dialog v-dialogDrag title="会议资源" :visible.sync="showResourceDialog" :close-on-click-modal="false" width="70%" top="6vh" append-to-body>
      <MeetingResource2 ref="meetingresource" :searchroom="form.room" :searchdate="form.date" :meetingid="form.sqlid" :visible.sync="showResourceDialog" @selectValue="resourceSelect"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
  </div>
</template>

<script>
  import {load_meeting, save_meeting, cancel_meeting, change_meeting} from '@/api/order/meeting';
  import {name_exist} from '@/api/profile/profile';
  import {select_data} from '@/api/common/custom-data';
  import {select_res, filterValue, filterValueArray} from "@/utils/select_data_res";
  import {getMeetingStatus} from '@/utils/status-format';
  import {dateIsExpire, compareHMtoday, stringToDate, todayZeroHMS} from '@/utils/calcdate';
  import {arrayToString, stringToArray} from '@/utils/string-util';
  import '@/styles/pagecontainer.scss';
  import ProfileSelect from "@/views/common/profile-select";
  import UserLogs from "@/views/common/userlogs";
  import MeetingResource2 from '@/views/order/meeting/meetingresource2';

    export default {
        name: "MeetingDetail",
        components: {ProfileSelect, UserLogs, MeetingResource2},
        props: {
            colno:{ type: String,default: ''},
            meetingid:{ type: Number,default: 0},
            newroom:{ type: String,default: ''},
            newdate:{ type: String, default: null},
            newtime:{ type: String,default: ''},
            history:{type: Boolean,default: false},
            visible:{type: Boolean,default: true},
            gotocol:{type: Boolean,default: false}//是否显示跳转综合预定按钮
        },
        data() {
            return {
                form:{},
                pickerOptions: {
                    disabledDate(time) { return time.getTime() < Date.now() - 8.64e7; },//不能选之前的日期
                    shortcuts: [{ text: '今天', onClick(picker) { picker.$emit('pick', new Date()); } }]
                },
                rules: {
                    name: [{ required: true, message: '请输入预定单位', trigger: 'change' }],
                    site: [{ required: true, message: '请选择场地', trigger: 'change' }],
                    room: [{ required: true, message: '请选择会议室', trigger: 'change' }],
                    time: [{ required: true, message: '请选择场次', trigger: 'change' }],
                    date: [{ required: true, message: '请选择会议日期', trigger: 'change' }]
                },
                showProfileDialog:false,showLogDialog:false,showResourceDialog:false,
                sites:[],rooms:[],payments:[],times:[], buttonLoading:false,
                meetingtypes:[], equips:[]
            }
        },
        computed:{
            showTitle(){ return this.form.sqlid!=null&&this.form.sqlid>0?'编辑会议订单  订单号: '+this.form.bookingid+' 综合订单号: '+this.form.colno
                    +(this.form.pmsno!=''?'  线下订单号: '+this.form.pmsno:''):'新建会议订单'},
            showStatus(){ return this.form.sqlid!=null&&this.form.sqlid>0?getMeetingStatus(this.form.status):''},
            dateIsExpire() { return dateIsExpire(this.form.date) },
            timeSelect:{
              get:function(){ return stringToArray(this.form.time,','); },
              set:function(timeValue){ this.form.time = arrayToString(timeValue,',');}
            },
            equipSelect:{
              get:function(){ return stringToArray(this.form.equipment,','); },
              set:function(equipValue){ this.form.equipment = arrayToString(equipValue,',');}
            },
            createInfo:{
              get:function(){
                return this.form.created==null||this.form.created=='1900-01-01'?this.form.creator
                        :this.form.created+' '+this.form.createtime+' '+this.form.creator;
              }
            },
        },
        watch:{
            visible: function(val){
              if(val){
                this.initDialog();
              }
            }
        },
        mounted: function(){
            this.$refs.nameinput.focus();
            select_data({"keyname":"payment,meeting_site,meeting_time,meeting_type"}).then(response => {
              this.payments = select_res(response.data,"payment");
              this.sites = select_res(response.data,"meeting_site");
              this.times = select_res(response.data,"meeting_time");
              this.meetingtypes = select_res(response.data,"meeting_type");
            });
            this.initDialog();
        },
        methods:{
            initDialog(){
                load_meeting({"sqlid":this.meetingid,"colno":this.colno,"date":this.newdate,
                    "room":this.newroom,"time":this.newtime,"history":this.history}).then(response => {
                    this.form = response.data;
                    if(this.form.site.length>0){
                      this.changeSite(this.form.site);
                    }else{
                      this.rooms = this.equips = [];
                    }
                }).catch(() => {
                    this.$emit('update:visible',false);
                })
            },
            changeSite(val){
                select_data({"keyname":"meeting_room,meeting_equip","params":[{'key':'meeting_room','value':val},{'key':'meeting_equip','value':val}]}).then(response => {
                    this.rooms = select_res(response.data,"meeting_room");
                    this.equips = select_res(response.data,"meeting_equip");
                    this.form.room = filterValue(this.rooms,this.form.room);
                    this.form.equipment = filterValueArray(this.equips,this.form.equipment);
                    if(this.form.room!=''){
                      this.getPrice();
                    }
                })
            },
            checkNameExist(){
              if(this.form.profileid==''&&this.form.name.length>0){
                name_exist({"name":this.form.name,"type":"C,T,G"}).then(response => {
                  if(response.data){
                    this.showProfileDialog = true;
                  }
                })
              }
            },
            selectProfile(selectValue){
                this.showProfileDialog = false;
                this.form.profileid = selectValue.profileid;
                this.form.name = selectValue.name;
                if(this.form.meetingname.length<=0 && this.form.name.length>0){
                  this.form.meetingname = this.form.name+'的会议';
                }
                this.form.telephone = selectValue.mobile;
                this.form.linkname = selectValue.linkname;
            },
            getPrice(){
              if(!this.form.fix){
                let hasValue = false;
                if(this.form.room!=''){
                  for(let i=0; i<this.rooms.length; i++){
                    if(this.rooms[i].code==this.form.room){
                      this.form.roomprice = parseFloat(this.rooms[i].group).toFixed(2);
                      hasValue = true;
                      break;
                    }
                  }
                }
                if(!hasValue){
                  this.form.roomprice = 0.00;
                }
                this.calcTotal();
              }
            },
            changeTime(){
              this.calcTotal();
              let timeArray = this.timeSelect;
              if(timeArray!=null && timeArray.length>0){
                let time = timeArray[0];//只取一个
                for(let i=0; i<this.times.length; i++){
                  if(time == this.times[i].code){
                    let timeStrArray = stringToArray(this.times[i].group,",");
                    if(timeStrArray.length>1){
                      this.form.starttime = timeStrArray[0];
                      this.form.endtime = timeStrArray[1];
                    }
                  }
                }
              }
            },
            changeEquip(){
              let equipPrice = 0.00;
              let equipArray = this.equipSelect;
              if(equipArray!=null && equipArray.length>0){
                for(let i=0; i<this.equips.length; i++){
                  if(equipArray.indexOf(this.equips[i].code)>-1){
                    equipPrice += parseFloat(this.equips[i].group);
                  }
                }
              }
              this.form.equipprice = equipPrice;
              this.calcTotal();
            },
            calcTotal(){
              if(!this.form.fix){
                let nums = this.timeSelect.length;
                this.form.totalprice = parseFloat(this.form.roomprice*(nums>1?nums:1)+this.form.equipprice).toFixed(2);
              }
            },
            handleSave(){
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.buttonLoading = true;
                        save_meeting(this.form).then(response => {
                            this.$msgbox({ title: '消息', message: '保存成功! 订单号:'+response.data.bookingid, confirmButtonText: '关闭'}).catch(() => {});
                            this.$emit('refresh');
                            if(!this.gotocol){//如果可以跳转到综合预定,保存后界面不关
                              this.$emit('update:visible', false);
                            }else{
                              this.form = response.data;
                            }
                        }).finally(()=>{
                          this.buttonLoading = false;
                        });
                    } else {
                        return false;
                    }
                });
            },
            handleChange(){
                if(todayZeroHMS().getTime()!=stringToDate(this.form.date).getTime()){
                  this.$message({ message: '会议时间未到!', type: 'warning'});
                }else{
                  let compareTime = '';
                  let comfirmStr = '';
                  let msgStr = '';
                  let start = true;
                  if(this.form.status=='N'){
                    compareTime = this.form.starttime;
                    comfirmStr = '会议开始时间未到,是否继续开始';
                    msgStr = '会议开始';
                  }else{
                    start = false;
                    compareTime = this.form.endtime;
                    comfirmStr = '会议结束时间未到,是否继续结束';
                    msgStr = '会议结束';
                  }
                  if(!compareHMtoday(compareTime)){
                    this.$confirm(comfirmStr, '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
                      this.doChange(msgStr, start);
                    }).catch(() => {});
                  }else{
                    this.doChange(msgStr, start);
                  }
                }
            },
            doChange(str, start){
              this.buttonLoading = true;
              change_meeting({"sqlid":this.form.sqlid, "start":start}).then(() => {
                this.$message({ message: str, type: 'success'});
                this.$emit('refresh');
                this.$emit('update:visible',false);
              }).finally(()=>{
                this.buttonLoading = false;
              });
            },
            handleCancel(){
              this.$confirm('确定取消?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
                this.buttonLoading = true;
                cancel_meeting({"sqlid":this.form.sqlid}).then(() => {
                  this.$message({ message: '取消成功!', type: 'success'});
                  this.$emit('refresh');
                  this.$emit('update:visible',false);
                }).finally(()=>{
                  this.buttonLoading = false;
                });
              }).catch(() => {});
            },
            resourceSelect(row){
                this.showResourceDialog = false;
                this.form.date = row.date;
                let changeSite = false;
                if(this.form.site!=row.site){
                  this.form.site = row.site;
                  this.changeSite(this.form.site);
                  changeSite = true;
                }
                if(this.form.room!=row.room){
                  this.form.room = row.room;
                  if(!changeSite){ //如果选的是同一会场不同会议室 需要手动刷一下价格
                    this.getPrice();
                  }
                }
                if(this.form.time!=row.time){
                  this.form.time = row.time;
                  this.changeTime();
                }
            },
            gotoCol(){
              this.$emit('update:rscolno',this.form.colno);
              this.$emit('gotoCol');
            }
        }
    }
</script>

<style>
  .priceInput .el-input__inner {
    font-weight: bolder;
    color: blue;
  }
</style>