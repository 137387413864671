import request from '@/utils/request'

//导游预定 - 导游预定列表
export  function guide_list(data){
    return request({
        url:'/api/guide/guide_list',
        method:'post',
        data
    })
}

//导游预定 - 取消导游预定
export function cancel_guides(data){
    return request({
        url:'/api/guide/cancel_guides',
        method:'post',
        data
    })
}

//导游预定 - 获取导游预定对象
export function load_guides(data){
    return request({
        url:'/api/guide/load_guides',
        method:'post',
        data
    })
}

//导游预定 - 保存导游预定
export function save_guides(data){
    return request({
        url:'/api/guide/save_guides',
        method:'post',
        data
    })
}