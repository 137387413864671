import request from '@/utils/request'

export function list(data) {
  return request({
    url: '/api/userconf/userlist',
    method: 'post',
    data
  })
}

export function load(data) {
  return request({
    url: '/api/userconf/loaduser',
    method: 'post',
    data
  })
}

// export function add(data) {
//   return request({
//     url: '/api/userconf/adduser',
//     method: 'post',
//     data
//   })
// }

export function del(data) {
  return request({
    url: '/api/userconf/deluser',
    method: 'post',
    data
  })
}

// export function merge(data) {
//   return request({
//     url: '/api/userconf/upduser',
//     method: 'post',
//     data
//   })
// }
export function save(data) {
  return request({
    url: '/api/userconf/saveuser',
    method: 'post',
    data
  })
}

export function updDeactivate(data) {
  return request({
    url: '/api/userconf/updDeactivate',
    method: 'post',
    data
  })
}

export function updpwd(data) {
  return request({
    url: '/api/userconf/updpwd',
    method: 'post',
    data
  })
}

export function listrole(data) {
  return request({
    url: '/api/userconf/rolelist',
    method: 'post',
    data
  })
}

export function loadrole(data) {
  return request({
    url: '/api/userconf/loadrole',
    method: 'post',
    data
  })
}

// export function addrole(data) {
//   return request({
//     url: '/api/userconf/addrole',
//     method: 'post',
//     data
//   })
// }

export function delrole(data) {
  return request({
    url: '/api/userconf/delrole',
    method: 'post',
    data
  })
}

// export function mergerole(data) {
//   return request({
//     url: '/api/userconf/updrole',
//     method: 'post',
//     data
//   })
// }

export function saverole(data) {
  return request({
    url: '/api/userconf/saverole',
    method: 'post',
    data
  })
}

export function loadrights(data) {
  return request({
    url: '/api/userconf/loadrights',
    method: 'post',
    data
  })
}

export function saverights(data) {
  return request({
    url: '/api/userconf/saverights',
    method: 'post',
    data
  })
}

export function profilelist(data) {
  return request({
    url: '/api/userconf/profilelist',
    method: 'post',
    data
  })
}

