import { asyncRoutes, constantRoutes } from '@/router'

/**
 * Use meta.role to determine if the current user has permission
 * @param roles
 * @param route
 */
function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(role => route.meta.roles.includes(role))
  } else {
    return true
  }
}

function hasMenuRight(rights,route) {
  if(route.meta &&route.meta.right){
    return rights.some(right=>route.meta.right.includes(right))
  }else {
    return true
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param routes asyncRoutes
 * @param roles
 */
export function filterAsyncRoutes(routes, roles,mrights) {
  const res = []

  routes.forEach(route => {
    const tmp = { ...route }
    if (hasMenuRight(mrights, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(tmp.children, roles,mrights)
      }
      res.push(tmp)
    }
  })

  return res
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
  ADD_SERVER_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  generateRoutes({ commit }, rightdata) { // 前端本地配置路由表属性有权限的话.就用这种.
    return new Promise(resolve => {
      let accessedRoutes
      let roles=rightdata.roles;
      let mrights=rightdata.mrights;
      if (roles.includes('admin')) {
        accessedRoutes = asyncRoutes || []
      } else {
        accessedRoutes = filterAsyncRoutes(asyncRoutes, roles,mrights)
      }
      let flatRoutes = generateFlatRoutes(accessedRoutes);
      commit('SET_ROUTES', accessedRoutes);
      // resolve(accessedRoutes)
      resolve(flatRoutes);
    })
  },
  addServerRoutes({ commit }, serverroutes) { // 将服务器返回的菜单路径.加载到本地
    return new Promise(resolve => {
      commit('ADD_SERVER_ROUTES', serverroutes)
      resolve(serverroutes)
    })
  }
}

/**
 * 生成扁平化机构路由(仅两级结构)
 * @param {允许访问的路由Tree} accessRoutes
 * 路由基本机构:
 * {
 *   name: String,
 *   path: String,
 *   component: Component,
 *   redirect: String,
 *   children: [
 *   ]
 * }
 */
function generateFlatRoutes(accessRoutes) {
  let flatRoutes = [];

  for (let item of accessRoutes) {
    let childrenFflatRoutes = [];
    if (item.children && item.children.length > 0) {
      childrenFflatRoutes = castToFlatRoute(item.children, "");
    }

    // 一级路由是布局路由,需要处理的只是其子路由数据
    flatRoutes.push({
      name: item.name,
      path: item.path,
      component: item.component,
      redirect: item.redirect,
      children: childrenFflatRoutes
    });
  }

  return flatRoutes;
}

/**
 * 将子路由转换为扁平化路由数组（仅一级）
 * @param {待转换的子路由数组} routes
 * @param {父级路由路径} parentPath
 */
function castToFlatRoute(routes, parentPath, flatRoutes = []) {
  for (let item of routes) {
    if (item.children && item.children.length > 0) {
      if (item.redirect && item.redirect !== 'noRedirect') {
        flatRoutes.push({
          name: item.name,
          path: (parentPath + "/" + item.path).substring(1),
          redirect: item.redirect,
          props: item.props,
          meta: item.meta
        });
      }
      castToFlatRoute(item.children, parentPath + "/" + item.path, flatRoutes);
    } else {
      flatRoutes.push({
        name: item.name,
        path: (parentPath + "/" + item.path).substring(1),
        component: item.component,
        props: item.props,
        meta: item.meta
      });
    }
  }

  return flatRoutes;
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
