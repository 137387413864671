import request from '@/utils/request'

export function productkit_list(data) {
  return request({
    url: '/api/productkit/productkit_list',
    method: 'post',
    data
  })
}

export function load_productkit(data) {
  return request({
    url: '/api/productkit/load_productkit',
    method: 'post',
    data
  })
}

export function save_productkit(data) {
  return request({
    url: '/api/productkit/save_productkit',
    method: 'post',
    data
  })
}

export function delete_productkit(data) {
  return request({
    url: '/api/productkit/delete_productkit',
    method: 'post',
    data
  })
}

export function general_productkit(data) {
  return request({
    url: '/api/productkit/general_productkit',
    method: 'post',
    data
  })
}

export function kititem_list(data) {
  return request({
    url: '/api/productkit/kititem_list',
    method: 'post',
    data
  })
}

export function load_kititem(data) {
  return request({
    url: '/api/productkit/load_kititem',
    method: 'post',
    data
  })
}

export function save_kititem(data) {
  return request({
    url: '/api/productkit/save_kititem',
    method: 'post',
    data
  })
}

export function delete_kititem(data) {
  return request({
    url: '/api/productkit/delete_kititem',
    method: 'post',
    data
  })
}

export function synckits(data) {
  return request({
    url: '/api/productkit/synckits',
    method: 'post',
    data
  })
}

export function synckititems(data) {
  return request({
    url: '/api/productkit/synckititems',
    method: 'post',
    data
  })
}

export function kititem_reslist(data) {
  return request({
    url: '/api/productkit/kititem_reslist',
    method: 'post',
    data
  })
}

export function productkit_calendar(data) {
  return request({
    url: '/api/productkit/productkit_calendar',
    method: 'post',
    data
  })
}

export function productkit_avl_calendar(data) {
  return request({
    url: '/api/productkit/productkit_avl_calendar',
    method: 'post',
    data
  })
}

export function productkit_filter(data) {
  return request({
    url: '/api/productkit/productkit_filter',
    method: 'post',
    data
  })
}
