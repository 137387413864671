import request from "@/utils/request";

export function log_list(data) {
    return request({
        url: '/api/msg/log_list',
        method: 'post',
        data
    })
}

export function send_msg(data) {
    return request({
        url: '/api/msg/send',
        method: 'post',
        data
    })
}

export function batch_send_msg(data) {
    return request({
        url: '/api/msg/batch_send',
        method: 'post',
        data
    })
}

export function load_content(data) {
    return request({
        url: '/api/msg/load_content',
        method: 'post',
        data
    })
}