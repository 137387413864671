<template>
  <div>
    <el-dialog v-dialogDrag title="套餐预订"
               :visible.sync="visible"
               :show="show"
               width="80%"
               top="1vh"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :modal-append-to-body="false"
               :append-to-body="true"
               @close="$emit('update:show', false)"
               @opened="initDialog"
    >
      <el-form ref="orderform" :model="orderform" label-width="60px" class="save-kit-form" :rules="rules" style="margin-top: 20px">
        <el-row :gutter="24">
          <el-col :md="5" :sm="12" :xs="12">
            <el-form-item label="姓名" prop="guestname">
              <zj-profileselect v-model="orderform.guestname" :no.sync="orderform.profileid" :ptype="''" @selectprofile="updProfileIno"/>
            </el-form-item>
          </el-col>
          <el-col :md="5" :sm="12" :xs="12">
            <el-form-item label="电话" prop="telno">
              <el-input v-model="orderform.telno" />
            </el-form-item>
          </el-col>
          <el-col :md="5" :sm="12" :xs="12">
            <el-form-item label="份数">
              <el-input-number v-model="orderform.packagenum" :min="1" @change="calcProductPrice"/>
            </el-form-item>
          </el-col>
          <el-col :md="5" :sm="12" :xs="12">
            <el-form-item label="价格码" prop="ratecode">
              <el-select v-model="ratecode" placeholder="请选择价格代码" clearable filterable @change="calcProductPrice">
                <el-option v-for="item in ratecodes" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code" >
                  <span style="float: left">{{ item.code }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="8" :sm="12" :xs="24">
            <el-form-item label="到店">
              <el-date-picker v-model="orderdate" type="daterange" align="right" unlink-panels
                              range-separator="至" start-placeholder="开始日期" end-placeholder="离店日期"
                              :picker-options="pickerOptions" value-format="yyyy-MM-dd" :clearable="false"
                              @change="calcProductPrice"/>
            </el-form-item>
          </el-col>
          <el-col :md="5" :offset="2" :sm="12" :xs="12">
            <el-form-item label="Block" prop="block">
              <zj-blockselect v-model="orderform.block" :calcform="orderform" :products="roomitems"/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :md="24" :sm="24" :xs="24">
            <el-form-item label="备注">
              <el-input v-model="orderform.remark" type="textarea" :rows="2" placeholder=""/>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-form :model="kitSearchForm" :inline="true" class="search-kit-form">
        <el-form-item>
          <el-input ref="kitcodeinput" v-model="kitSearchForm.code" placeholder="套餐代码或名称查询" clearable @keyup.enter.native="refreshKitTable"/>
        </el-form-item>
        <el-form-item>
          <el-date-picker v-model="dateselect" type="daterange" align="right" unlink-panels
                          range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                          value-format="yyyy-MM-dd"/>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="refreshKitTable">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="kitTable" v-loading="kitloading" :data="kitData" style="width: 100%" max-height="400" border highlight-current-row
                @current-change="loadKitDetailTable">
        <el-table-column prop="code" label="代码" min-width="80"/>
        <el-table-column prop="desc" label="描述" min-width="350"/>
        <el-table-column prop="startdate" label="开始日期" min-width="80"/>
        <el-table-column prop="enddate" label="结束日期" min-width="80"/>
        <el-table-column prop="type" label="类型" min-width="80" :formatter="typeFormatter"/>
      </el-table>
      <el-pagination :current-page="kitSearchForm.pages.currentpage" :page-size="kitSearchForm.pages.pagesize"
                     layout="total, prev, pager, next, jumper" :total="kitSearchForm.pages.total" style="margin-top: 8px;float: right;" background
                     @current-change="handleKitCurrentChange"/>
      <br>
      <el-divider content-position="left">套餐详情</el-divider>
      <el-table v-loading="kitdetailloading" :data="kitdetailData" style="width: 100%" max-height="400" border>
        <el-table-column prop="itemtype" label="类型" min-width="80" :formatter="itemTypeFormatter"/>
        <el-table-column prop="producttype" label="项目" min-width="80" :formatter="productTypeFormatter"/>
        <el-table-column prop="productdesc" label="产品" min-width="180">
          <template slot-scope="{row,$index}">
            <span v-if="row.itemtype=='M'">
              <el-select v-model="row.productdesc" placeholder="请选择产品" filterable @change="productSelect(row,$index)">
                <el-option v-for="item in row.selectdata" :key="item" :value="item" />
              </el-select>
            </span>
            <span v-else>{{ row.productdesc }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="num" label="数量" min-width="50"/>
        <el-table-column prop="price" label="价格" min-width="80"/>
      </el-table>
      <div style="margin-top: 10px">
        <el-button type="primary" icon="el-icon-edit-outline" :loading="buttonLoading" @click="saveKit">马上预订</el-button>
        <el-button @click="closeDialog">退出</el-button>
        <span style="float: right">金额:<span class="pricelabel" >{{ totalprice }}</span></span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {productkit_list, kititem_reslist} from '@/api/conf/productkitsetup';
import {batchorder,calcbatchprice} from "@/api/order/order";
import {formatDate, reckonDay,reckonDayStr} from '@/utils/calcdate';
import {select_data} from '@/api/common/custom-data';
import {select_res} from "@/utils/select_data_res";
import ZjProfileselect from "@/views/order/common/ZjProfileselect";
import ZjBlockselect from "@/views/order/common/ZjBlockselect";
export default {
  name: "KitList",
  components:{ZjProfileselect,ZjBlockselect},
  props:{
    calcform:{
      type:Object,
      default:function () {
        return {
          arrdate:formatDate(new Date()),
          deptdate:formatDate(new Date())
        }
      }
    },
    arrdate:{type:String,default:formatDate(new Date())},
    deptdate:{type:String,default:formatDate(new Date())},
    show: {type: Boolean, default: false},
    showTips: {type: Boolean, default: false},
  },
  data(){
    return {
      visible:this.show,
      ltips:this.showTips,
      ratecode:'SKJ',  //TODO 暂时的古北环境初始值.
      kitSearchForm:{
        code:'',
        startdate:this.calcform.arrdate,
        enddate:this.calcform.deptdate,
        pages:{total:0, currentpage:1, pagesize:3}
      },
      selectKit:'',
      // saveKitForm:{num:1},
      kitloading:false,
      kitData:[],
      kitdetailloading:false,
      kitdetailData:[],
      ratecodes:[],
      totalprice:0,
      rules: {
        guestname: [{required: true, message: '查询档案或填入姓名', trigger: 'blur'}],
        telno: [{required: true, message: '请填入电话或手机号码', trigger: 'blur'}]
        // block: [{ required: true, message: '请选择锁房', trigger: 'change' }],
      },
      orderform:{
        guestname:this.calcform.guestname,
        telno:this.calcform.telno,
        profileid:this.calcform.profileid,
        channel:this.calcform.channel,
        market:this.calcform.market,
        scode:this.calcform.scode,
        booker:'',
        companyid:'',
        companyname:'',
        travelid:'',
        travelname:'',
        groupid:'',
        groupname:'',
        remark:'',
        person:1,
        arrdate: this.calcform.arrdate,
        deptdate:this.calcform.deptdate,
        packagecode:'',
        packagenum:1,
        ratecode:'',
        block:''
      },
      pickerOptions: {
        disabledDate(time) { return time.getTime() < Date.now() - 8.64e7; }
      },
      buttonLoading:false
    }
  },
  computed:{
    dateselect:{
      get:function(){
        return this.kitSearchForm.startdate!=null && this.kitSearchForm.enddate!=null?[this.kitSearchForm.startdate,this.kitSearchForm.enddate]:[];
      },
      set:function(dateValue){
        this.kitSearchForm.startdate = dateValue!=null&&dateValue.length>0?dateValue[0]:'';
        this.kitSearchForm.enddate = dateValue!=null&&dateValue.length>1?dateValue[1]:'';
      }
    },
    orderdate:{
      get:function(){
        return this.orderform.arrdate!=null && this.orderform.deptdate!=null?[this.orderform.arrdate,this.orderform.deptdate]:[];
      },
      set:function(dateValue){
        this.orderform.arrdate = dateValue!=null&&dateValue.length>0?dateValue[0]:'';
        this.orderform.deptdate = dateValue!=null&&dateValue.length>1?dateValue[1]:'';
      }
    },
    itemlist:{
      get:function (){
        let items=[];
        let _orderform=this.orderform;
        this.kitdetailData.forEach(row=>{
          if(row.hasOwnProperty('productdesc')&&(row.productdesc==null||row.productdesc.length<=0)){
            items.push({
              id: row.id,
              ratecode:this.ratecode,
              product:row.productcode,//要正确的产品代码
              productdesc: row.productdesc, //要返回对应的产品代码
              restaurant: row.productgroup, //填充餐厅
              arrdate:_orderform.arrdate,  //JUST 2021.5.28 修改.选择日期之后.变成 string 类型.
              deptdate:row.producttype=='R'?(typeof _orderform.arrdate =='string'?reckonDayStr(_orderform.arrdate,row.days):reckonDay( _orderform.arrdate,row.days) ):_orderfrom.deptdate,
              producttype:row.producttype,
              num:row.num*this.orderform.packagenum,
              block:'',//展开套餐时.库存可能是从Block 里面拿
              price:row.price,
              lfix: row.fixrate?1:0 //根据设置判断.0为非固定价格.1为固定价格
            });
            //回调通知前发起套餐价格计算
          }
        });
        return items;
      }
    },
    roomitems:{
      get:function(){
        let items=[];
        this.kitdetailData.forEach(row=>{
          if(row.producttype=='R'&&row.productdesc!=null&&row.productdesc.length>0){
            items.push(row.productcode);
          }
        });
        return items;
      }

    }
  },
  watch:{
    show: function () {
      this.visible=this.show;
    },
    calcform(){ //监听对象没起效果....先用单个属性来
      this.kitSearchForm.startdate=this.calcform.arrdate;
      this.kitSearchForm.enddate=this.calcform.deptdate;
      this.orderform=this.calcform;
    },
    arrdate(){
      this.kitSearchForm.startdate=this.arrdate;
      this.orderform.arrdate=this.arrdate;
    },
    deptdate(){
      this.kitSearchForm.enddate=this.deptdate;
      this.orderform.deptdate=this.deptdate;
    }
  },
  mounted: function(){
    // this.loadKitTable();
  },
  methods:{
    initDialog(){
      this.$refs.kitcodeinput.focus();
      select_data({"keyname":"ratecode"}).then(response => {
        this.ratecodes = select_res(response.data,"ratecode");
      });
      this.ratecode=this.selectKit='';
      Object.assign(this.kitSearchForm, this.calcform)
      Object.assign(this.orderform,this.calcform)
      this.kitSearchForm={
        code:'',
        startdate:this.calcform.arrdate,
        enddate:this.calcform.deptdate,
        pages:{total:0, currentpage:1, pagesize:3}
      };
      this.ratecode='SKJ'; //TODO 改成读取配置 每次打开.都选这个
      this.refreshKitTable();
    },
    closeDialog(){
      // this.$emit('update:visible',false);
      this.visible=false; //触发dialog 关闭的同时. 也触发外层变量关闭
      // this.visible=false;
      this.$emit('update:show',false);
    },
    typeFormatter(row){
      return row.type=="T"?"限时套餐":"普通套餐";
    },
    itemTypeFormatter(row){
      return row.itemtype=="M"?"多选一":"固定项目";
    },
    productTypeFormatter(row){
      let producttype = row.producttype;
      if(producttype=="R"){
        return "客房";
      }else if(producttype=="T"){
        return "票务";
      }else if(producttype=="C"){
        return "餐饮";
      }else if(producttype=="S"){
        return "娱乐";
      }else if(producttype=="B"){
        return "车船";
      }else if(producttype=="G"){
        return "导游";
      }else if(producttype=="Z"){
        return "自定义项目";
      }
      return producttype;
    },
    updProfileIno(selectValue){
      this.orderform.guestname=selectValue.name;
      this.orderform.profileid=selectValue.profileid;
      this.orderform.telno=selectValue.mobile;//selectValue.mobile;
      this.$refs.orderform.validate();
    },
    productSelect(row,index){
      let productdesc = row.productdesc;
      if(productdesc!=null && productdesc.length>0){
        let kititem = row.selectMap[productdesc];
        if(kititem!=null){
          row.num = kititem.num;
          row.price = kititem.price;
          row.id = kititem.id;
          row.productcode = kititem.productcode;
          row.days = kititem.days;
          row.fixrate = kititem.fixrate;
          row.productgroup = kititem.restaurant;//选择后要补上餐厅
          this.kitdetailData.splice(index,1,row);
        }
        this.calcProductPrice();
      }
    },
    refreshKitTable(){
      this.kitSearchForm.pages.currentpage = 1;
      this.loadKitTable();
    },
    loadKitTable(){
      this.kitloading = true;
      productkit_list(this.kitSearchForm).then(response => {
        this.kitData = response.data.records;
        this.kitSearchForm.pages.total = response.data.total;
        if(this.kitData.length>0){
          this.$refs.kitTable.setCurrentRow(this.kitData[0]);
          // this.loadKitDetailTable(this.kitData[0]);//在currentChange里调用过了
        }
      }).finally(() => { this.kitloading = false;})
    },
    loadKitDetailTable(row){
      if(this.kitData.length>0){
        this.kitdetailloading = true;
        let kitcode = row!=null?row.code:this.selectKit;//如果只修改了kititem这里传null
        this.selectKit = kitcode;
        kititem_reslist({"kitcode":kitcode,"startdate":this.kitSearchForm.startdate}).then(response => {
          this.selectValue = '';
          this.kitdetailData = response.data;
          this.calcProductPrice();
        }).finally(() => { this.kitdetailloading = false;})
      }else{
        this.kitdetailData = [];
      }
    },
    handleKitCurrentChange(val){
      this.kitSearchForm.pages.currentpage = val;
      this.loadKitTable();
    },
    tableSummary(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '总价';
          return;
        }
        if (column.property=='price') {
          const values = data.map(function(item){
            return Number(item[column.property]);
          });
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
      });
      return sums;
    },
    calcProductPrice(){  //实时计算套餐价格
      let  form={...this.orderform};
      let items=[];
      let _ratecode=this.ratecode;
      let _orderform=this.orderform;
      this.kitdetailData.forEach(row=>{
        if(row.hasOwnProperty('productdesc')&&(row.productdesc!=null||row.productdesc.length>0)){
          items.push({
            id: row.id,
            ratecode:_ratecode,
            product:row.productcode,//要正确的产品代码
            productdesc: row.productdesc, //要返回对应的产品代码
            restaurant: row.productgroup, //填充餐厅
            arrdate:_orderform.arrdate,
            deptdate:row.producttype=='R'?(typeof _orderform.arrdate =='string'?reckonDayStr(_orderform.arrdate,row.days):reckonDay( _orderform.arrdate,row.days) ):_orderform.arrdate, //如果是客房产品.根据房晚数计算离店日期.
            producttype:row.producttype,
            num:row.num*_orderform.packagenum,
            block:'',//展开套餐时.库存可能是从Block 里面拿
            price:row.price,
            lfix: row.fixrate?1:0 //根据设置判断.0为非固定价格.1为固定价格
          });
          //回调通知前发起套餐价格计算
        }
      });
      form.items=items;
      form.packagecode=this.selectKit;
      form.packagenum=this.orderform.packagenum;
      let _this=this;
      calcbatchprice(form).then(response => {
        // this.itemlist = response.data.records;
        _this.totalprice = response.data.totalamount;//.toFixed(2);
        this.totalprice=response.data.totalamount;
        // this.$forceUpdate();

      }).finally(() => { this.loading = false;})

    },
    saveKit(){
      let items=[]; //
      if(this.ratecode===''){
        this.$message({ message: '请选择价格码!', type: 'warning'});
        return false;
      }
      let _orderform=this.orderform;
      let _ratecode=this.ratecode;
      this.kitdetailData.forEach(row=>{
        if(!row.hasOwnProperty('productdesc')||row.productdesc==null||row.productdesc.length<=0){
          this.$message({ message: '请选择具体产品!', type: 'warning'});
          return false;
        }else{
          items.push({
            id: row.id,
            ratecode:_ratecode,
            product:row.productcode,//要正确的产品代码
            productdesc: row.productdesc, //要返回对应的产品代码
            restaurant: row.productgroup, //填充餐厅
            arrdate:_orderform.arrdate,
            deptdate:row.producttype=='R'?(typeof _orderform.arrdate =='string'?reckonDayStr(_orderform.arrdate,row.days):reckonDay( _orderform.arrdate,row.days) ):_orderform.arrdate, //如果是客房产品.根据房晚数计算离店日期.
            producttype:row.producttype,
            num:row.num*_orderform.packagenum,
            block:'',//展开套餐时.库存可能是从Block 里面拿
            price:row.price,
            lfix: row.fixrate?1:0 //根据设置判断.0为非固定价格.1为固定价格
          });
          //回调通知前发起套餐价格计算
        }
      });
      let  form={...this.orderform};
      form.items=items;
      form.packagecode=this.selectKit;
      form.packagenum=this.orderform.packagenum;
      form.totalAmount = this.totalprice;

      this.$refs['orderform'].validate((valid) => {
        if (valid) {
          this.buttonLoading = true;
          batchorder(form).then(response => {
            this.orderid=response.data.orderid;
            this.visible = false;
            this.$emit("refresh");
            this.$msgbox({ title: '消息', message: '保存成功! 订单号:'+this.orderid, confirmButtonText: '关闭'}).catch(() => {});
          }).finally(()=>{
            this.buttonLoading = false;
          });
        } else {
          return false;
        }
      });


      // let kitinfo={
      //   kitcode:this.selectKit,
      //   kitnum:this.saveKitForm.num,
      //   kitdesc:this.selectKit
      // };
      // if(items.length>0){
      //   this.$emit("selected", items,kitinfo);
      //   this.$emit('update:show',false);
      // }

    },
  }
}
</script>

<style scoped>
.pricelabel{
  margin-top: 10px;
  color:#c11a25;
  font-size: 22px;
  font-weight: bolder;
}

</style>
