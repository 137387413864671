<template>
  <div>
    <el-form :inline="true" :model="form" class="profile-search-form">
      <el-form-item>
        <el-input ref="profileidInput" v-model="form.profileid" placeholder="请输入档案号或会员号" clearable @keyup.enter.native="refreshProfileTable"/>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.name" placeholder="请输入姓名" clearable @keyup.enter.native="refreshProfileTable"/>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.telno" placeholder="请输入电话" clearable @keyup.enter.native="refreshProfileTable"/>
      </el-form-item>
      <el-form-item>
        <el-input v-model="form.idnumber" placeholder="请输入证件号" clearable @keyup.enter.native="refreshProfileTable"/>
      </el-form-item>
      <el-form-item>
        <el-select v-model="form.type" placeholder="请选择档案类型" clearable multiple collapse-tags>
          <el-option label="散客" value=""/>
          <el-option label="公司" value="C"/>
          <el-option label="旅行社" value="T"/>
          <el-option label="团队" value="G"/>
          <el-option label="客源" value="S"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="refreshProfileTable">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="profileSelectData" style="width: 100%" max-height="350" border @sort-change="handleSortChange">
      <el-table-column fixed prop="operation" min-width="80">
        <template slot-scope="operatescope">
          <el-button size="mini" type="primary" @click="handleSelect(operatescope.row)">选择</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="type" label="类型" min-width="80" sortable="custom" :formatter="typeFormatter"/>
      <el-table-column prop="name" label="客户名称" min-width="150" sortable="custom"/>
      <el-table-column prop="telephone" label="电话" min-width="100" :formatter="telFormatter"/>
      <el-table-column prop="idnum" label="证件号码" min-width="150"/>
      <el-table-column prop="linkname" label="联系人" min-width="150" :formatter="linkFormatter"/>
      <el-table-column prop="province" label="省份" min-width="80" sortable="custom"/>
      <el-table-column prop="city" label="城市" min-width="80" sortable="custom"/>
      <el-table-column prop="memberid" label="会员号" min-width="150"/>
      <el-table-column prop="profileid" label="档案号" min-width="180"/>
    </el-table>
    <el-pagination :current-page="form.pages.currentpage" :page-size="form.pages.pagesize"
                   layout="total, prev, pager, next, jumper" :total="form.pages.total" style="margin-top: 10px;float: right;" background
                   @current-change="handleCurrentChange"/>
  </div>
</template>

<script>
  import {profile_list} from '@/api/profile/profile';
  import {getProfileType} from '@/utils/status-format';

  export default {
      name: "ProfileSelect",
      props: {
        profileid:{ type: String,default: ''},
        name:{ type: String,default: ''},
        telno:{ type: String,default: ''},
        type:{ type: Array,default: () => []},
        channel:{ type: String,default: ''},//channel有值只能查看渠道关联的档案
        relation:{ type: String,default: ''},//relation有值只能查看这个档案关联的档案
        visible: { type: Boolean, default: false}
      },
      data(){
          return {
            form:{
              profileid:this.profileid, name:this.name, telno:this.telno, channel:this.channel, relation: this.relation,type:this.type,idnumber:'',
              pages:{total:0, currentpage:1, pagesize:20, sortname:'name', sortorder:'ascending'}
            },
            profileSelectData:[],loading:false
          }
      },
      watch: {
        channel: function(val){ this.form.channel = val; },
        name: function(val){ this.form.name = val; },
        telno: function(val){ this.form.telno = val; },
        profileid: function(val){ this.form.profileid = val; },
        relation: function(val){ this.form.relation = val; },
        type: function(val){ this.form.type = val; },
        visible: function(val){
          if(val){
            this.$refs.profileidInput.focus();
            this.loadProfileTable();
          }
        }
      },
      mounted: function(){
        this.$refs.profileidInput.focus();
        this.loadProfileTable();
      },
      methods:{
        typeFormatter(row){
          return getProfileType(row.type);
        },
        telFormatter(row){
          return row.mobile.length>0?row.mobile:row.phone;
        },
        linkFormatter(row){
          return row.linkname+' '+row.linktel;
        },
        handleCurrentChange(val){
          this.form.pages.currentpage = val;
          this.loadProfileTable();
        },
        handleSortChange(column){
          this.form.pages.sortname = column.prop;
          this.form.pages.sortorder = column.order;
          this.loadProfileTable();
        },
        refreshProfileTable(){
          this.form.pages.currentpage = 1;
          this.loadProfileTable();
        },
        loadProfileTable(){
          this.loading = true;
          profile_list(this.form).then(response => {
            this.profileSelectData = response.data.records;
            this.form.pages.total = response.data.total;
          }).finally(() => { this.loading = false;})
        },
        handleSelect(row){
          this.$emit('selectValue', row);
        }
      }
    }
</script>

<style scoped>

</style>