<template>
  <div class="colroom">
    <div style="margin-bottom: 10px">
      <el-button type="primary" icon="el-icon-edit-outline" :disabled="history" @click="openTicket(0)">新增</el-button>
      <el-button icon="el-icon-refresh" @click="refresh(true)">刷新</el-button>
      <span v-if="qrcodeurl!==''" style="margin-left: 10px">二维码地址: {{ qrcodeurl }}</span>
    </div>
    <el-table v-loading="loading" :data="tableData" style="width: 100%" :summary-method="tableSummary" :show-summary="summary!=''"
              :row-class-name="tableRowClassName" max-height="450" border @sort-change="handleSortChange">
      <el-table-column prop="status" label="状态" min-width="70" sortable="custom">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status==='C'||scope.row.status==='R' ? 'danger' : 'primary'" disable-transitions>{{ statusFormatter(scope.row.status) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="bookingid" label="订单号" min-width="150"/>
      <el-table-column prop="name" label="姓名" min-width="100" :show-overflow-tooltip="true" sortable="custom"/>
      <el-table-column prop="telephone" label="电话" min-width="110"/>
      <el-table-column prop="ticket" label="票型" min-width="180" :show-overflow-tooltip="true" sortable="custom"/>
      <!--
            <el-table-column prop="ticketgroup" label="票型组" min-width="100" sortable="custom"/>
            -->
      <el-table-column prop="date" label="游玩日期" min-width="100" sortable="custom"/>
      <el-table-column prop="num" label="票数" min-width="50"/>
      <!--
            <el-table-column prop="person" label="人数" min-width="50"/>
            -->
      <el-table-column prop="totalprice" label="总价" min-width="80"/>
      <el-table-column prop="checknum" label="检票" min-width="50"/>
      <el-table-column prop="returnnum" label="退票" min-width="50"/>
      <el-table-column prop="subchannel" label="渠道" min-width="100" sortable="custom"/>
      <!--
            <el-table-column prop="channel" label="渠道" min-width="100" sortable="custom"/>
            <el-table-column prop="kitcode" label="套餐" min-width="100" sortable="custom"/>
            <el-table-column prop="interfaceid" label="线下订单号" min-width="210" :formatter="interfaceidFormatter"/>
            -->
      <el-table-column prop="colno" label="综合订单号" min-width="150"/>
      <el-table-column fixed="right" prop="operation" label="操作" min-width="160">
        <template slot-scope="operatescope">
          <el-button size="mini" type="primary" @click="openTicket(operatescope.row.sqlid)">编辑</el-button>
          <el-button size="mini" type="danger" :disabled="(operatescope.row.status!=='N'&&operatescope.row.status!=='P')||form.history"
                     :loading="operatescope.row.cancelLoading" @click="handleCancel(operatescope.row)">取消</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :current-page="form.pages.currentpage" :page-size="form.pages.pagesize"
                   layout="total, prev, pager, next, jumper" :total="form.pages.total" style="margin-top: 10px;float: right;" background
                   @current-change="handleCurrentChange"/>

    <el-dialog v-dialogDrag :visible.sync="showTicketDetailDialog" :close-on-click-modal="false" width="80%" top="8vh" append-to-body>
      <TicketDetail ref="ticketdetail" :ticketid="ticketid" :history="form.history" :colno="form.bookingid" :visible.sync="showTicketDetailDialog" @refresh="refresh(false)"/>
    </el-dialog>
  </div>
</template>

<script>
    import {cancel_ticketrs, ticketrs_list} from '@/api/order/ticket';
    import {getTicketStatus} from '@/utils/status-format';
    import TicketDetail from '@/views/order/ticket/ticketdetail';

    export default {
        name: "ColTicket",
        components: {
            TicketDetail
        },
        props: {
            colno:{ type: String,default: ''},
            qrcodeurl:{ type: String,default: ''},
            // profileid:{ type: String,default: ''},
            custom:{ type: String,default: ''},//不为空可以自定义搜索框
            summary:{ type: String,default: ''},//不为空可以进行统计
            // cancel:{ type: Boolean,default: false},
            history:{ type: Boolean,default: false}
        },
        data() {
            return {
                form:{
                    // usedate:formatDate(new Date()), name:'', status:['N','P','O'],
                    // tickettype:'', ticketgroup:'', channel:'', subchannel:'',
                    // profileid:this.profileid,
                    bookingid:this.colno, history:this.history,
                    pages:{total:0, currentpage:1, pagesize:20, sortname:'date,name', sortorder:'ascending'}
                },
                // pickerOptions: {
                //     shortcuts: [{ text: '今天', onClick(picker) { picker.$emit('pick', new Date()); } }]
                // },
                // tickettypes:[],ticketgroups:[],subchannels:[],channels:[],
                tableData: [],
                loading:false, showTicketDetailDialog:false, ticketid:0
            }
        },
        watch: {
            colno: function(val){ this.form.bookingid = val; },
            // profileid: function(val){
            //     this.form.profileid = val;
            //     if(val!=null && val.length>0){
            //         this.form.status = ['N','P','O','F'];
            //         this.form.usedate = '';
            //     }
            // },
            // cancel: function(val){
            //     if(this.profileid.length>0){//是否包含取消这个只有在档案里用到
            //         if(val){
            //             this.form.status = [];
            //         }else{
            //             this.form.status = ['N','P','O','F'];
            //         }
            //     }
            // },
            history: function(val){ this.form.history = val; }
        },
        mounted: function(){
            // if(this.custom==''){
            //     this.$refs.bookingidinput.focus();
            //     select_data({"keyname":"subchannel,ticket,ticketgroup"}).then(response => {
            //         this.tickettypes = select_res(response.data,"ticket");
            //         this.ticketgroups = select_res(response.data,"ticketgroup");
            //         // this.channels = select_res(response.data,"channel");
            //         this.subchannels = select_res(response.data,"subchannel");
            //     });
            // }
            this.loadTable();
        },
        methods:{
            statusFormatter(val){
                return getTicketStatus(val);
            },
            tableSummary(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    if (column.property=='num'||column.property=='checknum'||column.property=='returnnum'
                        ||column.property=='person'||column.property=='totalprice') {
                        const values = data.map(function(item){
                            if((item['status']!='R'&&item['status']!='C')||column.property=='num'||column.property=='checknum'||column.property=='returnnum'){
                                return Number(item[column.property]);
                            }else{
                                return 0;
                            }
                        });
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        if (column.property=='totalprice') {//总价列合计保留两位小数
                            sums[index] = sums[index].toFixed(2);
                        }
                    }
                });
                return sums;
            },
            tableRowClassName({row}){
                if(row.status==='C'||row.status==='R'){
                    return 'cancel-row';
                }else if(row.status==='P'){
                    return 'start-row';
                }else if(row.status==='F'||row.status==='O'){
                    return 'finish-row';
                }
                return null;
            },
            handleSortChange(column){
                this.form.pages.sortname = column.prop;
                this.form.pages.sortorder = column.order;
                this.loadTable();
            },
            handleCurrentChange(val){
                this.form.pages.currentpage = val;
                this.loadTable();
            },
            loadTable(){
                this.loading = true;
                ticketrs_list(this.form).then(response => {
                    this.tableData = response.data.records;
                    this.form.pages.total = response.data.total;
                }).finally(() => { this.loading = false;})
            },
            refresh(search){//刷新的时候也把col_rs刷一下
                if(search||!this.form.history){
                    this.loadTable();
                    this.$emit('refreshCol');
                }
            },
            openTicket(id) {
                this.ticketid = id;
                this.showTicketDetailDialog = true;
            },
            handleCancel(row){
                this.$confirm('确定取消?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
                    this.$set(row, 'cancelLoading', true);
                    cancel_ticketrs({"sqlid":row.sqlid}).then(response => {
                      if(response.data.flag){
                        this.$msgbox({ title: '消息', message: '取消成功! '+response.data.message, confirmButtonText: '关闭'}).catch(() => {});
                      }else{
                        this.$msgbox({ title: '消息', message: response.data.message, confirmButtonText: '关闭'}).catch(() => {});
                      }
                      this.loadTable();
                      this.$emit('refreshCol');
                    }).finally(()=>{
                        this.$set(row, 'cancelLoading', false);
                    });
                }).catch(() => {});
            }
        }
    }
</script>

<style scoped>
    .el-table .cancel-row {
        background: #FFEBEE;
    }
    .el-table .finish-row {
        background: #E8F5E9;
    }
    .el-table .start-row {
        background: #E3F2FD;
    }
</style>