<template>
  <div>


    <!--<el-dropdown trigger="click" @command="reconn">
&lt;!&ndash;      <div>&ndash;&gt;
&lt;!&ndash;        &lt;!&ndash;            <svg-icon class-name="icon-icon" icon-class="size" v-if="lconn"></svg-icon>&ndash;&gt;&ndash;&gt;
&lt;!&ndash;        &lt;!&ndash;            <svg-icon class-name="icon-icon" icon-class="size" v-else="lconn"></svg-icon>&ndash;&gt;&ndash;&gt;
&lt;!&ndash;        &lt;!&ndash;            <svg-icon class-name="size-icon" icon-class="size" />&ndash;&gt;&ndash;&gt;
&lt;!&ndash;        <i v-if="lconn" class="el-icon-check"/>&ndash;&gt;
&lt;!&ndash;        <i v-else class="el-icon-close"/>&ndash;&gt;
&lt;!&ndash;      </div>&ndash;&gt;
      &lt;!&ndash; 用了el-dropdown 没有el-dropdown-menu会报错 先加上 &ndash;&gt;
      <el-dropdown-menu slot="dropdown">
        &lt;!&ndash;      <el-dropdown-item command="R">客房</el-dropdown-item>&ndash;&gt;
        &lt;!&ndash;      <el-dropdown-item command="T">票务</el-dropdown-item>&ndash;&gt;
        &lt;!&ndash;      <el-dropdown-item command="C">餐饮</el-dropdown-item>&ndash;&gt;
        &lt;!&ndash;      <el-dropdown-item command="Z">自定义</el-dropdown-item>&ndash;&gt;
      </el-dropdown-menu>
    </el-dropdown>-->

    <el-dialog v-dialogDrag :visible.sync="showCti" :title="'来电呼叫:'+telno"
               :destroy-on-close="true"
               :close-on-click-modal="false"
               width="1068px" append-to-body @close="closeCti">
      <cti-main ref="ctimain" :telno="telno" />
    </el-dialog>
  </div>
</template>

<script>
  import CtiMain from "@/views/order/cti/cti-main"
  import Vue from "vue"
  export default {
    name:'Cti',
    components: {CtiMain},
    data() {
      return {
        lconn:true,
        showCti:false,
        telno:''
      }
    },
    created() { // 页面创建生命周期函数
      // this.initWebSocket()
    },
    destroyed: function () { // 离开页面生命周期函数
      // this.websocketclose();
    },
    methods: {
      reconn: function () {
        this.initWebSocket();
      },
      initWebSocket: function () {
        var es = new EventSource(Vue.prototype.BASE_API+ '/cti/sub/'+this.$store.state.user.userid);
        let this_=this;
        es.addEventListener('message', function (e) {
          this_.handleEvent(JSON.parse(e.data))
        })
      },
      handleEvent:function(pushdata){
        if(pushdata.event=='TEL'){
          this.telno=pushdata.data.telno;
          this.showCti=true;
        }else if(pushdata.event=='TRACE'){
          if(pushdata.data.userid==this.$store.state.user.userid){
            this.$store.commit("user/INC_MSGCOUNT");//如果有消息,改变msgCount值重获消息
          }
        }
      },
      closeCti(){
        this.$refs.ctimain.reset;
      }
    }
  }
</script>

<style scoped>

</style>
