import request from '@/utils/request'

export function channel_list(data) {
  return request({
    url: '/api/channel/channel_list',
    method: 'post',
    data
  })
}

export function load_channel(data) {
  return request({
    url: '/api/channel/load_channel',
    method: 'post',
    data
  })
}

export function save_channel(data) {
  return request({
    url: '/api/channel/save_channel',
    method: 'post',
    data
  })
}

export function cancel_channel(data) {
  return request({
    url: '/api/channel/cancel_channel',
    method: 'post',
    data
  })
}

export function strategy_list(data) {
  return request({
    url: '/api/channel/strategy_list',
    method: 'post',
    data
  })
}

export function load_strategy(data) {
  return request({
    url: '/api/channel/load_strategy',
    method: 'post',
    data
  })
}

export function save_strategy(data) {
  return request({
    url: '/api/channel/save_strategy',
    method: 'post',
    data
  })
}

export function delete_strategy(data) {
  return request({
    url: '/api/channel/delete_strategy',
    method: 'post',
    data
  })
}

export function subchannel_list(data) {
  return request({
    url: '/api/channel/subchannel_list',
    method: 'post',
    data
  })
}

export function load_subchannel(data) {
  return request({
    url: '/api/channel/load_subchannel',
    method: 'post',
    data
  })
}

export function save_subchannel(data) {
  return request({
    url: '/api/channel/save_subchannel',
    method: 'post',
    data
  })
}

export function delete_subchannel(data) {
  return request({
    url: '/api/channel/delete_subchannel',
    method: 'post',
    data
  })
}

export function subchannel_types() {
  return request({
    url: '/api/channel/subchannel_types',
    method: 'post'
  })
}

export function attr_list(data) {
  return request({
    url: '/api/channel/attr_list',
    method: 'post',
    data
  })
}

export function load_attr(data) {
  return request({
    url: '/api/channel/load_attr',
    method: 'post',
    data
  })
}

export function save_attr(data) {
  return request({
    url: '/api/channel/save_attr',
    method: 'post',
    data
  })
}

export function delete_attr(data) {
  return request({
    url: '/api/channel/delete_attr',
    method: 'post',
    data
  })
}

export function save_attr_package(data) {
  return request({
    url: '/api/channel/save_attr_package',
    method: 'post',
    data
  })
}

export function save_attr_roomtype(data) {
  return request({
    url: '/api/channel/save_attr_roomtype',
    method: 'post',
    data
  })
}

export function query_ar(data) {
  return request({
    url: '/api/channel/query_ar',
    method: 'post',
    data
  })
}
