//获取综合预定状态描述
export function getColStatus(status,pstatus){
    let showStatus = "";
    if(status==="N"){
        showStatus = "预定";
    }else if(status==="F"){
        showStatus = "完成";
    }else if(status==="C"){
        showStatus = "取消";
    }else if(status==="A"){
        showStatus = "到店";
    }else if(status==="H"){
        showStatus = "应到未到";
    }else if(status==="P"){
        showStatus = "退款中";
    }
    if(pstatus!=null){
        showStatus = showStatus +"   "+(pstatus==='P'?'已付款':'未付款');
    }
    return showStatus;
}
//获取预付款状态描述
export function getPrepayStatus(status){
    let showStatus = "";
    if(status==="P"){
        showStatus = "部分";
    }else if(status==="A"){
        showStatus = "全额";
    }else if(status==="R"){
        showStatus = "退款";
    }else{
        showStatus = "全额";
    }
    return showStatus;
}
//获取票务状态描述-包括是否付款
export function getTicketStatusAndPay(status, pstatus){
    let showStatus = getTicketStatus(status);
    if(pstatus!=null && pstatus!==''){
        showStatus = showStatus +"   "+(pstatus==='P'?'已付款':'未付款');
    }
    return showStatus;
}
export function getTicketStatus(status){
    let showStatus = "";
    if(status==="N"){
        showStatus = "预定";
    }else if(status==="P"){
        showStatus = "出票";
    }else if(status==="R"){
        showStatus = "退票";
    }else if(status==="O"){
        showStatus = "检票";
    }else if(status==="F"){
        showStatus = "完成";
    }else if(status==="C"){
        showStatus = "取消";
    }else if(status==="S"){
        showStatus = "退款中";
    }
    return showStatus;
}
//获取商超订单状态描述
export function getMallStatus(status){
    let showStatus = "";
    if(status==="P"){
        showStatus = "出单";
    }else if(status==="C"){
        showStatus = "取消";
    }
    return showStatus;
}
//获取团购票状态描述
export function getGTicketStatus(status){
    let showStatus = "";
    if(status==="N"){
        showStatus = "预定";
    }else if(status==="P"){
        showStatus = "审核下发";
    }else if(status==="R"){
        showStatus = "审核失败";
    }else if(status==="C"){
        showStatus = "取消";
    }
    return showStatus;
}
//获取餐饮状态描述
export function getCateringStatus(status){
    let showStatus = "";
    if(status==="N"){
        showStatus = "预定";
    }else if(status==="S"){
        showStatus = "到店";
    }else if(status==="F"){
        showStatus = "完成";
    }else if(status==="C"){
        showStatus = "取消";
    }
    return showStatus;
}
//获取餐饮状态描述
export function getMeetingStatus(status){
    let showStatus = "";
    if(status==="N"){
        showStatus = "预定";
    }else if(status==="A"){
        showStatus = "开始";
    }else if(status==="F"){
        showStatus = "结束";
    }else if(status==="C"){
        showStatus = "取消";
    }
    return showStatus;
}
//获取档案类型
export function getProfileType(type){
    let showType = "";
    if(type===""){
        showType = "散客";
    }else if(type==="C"){
        showType = "公司";
    }else if(type==="T"){
        showType = "旅行社";
    }else if(type==="G"){
        showType = "团队";
    }else if(type==="S"){
        showType = "客源";
    }else{
        showType = "散客";
    }
    return showType;
}
//获取锁房/渠道状态描述
export function getBlockStatus(status){
    let showStatus = "";
    if(status==="O"){
        showStatus = "启用";
    }else if(status==="C"){
        showStatus = "取消";
    }else{
        showStatus = "初始";
    }
    return showStatus;
}

export function getRoomRsStatus(status) {
    let showStatus = "";
    if(status===""){
        showStatus = "预抵";
    }else if(status==="C"){
        showStatus = "到店";
    }else if(status==="X"){
        showStatus = "取消";
    }else if(status==="N"){
        showStatus = "应到未到";
    }else if(status==="L"){
        showStatus = "结账离店";
    }
    return showStatus;
}

//获取产品描述
export function getProductDesc(type){
    let showStatus = type;
    if(type==="R"){
        showStatus = "客房";
    }else if(type==="T"){
        showStatus = "票务";
    }else if(type==="C"){
        showStatus = "餐饮";
    }else if(type==="Z"){
        showStatus = "套餐自定义";
    }else if(type==="M"){
        showStatus = "会议";
    }
    return showStatus;
}
