<template>
  <div class="roomrsdetailcol-div">
    <el-dialog v-dialogDrag :visible.sync="showRoomDetailDialog" :close-on-click-modal="false" width="80%" top="3vh" append-to-body>
      <RoomrsDetail :history="history" :roomid="rsid" :visible.sync="showRoomDetailDialog"
                    :arrdate="params.arrdate" :deptdate="params.deptdate" :block="params.block"
                    :ratecode="params.ratecode" :rmtype="params.product"
                    :rscolno.sync="rscolno" :gotocol="true" @refresh="refresh" @gotoCol="gotoCol"/>
    </el-dialog>

    <el-dialog v-dialogDrag :visible.sync="showColDetailDialog" :close-on-click-modal="false" append-to-body width="80%" top="6vh">
      <ColDetail ref="coldetail" :colno="rscolno" :history="history" :visible.sync="showColDetailDialog"/>
    </el-dialog>
  </div>
</template>

<script>
  import {formatDate, reckonDay} from '@/utils/calcdate';
  import RoomrsDetail from '@/views/order/room/roomrsdetail';
  import ColDetail from '@/views/order/col/coldetail';
    export default {
        name: "RoomrsDetailCol",
        components: { RoomrsDetail,ColDetail },
        props: {
          rsid:{ type: Number,default: 0},
          history:{type: Boolean,default: false},
          visible:{type: Boolean,default: false},
          params: {
            type: Object,
            default: () => ({
              arrdate: formatDate(new Date()),
              deptdate: formatDate(reckonDay(new Date(), 1)),
              block: '',
              ratecode: '',
              product: ''
            })
          }
        },
        data() {
          return {
            showColDetailDialog:false,
            showRoomDetailDialog:false,
            rscolno:''
          }
        },
        watch: {
          visible: function(val){
            if(val){
              this.showRoomDetailDialog = true;
            }
          },
          showRoomDetailDialog: function(val) {
            this.$emit('update:visible',val);//更新上一层
          }
        },
        methods:{
          refresh(){
            this.$emit('refresh');//通知上一层
          },
          gotoCol(){
            this.showRoomDetailDialog = false;
            this.showColDetailDialog = true;
          }
        }
    }
</script>

<style scoped>

</style>