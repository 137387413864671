import request from '@/utils/request'

export function meeting_list(data) {
    return request({
        url: '/api/meeting/meeting_list',
        method: 'post',
        data
    })
}

export function load_meeting(data) {
    return request({
        url: '/api/meeting/load_meeting',
        method: 'post',
        data
    })
}

export function save_meeting(data) {
    return request({
        url: '/api/meeting/save_meeting',
        method: 'post',
        data
    })
}

export function cancel_meeting(data) {
    return request({
        url: '/api/meeting/cancel_meeting',
        method: 'post',
        data
    })
}

export function change_meeting(data) {
    return request({
        url: '/api/meeting/change_meeting',
        method: 'post',
        data
    })
}

export function meeting_resoucelist(data) {
    return request({
        url: '/api/meeting/meeting_resoucelist',
        method: 'post',
        data
    })
}