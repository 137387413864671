//解析下拉框数据
export function select_res(datas,key){
    let data = [];
    datas.forEach(function(row){
        if(row.keyname==key){
            data = row.values;
        }
    });
    return data;
}
//返回下拉框是否含有该值 没有返回空
export function filterValue(values,key){
    if(values!=null && values.length>0 && key!=null && key.length>0){
        for(let i=0; i<values.length; i++){
            if(values[i].code==key){
                return key;
            }
        }
    }
    return '';
}
//返回下拉框是否含有该值 这个值以,号分隔 没有返回空
export function filterValueArray(values,key){
    let returnStr = "";
    if(values!=null && values.length>0 && key!=null && key.length>0){
        let keyArray = key.split(",");
        for(let i=0; i<values.length; i++){
            for(let j=0; j<keyArray.length; j++){
                if(values[i].code==keyArray[j]){
                    returnStr = returnStr.length<=0?keyArray[j]:returnStr+","+keyArray[j];
                }
            }
        }
    }
    return returnStr;
}

export function filterOnlyValue(values,key){
    let data = [];
    if(key.length>0){
        for(let i=0; i<values.length; i++){
            if(values[i].code===key){
                data.push(values[i]);
                break;
            }
        }
    }
    return data;
}