<template>
  <div class="main-div">
    <el-container>
      <el-header height="40px">{{ showTitle }}<span style="float:right">{{ showStatus }}</span></el-header>
      <el-main>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px" class="base-form">
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="联系人" prop="name">
                <el-input ref="nameinput" v-model="form.name" maxlength="60">
                  <i slot="suffix" class="el-input__icon el-icon-s-grid" @click="showProfileDialog=true"/>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="电话号码" prop="telephone">
                <el-input ref="telinput" v-model="form.telephone" maxlength="20" clearable/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="证件号码">
                <el-input v-model="form.idnumber" maxlength="60" clearable/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="票务代码" prop="ticket">
                <el-select v-model="form.ticket" placeholder="请选择票务代码" filterable @change="getPrice">
                  <el-option v-for="item in tickets" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="票务组">
                <el-select v-model="form.ticketgroup" placeholder="请选择票务组" filterable @change="changeticketgroup">
                  <el-option v-for="item in ticketgroups" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="渠道" prop="subchannel">
                <el-select v-model="form.subchannel" placeholder="请选择渠道" filterable @change="getPrice">
                  <el-option v-for="item in subchannels" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="游玩日期" prop="date">
                <el-date-picker v-model="form.date" align="right" type="date" :picker-options="pickerOptions"
                                :clearable="false" value-format="yyyy-MM-dd" @change="getPrice"/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="价格代码">
                <el-select v-model="form.ratecode" placeholder="请选择价格代码" filterable @change="getPrice">
                  <el-option v-for="item in ratecodes" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="付款方式">
                <el-select v-model="form.payment" placeholder="请选择付款方式" filterable>
                  <el-option v-for="item in payments" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="单价">
                <el-input v-model="form.price" :readonly="!form.fix" @change="changeSinglePrice">
                  <template slot="append">
                    <el-checkbox v-model="form.fix" @change="getPrice">固定价</el-checkbox>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="数量" prop="num">
                <el-input-number v-model="form.num" :min="1" @change="changeNum"/>
                <label style="margin-left: 20px; font-weight: bold">总价: </label>
                <span class="pricelabel">{{ form.totalprice }}</span>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="人数" prop="person">
                <el-input-number v-model="form.person" :min="1"/>
              </el-form-item>
            </el-col>
            <!--
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="综合订单号">
                <el-input v-model="form.colno" readonly/>
              </el-form-item>
            </el-col>
            -->
            <!--
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="线下订单号">
                <el-input v-model="form.interfaceid" readonly/>
              </el-form-item>
            </el-col>
            -->
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="锁房">
                <el-select v-model="form.block" placeholder="请选择锁房" filterable>
                  <el-option v-for="item in blocks" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="4" :xs="12">
              <el-form-item label="检票数">
                <el-input v-model="form.checknum" readonly/>
              </el-form-item>
            </el-col>
            <el-col :md="4" :sm="4" :xs="12">
              <el-form-item label="退票数">
                <el-input v-model="form.returnnum" readonly/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="套餐">
                <el-input v-model="form.kitcode" readonly/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="二维码">
                <el-input v-model="form.qrcode" readonly style="width: 80%"/>
                <el-button type="primary" style="width: 20%" :disabled="!form.qrcode" @click="openQrCodeUrl(form.qrcode)">打开</el-button>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="辅助码">
                <el-input v-model="form.addition" readonly/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="场次">
                <el-input v-model="form.session"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10" style="margin-bottom: -20px">
            <el-col :md="16" :sm="16" :xs="24">
              <el-form-item label="备注">
                <el-input v-model="form.info" type="textarea" :rows="4" placeholder="请输入备注内容"/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="创建信息">
                <el-input v-model="createDateTime" readonly/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="修改信息">
                <el-input v-model="modifyDateTime" readonly/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider content-position="left">实名制信息</el-divider>
          <el-row>
            <el-form :inline="true" :model="profileInfoForm" style="margin-top: -10px; margin-bottom: -10px">
              <el-form-item label="姓名">
                <el-input ref="profileInfoName" v-model="profileInfoForm.name" clearable/>
              </el-form-item>
              <el-form-item label="手机号">
                <el-input v-model="profileInfoForm.mobile" clearable/>
              </el-form-item>
              <el-form-item label="证件类型">
                <el-select v-model="profileInfoForm.idtype" placeholder="请选择">
                  <el-option label="身份证" value="01"/>
                  <el-option label="护照" value="02"/>
                  <el-option label="港澳通行证" value="03"/>
                  <el-option label="台胞证" value="04"/>
                  <el-option label="户口本" value="07"/>
                  <el-option label="军官证" value="10"/>
                  <el-option label="外国人居留证" value="15"/>
                  <el-option label="学生证" value="16"/>
                </el-select>
              </el-form-item>
              <el-form-item label="证件号">
                <el-input v-model="profileInfoForm.idnum" clearable/>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" icon="el-icon-plus" @click="addProfileInfo">添加</el-button>
              </el-form-item>
            </el-form>
            <el-table :data="profileInfoData" style="width: 100%" max-height="150" border>
              <el-table-column prop="name" label="姓名" min-width="150"/>
              <el-table-column prop="mobile" label="手机号" min-width="100"/>
              <el-table-column prop="idtype" label="证件类型" min-width="80" :formatter="idTypeFormatter"/>
              <el-table-column prop="idnum" label="证件号" min-width="150"/>
              <el-table-column min-width="50">
                <template slot-scope="scope">
                  <el-button size="mini" type="danger" @click="deleteProfileInfo(scope.$index)">移除</el-button>
                </template>
              </el-table-column>
            </el-table>
            <span>总共 {{ profileInfoData.length }} 条人员信息</span>
          </el-row>
        </el-form>
      </el-main>
      <el-footer height="40px">
        <!--        <el-button type="primary" icon="el-icon-notebook-2" @click="showProfileInfoDialog=true">实名制</el-button>-->
        <el-button type="primary" icon="el-icon-edit-outline" :loading="buttonLoading" :disabled="form.status!=='N'||history" @click="handleSave">保存</el-button>
        <el-button type="danger" icon="el-icon-warning-outline" :disabled="form.sqlid<=0||(form.status!=='N'&&form.status!=='P')||history"
                   :loading="buttonLoading" @click="handleCancel">取消</el-button>
        <el-button type="primary" icon="el-icon-money" :disabled="form.sqlid<=0||form.status!=='N'||form.pstatus==='P'||history"
                   :loading="buttonLoading" @click="handlePay">支付</el-button>
        <el-button type="primary" icon="el-icon-position" :disabled="form.sqlid<=0||form.status!=='N'||form.pstatus==='P'||history||form.interfaceid.length>0"
                   :loading="buttonLoading" @click="handleResend">重发</el-button>
        <el-button v-if="gotocol" type="primary" icon="el-icon-refresh-left" :disabled="form.sqlid<=0||form.colno===''"
                   @click="gotoCol">综合预定</el-button>
        <el-dropdown style="margin-left: 10px" @command="handleCommand">
          <el-button type="primary" icon="el-icon-tickets">日志<i class="el-icon-arrow-down el-icon--right"/></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="U">用户日志</el-dropdown-item>
            <el-dropdown-item command="I">接口日志</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-footer>
    </el-container>
    <!-- 窗口Dialog -->
    <el-dialog v-dialogDrag title="选择档案" :visible.sync="showProfileDialog" :close-on-click-modal="false" width="85%" append-to-body>
      <ProfileSelect :profileid="form.profileid" :name="form.name" :telno="form.telephone" :type="['']" :visible.sync="showProfileDialog" @selectValue="selectProfile"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <el-dialog v-dialogDrag title="查看用户日志" :visible.sync="showLogDialog" :close-on-click-modal="false" width="70%" append-to-body>
      <UserLogs ref="userlogs" :type="'TICKET'" :bookingid="form.bookingid" :visible.sync="showLogDialog"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <el-dialog v-dialogDrag title="查看接口日志" :visible.sync="showInterfaceLogDialog" :close-on-click-modal="false" width="70%" top="7vh" append-to-body>
      <OrderLogs :crsno="form.bookingid" :otano="form.channelno" :pmsno="form.interfaceid" :visible.sync="showInterfaceLogDialog"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <!--    <el-dialog v-dialogDrag title="实名制人员信息" :visible.sync="showProfileInfoDialog" :close-on-click-modal="false" width="80%" append-to-body @opened="openProfileInfo">-->
    <!--      <el-form :inline="true" :model="profileInfoForm" class="profileinfo-form">-->
    <!--        <el-form-item label="姓名">-->
    <!--          <el-input ref="profileInfoName" v-model="profileInfoForm.name" clearable/>-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="手机号">-->
    <!--          <el-input v-model="profileInfoForm.mobile" clearable/>-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="证件类型">-->
    <!--          <el-select v-model="profileInfoForm.idtype" placeholder="请选择">-->
    <!--            <el-option label="身份证" value="01"/>-->
    <!--            <el-option label="护照" value="02"/>-->
    <!--            <el-option label="港澳通行证" value="03"/>-->
    <!--            <el-option label="台胞证" value="04"/>-->
    <!--            <el-option label="户口本" value="07"/>-->
    <!--            <el-option label="军官证" value="10"/>-->
    <!--            <el-option label="外国人居留证" value="15"/>-->
    <!--            <el-option label="学生证" value="16"/>-->
    <!--          </el-select>-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="证件号">-->
    <!--          <el-input v-model="profileInfoForm.idnum" clearable/>-->
    <!--        </el-form-item>-->
    <!--        <el-form-item>-->
    <!--          <el-button type="primary" icon="el-icon-plus" @click="addProfileInfo">添加</el-button>-->
    <!--        </el-form-item>-->
    <!--      </el-form>-->
    <!--      <el-table :data="profileInfoData" style="width: 100%" max-height="300" border>-->
    <!--        <el-table-column prop="name" label="姓名" min-width="150"/>-->
    <!--        <el-table-column prop="mobile" label="手机号" min-width="100"/>-->
    <!--        <el-table-column prop="idtype" label="证件类型" min-width="80" :formatter="idTypeFormatter"/>-->
    <!--        <el-table-column prop="idnum" label="证件号" min-width="150"/>-->
    <!--        <el-table-column min-width="50">-->
    <!--          <template slot-scope="scope">-->
    <!--            <el-button size="mini" type="danger" @click="deleteProfileInfo(scope.$index)">移除</el-button>-->
    <!--          </template>-->
    <!--        </el-table-column>-->
    <!--      </el-table>-->
    <!--      <span>总共 {{ profileInfoData.length }} 条人员信息</span>-->
    <!--      <div slot="footer" class="dialog-footer">-->
    <!--        <el-button type="primary" @click="saveProfileInfo">确定</el-button>-->
    <!--        <el-button @click="showProfileInfoDialog=false">退出</el-button>-->
    <!--      </div>-->
    <!--    </el-dialog>-->
    <!--
    <el-dialog title="短信" :visible.sync="showMsgDialog" :close-on-click-modal="false" width="70%" append-to-body>
      <MsgView :id="form.sqlid" :regno="form.bookingid" :type="'T'" :mobile="form.telephone"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <el-dialog title="票务接口" :visible.sync="showInterfaceDialog" :close-on-click-modal="false" width="70%" append-to-body>
      <InterfaceOp :logtype="'TICKET'" :type="'TICKET'"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    -->
    <el-dialog v-dialogDrag title="二维码信息" :visible.sync="showQrCodeDialog" :close-on-click-modal="false" top="3vh" width="30%" append-to-body>
      <iframe :src="form.qrcode" width="100%" height="500px"/>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showQrCodeDialog=false">退出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {load_ticketrs,save_ticketrs,cancel_ticketrs,pay_ticketrs,resend_ticket} from '@/api/order/ticket';
  import {name_exist} from '@/api/profile/profile';
  import {select_data,calc_price} from '@/api/common/custom-data';
  import {getTicketStatusAndPay} from '@/utils/status-format';
  import {select_res} from "@/utils/select_data_res";
  import '@/styles/pagecontainer.scss';
  import ProfileSelect from "@/views/common/profile-select";
  import UserLogs from "@/views/common/userlogs";
  import OrderLogs from "@/views/common/orderlogs";

  export default {
    name:'TicketDetail',
    components: {ProfileSelect,UserLogs,OrderLogs/*,MsgView,InterfaceOp*/},
    props: {
      colno:{ type: String,default: ''},
      ticketid:{ type: Number,default: 0},
      history:{type: Boolean,default: false},
      visible:{type: Boolean,default: true},
      gotocol:{type: Boolean,default: false}//是否显示跳转综合预定按钮
    },
    data() {
      return {
        form:{
          name:'',telephone:'',ticket:'',subchannel:''
        },
        profileInfoForm:{
          name:'',mobile:'',idnum:'',idtype:'01'
        },
        pickerOptions: {
          disabledDate(time) { return time.getTime() < Date.now() - 8.64e7; },//不能选之前的日期
          shortcuts: [{ text: '今天', onClick(picker) { picker.$emit('pick', new Date()); } }]
        },
        rules: {
          name: [{ required: true, message: '请输入联系人姓名', trigger: 'change' }],
          telephone: [{ required: true, message: '请输入电话号码', trigger: 'change' }],
          ticket: [{ required: true, message: '请选择票务代码', trigger: 'change' }],
          // block: [{ required: true, message: '请选择锁房', trigger: 'change' }],//锁房不是必填
          // channel: [{ required: true, message: '请选择渠道', trigger: 'change' }],
          subchannel: [{ required: true, message: '请选择渠道', trigger: 'change' }],
          date: [{ required: true, message: '请选择游玩日期', trigger: 'change' }],
          num: [{ required: true, message: '请选择正确的票数', trigger: 'change' }],
          person: [{ required: true, message: '请选择正确的人数', trigger: 'change' }]
          // ratecode: [{ required: true, message: '请选择价格代码', trigger: 'change' }]
        },
        subchannels:[],payments:[],
        ticketgroups:[],tickets:[],ratecodes:[],
        blocks:[],
        showProfileDialog:false,showLogDialog:false,showMsgDialog:false,showInterfaceDialog:false,
        buttonLoading:false,showProfileInfoDialog:false,profileInfoData:[],showInterfaceLogDialog:false,
        showQrCodeDialog:false
      }
    },
    computed:{
      showTitle(){ return this.form.sqlid!=null&&this.form.sqlid>0?'编辑票务订单  订单号: '+this.form.bookingid+
              (this.form.colno!==''?' 综合订单号: '+this.form.colno:'')+
              (this.form.channelno!==''?' OTA订单号: '+this.form.channelno:'')+
              (this.form.interfaceid!==''?'  线下订单号: '+this.form.interfaceid:''):'新建票务订单'},
      showStatus(){ return this.form.sqlid!=null&&this.form.sqlid>0?getTicketStatusAndPay(this.form.status, this.form.pstatus):''},
      // createinfo(){ return this.form.createdate==null||this.form.createdate=='1900-01-01'?'':this.form.createdate+'  '+this.form.createtime+'  '+this.form.creator},
      // modifyinfo(){ return this.form.modifydate==null||this.form.modifydate=='1900-01-01'?'':this.form.modifydate+'  '+this.form.modifytime+'  '+this.form.modify},
      // dateIsExpire() { return dateIsExpire(this.form.date) }
      createDateTime:{
        get:function(){
          return this.form.createdate==null||this.form.createdate=='1900-01-01'?this.form.creator
                  :this.form.createdate+' '+this.form.createtime+' '+this.form.creator
        }
      },
      modifyDateTime:{
        get:function(){
          return this.form.modifydate==null||this.form.modifydate=='1900-01-01'?'':this.form.modifydate+' '+this.form.modifytime+' '+this.form.modify
        }
      }
    },
    watch:{
      visible: function(val){
        if(val){
          this.initDialog();
        }
      }
    },
    mounted: function(){
      this.$refs.nameinput.focus();
      select_data({"keyname":"subchannel,payment,ticket,ticketgroup,ratecode,block"}).then(response => {
          this.subchannels = select_res(response.data,"subchannel");
          this.payments = select_res(response.data,"payment");
          this.tickets = select_res(response.data,"ticket");
          this.ticketgroups = select_res(response.data,"ticketgroup");
          this.ratecodes = select_res(response.data,"ratecode");
          this.blocks = select_res(response.data,"block");
      });
      this.initDialog();
    },
    methods: {
      idTypeFormatter(row){
        if(row.idtype==='01'){
          return '身份证';
        }else if(row.idtype==='02'){
          return '护照';
        }else if(row.idtype==='03'){
          return '港澳通行证';
        }else if(row.idtype==='04'){
          return '台胞证';
        }else if(row.idtype==='07'){
          return '户口本';
        }else if(row.idtype==='10'){
          return '军官证';
        }else if(row.idtype==='15'){
          return '外国人居留证';
        }else if(row.idtype==='16'){
          return '学生证';
        }
      },
      initDialog(){
        load_ticketrs({"sqlid":this.ticketid,"colno":this.colno,"history":this.history}).then(response => {
          this.form = response.data;
          this.openProfileInfo();
        }).catch(() => {
          this.$emit('update:visible',false);
        })
      },
      changeticketgroup(val){
        select_data({"keyname":"ticket","params":[{'key':'ticket','value':val}]}).then(response => {
          this.tickets = select_res(response.data,"ticket");
          this.form.ticket = '';
        })
      },
      // 不校验姓名档案了 纯票订单可以没有档案
      // checkNameExist(){
      //   if(this.form.profileid===''&&this.form.name.length>0){
      //     name_exist({"name":this.form.name}).then(response => {
      //       if(response.data){
      //         this.showProfileDialog = true;
      //       }
      //     })
      //   }
      // },
      selectProfile(selectValue){
        this.showProfileDialog = false;
        this.form.profileid = selectValue.profileid;
        this.form.name = selectValue.name;
        this.form.telephone = selectValue.mobile;
        this.form.idnumber = selectValue.idnum;
      },
      changeNum(val){
        this.form.totalprice = (val*this.form.price).toFixed(2);
      },
      changeSinglePrice(val){
        if(!isNaN(val)){
          this.form.totalprice = (val*this.form.num).toFixed(2);
        }else{
          this.form.totalprice = 0;
        }
      },
      getPrice(){
        if(!this.form.fix){
          if(this.form.ticket!==''&&this.form.ratecode!==''&&this.form.date!==''){
            calc_price({'from':this.form.date,'to':this.form.date,'product':this.form.ticket,
              'ratecode':this.form.ratecode,'type':'T','channelcode':this.form.channel,'channelid':this.form.subchannel}).then(response => {
              this.form.price = response.data;
              this.form.totalprice = this.form.num*this.form.price;
            }).catch(() => {
              this.form.price = this.form.totalprice = 0;
            })
          }else{
            this.form.price = this.form.totalprice = 0;
          }
        }
      },
      handleSave(){
        this.$refs['form'].validate((valid) => {
          if (valid) {
            this.saveProfileInfo();
            this.buttonLoading = true;
            save_ticketrs(this.form).then(response => {
              this.$msgbox({ title: '消息', message: '保存成功! 订单号:'+response.data.entity.bookingid+' '+response.data.message, confirmButtonText: '关闭'}).catch(() => {});
              this.$emit('refresh');
              if(!this.gotocol){//如果可以跳转到综合预定,保存后界面不关
                this.$emit('update:visible', false);
              }else{
                this.form = response.data.entity;
              }
            }).finally(()=>{
              this.buttonLoading = false;
            })
          } else {
            return false;
          }
        });
      },
      handleCancel(){
        this.$confirm('确定取消?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
          this.buttonLoading = true;
          cancel_ticketrs({"sqlid":this.form.sqlid}).then(response => {
            if(response.data.flag){
              this.$message({ message: '取消成功!', type: 'success'});
            }else{
              this.$msgbox({ title: '消息', message: response.data.message, confirmButtonText: '关闭'}).catch(() => {});
            }
            this.$emit('refresh');
            this.$emit('update:visible',false);
          }).finally(()=>{
            this.buttonLoading = false;
          })
        }).catch(() => {});
      },
      handlePay(){
        this.$confirm('确定已支付?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
          this.buttonLoading = true;
          pay_ticketrs({"sqlid":this.form.sqlid}).then(response => {
            this.$emit('refresh');
            this.form = response.data;
            this.$message({ message: '支付成功!', type: 'success'});
          }).finally(()=>{
            this.buttonLoading = false;
          })
        }).catch(() => {});
      },
      gotoCol(){
        this.$emit('update:rscolno',this.form.colno);
        this.$emit('gotoCol');
      },
      openProfileInfo(){
        this.profileInfoData = [];
        // this.$refs.profileInfoName.focus();
        if (this.form.profileinfo!=null && this.form.profileinfo.length>0) {
          let profiles = this.form.profileinfo.split(";");
          profiles.forEach(profile => {
            let subProfile = profile.split("||");
            if(subProfile[0]!==''){
              this.profileInfoData.push({"name": subProfile[0],
                "mobile": subProfile.length>1?subProfile[1]:"",
                "idnum": subProfile.length>2?subProfile[2]:"",
                "idtype": subProfile.length>3?subProfile[3]:""});
            }
          });
        }
      },
      addProfileInfo() {
        let name = this.profileInfoForm.name;
        let mobile = this.profileInfoForm.mobile;
        let idnum = this.profileInfoForm.idnum;
        let idtype = this.profileInfoForm.idtype;
        if (name === '') {
          return false;
        }
        this.profileInfoData.splice(0, 0, {"name": name, "mobile": mobile, "idnum": idnum, "idtype": idtype});
        this.profileInfoForm.name = '';
        this.profileInfoForm.mobile = '';
        this.profileInfoForm.idnum = '';
        this.profileInfoForm.idtype = '01';
        this.$refs.profileInfoName.focus();
      },
      deleteProfileInfo(index) {
        this.profileInfoData.splice(index, 1);
      },
      saveProfileInfo() {
        let profileInfoStr = '';
        for(let i=0; i<this.profileInfoData.length; i++){
          profileInfoStr = profileInfoStr + this.profileInfoData[i].name + "||" +
                  this.profileInfoData[i].mobile + "||" +
                  this.profileInfoData[i].idnum + "||" +
                  this.profileInfoData[i].idtype +";";
        }
        this.form.profileinfo = profileInfoStr;
        // this.showProfileInfoDialog = false;
      },
      openQrCodeUrl(url){
        if(url!==''){
          if(url.indexOf("https")===0){
            this.showQrCodeDialog = true;
          }else{
            window.open(url,"_blank","width=400, height=400");
          }
        }
      },
      handleCommand(command) {
        if(command==='U'){
          this.showLogDialog = true;
        }else{
          this.showInterfaceLogDialog = true;
        }
      },
      handleResend(){
        resend_ticket({"sqlid":this.form.sqlid}).then(response => {
          this.form = response.data;
          this.$message({ message: '重发成功!', type: 'success'});
        }).finally(()=>{
          this.buttonLoading = false;
        })
      }
    }
}
</script>

<style>
  .pricelabel{
    color:#c11a25;
    font-size: 22px;
    font-weight: bolder;
  }
</style>
