import Vue from 'vue'

import Cookies from 'js-cookie'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'

import '@/styles/index.scss' // 全局 css

import App from './App.vue'
import router from './router'
import store from './store'

import './icons' // icon 图标
import './permission' // permission control
import './utils/error-log' // error log

import * as filters from './filters' // global filters

import './quickkey';//引入快捷键

import './utils/dragdialog';//引入拖拽窗口

Vue.use(ElementUI, {
  size: Cookies.get('size') || 'mini' // set element-ui default size
})

// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.config.productionTip = false

function createVue() {
  return new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app');
}

if (process.env.NODE_ENV == 'development') {
  axios.get('/config.json').then(res=>{
    // alert('外部地址:'+res.data.apiurl);
    createVue();
    Vue.prototype.BASE_API=process.env.VUE_APP_BASE_API;
    Vue.prototype.$bgimg = res.data.bgimg;
    Vue.prototype.$section = res.data.section;
  })
} else if (process.env.NODE_ENV == 'production') {
  // 放在public文件夹下的静态文件需要通过绝对路径去获取
  axios.get('/config.json').then(res=>{
    // 将获取到的配置文件作为全局变量保存
    // alert('外部地址:'+res.data.apiurl);
    // 成功获取到配置后再去创建vue实例
    // Vue.prototype.BASE_API = res.data.apiurl;
    createVue();
    Vue.prototype.BASE_API = res.data.apiurl;
    Vue.prototype.$bgimg = res.data.bgimg;
    Vue.prototype.$section = res.data.section;
  })
}



