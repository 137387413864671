import request from '@/utils/request'

export function pay_list(data) {
    return request({
        url: '/api/pay/pay_list',
        method: 'post',
        data
    })
}

export function load_pay(data) {
    return request({
        url: '/api/pay/load_pay',
        method: 'post',
        data
    })
}

export function save_pay(data) {
    return request({
        url: '/api/pay/save_pay',
        method: 'post',
        data
    })
}

export function refund_value(data) {
    return request({
        url: '/api/pay/refund_value',
        method: 'post',
        data
    })
}

export function refund_pay(data) {
    return request({
        url: '/api/pay/refund_pay',
        method: 'post',
        data
    })
}

export function cancel_refund(data) {
    return request({
        url: '/api/pay/cancel_refund',
        method: 'post',
        data
    })
}

export function fix_doublepost(data) {
    return request({
        url: '/api/pay/fix_doublepost',
        method: 'post',
        data
    })
}