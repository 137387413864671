import request from "@/utils/request";

export function userlog_list(data) {
    return request({
        url: '/api/userlog/userlog_list',
        method: 'post',
        data
    })
}

export function gridlog_list(data) {
    return request({
        url: '/api/userlog/gridlog_list',
        method: 'post',
        data
    })
}

export function ratelog_list(data) {
    return request({
        url: '/api/userlog/ratelog_list',
        method: 'post',
        data
    })
}