import request from '@/utils/request'

export function cateringrs_list(data) {
    return request({
        url: '/api/catering/cateringrs_list',
        method: 'post',
        data
    })
}

export function load_cateringrs(data) {
    return request({
        url: '/api/catering/load_cateringrs',
        method: 'post',
        data
    })
}

export function save_cateringrs(data) {
    return request({
        url: '/api/catering/save_cateringrs',
        method: 'post',
        data
    })
}

export function cancel_cateringrs(data) {
    return request({
        url: '/api/catering/cancel_cateringrs',
        method: 'post',
        data
    })
}

export function query_tablestatus(data) {
    return request({
        url: '/api/catering/query_tablestatus',
        method: 'post',
        data
    })
}