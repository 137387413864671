<template>
  <div class="quickkey-class">
    <!-- 价格详情页面 -->
    <zj-ratequery :type="'R'" :arrdate="orderform.arrdate" :deptdate="orderform.deptdate"
                  :person="orderform.person" :show.sync="showResource" :calcform="orderform" @selectRate="handleSelectResource"/>
    <!-- 可以跳转到综合预定的客房订单页面 -->
    <RoomrsDetailCol :params="resourceData" :visible.sync="showRoomrs"/>
    <!-- 酒店指标 -->
    <el-dialog v-dialogDrag title="酒店指标" :visible.sync="showHotelPoint" width="85%" top="4vh" :close-on-click-modal="false">
      <HotelPoint :visible="showHotelPoint"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <!-- 可卖房 -->
    <el-dialog v-dialogDrag title="可卖房" :visible.sync="showAvail" width="90%" top="4vh" :close-on-click-modal="false">
      <AvailRoom/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <!-- 综合预定 -->
    <el-dialog v-dialogDrag title="综合预定" :visible.sync="showCol" width="90%" top="4vh" :close-on-click-modal="false">
      <Col/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <!-- 综合预定 -->
    <el-dialog v-dialogDrag title="锁房" :visible.sync="showBlockList" width="80%" top="4vh" :close-on-click-modal="false">
      <BlockList/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
  </div>
</template>

<script>
    import {formatDate, reckonDay} from '@/utils/calcdate';
    import ZjRatequery from "@/views/order/common/ZjRatequery";
    import RoomrsDetailCol from '@/views/order/room/roomrsdetailcol';
    import HotelPoint from '@/views/summary/hotelpoint/hotelpoint';
    import Col from '@/views/order/col/col';
    import BlockList from '@/views/channel/block/blocklist';
    import AvailRoom from '@/views/summary/availrooms/availrooms';
    export default {
        name: "Quickkey",
        components: {ZjRatequery,RoomrsDetailCol,HotelPoint,Col,BlockList,AvailRoom},
        data() {
            return {
                orderform:{
                    arrdate:formatDate(new Date()),
                    deptdate:formatDate(reckonDay(new Date(),1)),
                    person:1
                },
                showResource:false,showHotelPoint:false,showCol:false,showBlockList:false,showAvail:false,
                showRoomrs: false,
                resourceData: {
                  arrdate: null,
                  block: "",
                  deptdate: null,
                  // price: 0,
                  ratecode: "",
                  product:''
                }
            }
        },
        mounted() {
            //ctrl+r 客房资源
            this.$quickkey.bind('ctrl+r',this.openResourceDialog);//绑定快捷键
            //ctrl+b 酒店指标
            this.$quickkey.bind('ctrl+p',this.openHotelPointDialog);//绑定快捷键
            //ctrl+d 可卖房
            this.$quickkey.bind('ctrl+d',this.openAvailDialog);//绑定快捷键
            //ctrl+z 综合预定
            this.$quickkey.bind('ctrl+z',this.openColDialog);//绑定快捷键
            //ctrl+b 综合预定
            this.$quickkey.bind('ctrl+b',this.openBlockListDialog);//绑定快捷键
        },
        beforeDestroy(){
            this.$quickkey.unbind('ctrl+r');//解绑快捷键
            this.$quickkey.unbind('ctrl+p');//解绑快捷键
            this.$quickkey.unbind('ctrl+d');//解绑快捷键
            this.$quickkey.unbind('ctrl+z');//解绑快捷键
            this.$quickkey.unbind('ctrl+b');//解绑快捷键
        },
        methods:{
            openResourceDialog(){
                this.showResource = true;
            },
            handleSelectResource(data){
                this.showRoomrs = true;
                this.resourceData = data;
            },
            openHotelPointDialog(){
              this.showHotelPoint = true;
            },
            openAvailDialog(){
              this.showAvail = true;
            },
            openColDialog(){
              this.showCol = true;
            },
            openBlockListDialog(){
              this.showBlockList = true;
            }
        }
    }
</script>

<style scoped>

</style>