/** When your routing table is too long, you can split it into small modules**/

import Layout from "@/layout";
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true            是否在左侧菜单栏可见.一般404页面啊.或者一些权限的才隐藏       if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true     一般只有首页才会设置成总是可见          if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect      是否允许调回这个面包屑导航     if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'            必填项.跳转路径必须的  the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']   哪些角色可以显示本菜单  control the page roles (you can set multiple roles)
    title: 'title'        显示在面包屑跟左侧菜单栏的名字       the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'          左侧图标 id   the icon show in the sidebar
    noCache: true          是否缓存页面内容       if set true, the page will no be cached(default is false)
    affix: true         是否固定页签         if set true, the tag will affix in the tags-view
    breadcrumb: false        是否显示在面包屑导航    if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  高亮的菜单路径  if set path, the sidebar will highlight the path you set
  }
 */
const confRouter = {
  path: "/conf",
  component: Layout,
  redirect: "noRedirect", //点击这个面包屑时.定位回哪个路径
  name: "conf",
  meta: {
    title: "基础数据设置",
    right:['5000']
  },
  children: [
    {
      path: "product_setup",
      // component: ()=>import('@/views/conf/rate/index'),
      name: "ProductSetup",
      meta: { title: "产品设置",right:['5017'] },
      children: [
        {
          path: "hotel",
          component: () => import("@/views/conf/res/hotel/hotel-list"),
          name: "HotelList",
          meta: { title: "酒店设置" ,right:['5002']}
        },
        {
          path: "room",
          component: () => import("@/views/conf/res/roomtype/rmtype-list"),
          name: "RmtypeList",
          meta: { title: "房型设置" ,right:['5001']}
        },
        {
          path: "ticket",
          component: () => import("@/views/conf/res/ticket/ticketsetup"),
          name: "TicketSetup",
          meta: { title: "票务设置",right:['5003'] }
        },
        {
          path: "catering",
          component: () => import("@/views/conf/res/catering/cateringsetup"),
          name: "CateringSetup",
          meta: { title: "餐饮设置",right:['5011']}
        },
        {
          path: "meeting",
          component: () => import("@/views/conf/res/meeting/meetingsetup"),
          name: "MeetingSetup",
          meta: { title: "会议设置",right:['5012'] }
        },
        {
          path: "guide",
          component: () => import("@/views/conf/res/guide/guidesetup"),
          name: "GuideSetup",
          meta: { title: "导游设置",right:['5019'] }
        },
        {
          path: "spa",
          component: () => import("@/views/conf/res/spa/spasetup"),
          name: "SpaSetup",
          meta: { title: "SPA设置",right:['5020'] }
        },
        {
          path: "boat",
          component: () => import("@/views/conf/res/boat/boatsetup"),
          name: "BoatSetup",
          meta: { title: "车船设置",right:['5018'] }
        },
        {
          path: "mall",
          component: () => import("@/views/conf/res/mall/mallsetup"),
          name: "MallSetup",
          meta: { title: "商超设置",right:['5022'] }
        },
        {
          path: "fixcharge",
          component: () => import("@/views/conf/res/fixcharge/fixchargesetup"),
          name: "FixchargeSetup",
          meta: { title: "固定项设置",right:['5021'] }
        }
      ]
    },
    {
      path: "kit",
      component: () => import("@/views/conf/res/kit/productkit-list"),
      name: "ProductkitList",
      meta: { title: "套餐设置" ,right:['5005']}
    },
    {
      path: 'paydep',
      component: () => import('@/views/prepay/paydep'),
      name: 'PayDep',
      meta: {title: ' 付款账项',right:['7002']}
    },
    {
      path: "rate",
      component: ()=>import('@/views/conf/rate/index'),
      name: "RateNode",
      meta: { title: "价格管理",right:['5006'] },
      children: [
        {
          path: 'cat',
          component: () => import('@/views/conf/rate/ratecode/ratecode-list'),
          name: 'RatecodeList',
          meta: { title: '价格大组',right:['5007'] }
        },
        {
          path: 'code',
          component: () => import('@/views/conf/rate/subratecode/subratecode-list'),
          name: 'SubratecodeList',
          meta: { title: '价格代码',right:['5008'] }
        },
        {
          path: 'det',
          component: () => import('@/views/conf/rate/ratedet/ratedet-list'),
          name: 'RatedetList',
          meta: { title: '价格详情' ,right:['5009']}
        },
        {
          path: 'policy',
          component: () => import('@/views/conf/rate/ratepolicy/ratepolicy-list'),
          name: 'RatepolicyList',
          meta: { title: '价格策略',right:['5010'] }
        }
      ]
    },
    {
      path: "sundry",
      component: ()=>import('@/views/conf/sundry/index'),
      name: "Sundry",
      meta: { title: "杂项设置",right:['5013'] },
      children: [
        {
          path: 'profile_data',
          component: () => import('@/views/profile/profiledata'),
          name: 'ProfileData',
          meta: { title: '下拉统计项',right:['2002']}
        },
        {
          path: "template",
          component: () => import("@/views/conf/sundry/msg/templatesetup"),
          name: "templateSetup",
          meta: { title: "模板设置" ,right:['5004']}
        },
        {
          path: "convertor",
          component: () => import("@/views/conf/sundry/convertor/convertorlist"),
          name: "ConvertorList",
          meta: { title: "转换表" ,right:['5015']}
        },
        {
          path: "monitor",
          component: () => import("@/views/conf/sundry/monitor/monitorlist"),
          name: "monitorList",
          meta: { title: "监控列表" ,right:['5023']}
        },
        {
          path: "notify",
          component: () => import("@/views/conf/sundry/notify/notifysetup"),
          name: "notifySetup",
          meta: { title: "通知机器人设置" ,right:['5014']}
        },
        {
          path: "paramswitch",
          component: () => import("@/views/conf/sundry/switch/paramswitch"),
          name: "paramSwitch",
          meta: { title: "参数开关" ,right:['5016']}
        }
      ]
    }
  ]
};

export default confRouter;
