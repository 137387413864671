<template>
  <div class="cti-cater-div">
    <el-dialog v-dialogDrag title="餐饮资源" :visible.sync="visible" width="80%" :close-on-click-modal="false" top="1vh"
               :close-on-press-escape="false" append-to-body @close="$emit('update:show', false)" @opened="initDialog">
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="日期">
          <el-date-picker v-model="searchForm.date" align="right" type="date" placeholder="选择日期"
                          value-format="yyyy-MM-dd" :picker-options="pickerOptions" :clearable="false"/>
        </el-form-item>
        <el-form-item label="餐厅">
          <el-select id="restaurantSelect" v-model="searchForm.restaurant" placeholder="请选择餐厅" filterable>
            <el-option v-for="item in restaurants" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
              <span style="float: left">{{ item.code }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="餐段">
          <el-select v-model="searchForm.time" placeholder="请选择餐段" filterable>
            <el-option v-for="item in times" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
              <span style="float: left">{{ item.code }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型">
          <el-select v-model="searchForm.type" placeholder="请选择类型" clearable>
            <el-option label="餐桌" value="C"/>
            <el-option label="包厢" value="B"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="handleQuery">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table v-loading="loading" :data="tableData" style="width: 100%" max-height="450" border @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"/>
        <el-table-column prop="code" label="桌号/包厢" min-width="80"/>
        <el-table-column prop="desc" label="描述" min-width="250"/>
        <el-table-column prop="type" label="类型" min-width="70" :formatter="typeFormatter"/>
        <el-table-column prop="persons" label="容纳人数" min-width="80"/>
        <el-table-column prop="status" label="状态" min-width="80" :formatter="statusFormatter"/>
      </el-table>
      <!-- PMS返回的数据没有分页
      <el-pagination :current-page="searchForm.currentpage" :page-size="searchForm.pagesize"
                     layout="total, prev, pager, next, jumper" :total="searchForm.total" style="margin-top: 10px;float: right;" background
                     @current-change="handleCurrentChange"/>
      -->
      <el-form v-if="isCti" :inline="true" :model="saveForm" class="save-form" style="margin-top: 20px">
        <el-form-item label="价格">
          <el-input-number ref="priceinput" v-model="saveForm.price" :precision="2" :step="10" :min="0.00" controls-position="right" />
        </el-form-item>
        <el-form-item>
          <el-select v-model="saveForm.type" @change="changeType">
            <el-option label="/桌" value="T"/>
            <el-option label="/人" value="P"/>
          </el-select>
        </el-form-item>
        <el-form-item label="数量">
          <el-input-number v-model="saveForm.num" :min="1" controls-position="right" />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-edit-outline" @click="createRes">生成预定</el-button>
          <el-button @click="visible=false">退出</el-button>
        </el-form-item>
      </el-form>
      <div v-else slot="footer" class="dialog-footer">
        <el-button type="primary" icon="el-icon-edit-outline" @click="selectValue">选择</el-button>
        <el-button @click="visible=false">退出</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import {query_tablestatus} from '@/api/order/catering';
  import {formatDate} from '@/utils/calcdate';
  import {select_data} from '@/api/common/custom-data';
  import {select_res} from "@/utils/select_data_res";
  export default {
    name: "CtiCater",
    props:{
      arrdate:{type:String,default:formatDate(new Date())},
      restaurant:{type:String,default:''},
      time:{type:String,default:''},
      show: {type: Boolean, default: false},
      cti: {type: Boolean, default: false}
    },
    data() {
      return {
        visible:this.show,
        searchForm:{
          date:this.arrdate, restaurant:this.restaurant, time:this.time, type:''
        },
        saveForm:{
          type:'T',price:0,num:1
        },
        pickerOptions: {
          shortcuts: [{ text: '今天', onClick(picker) { picker.$emit('pick', new Date()); } }]
        },
        restaurants:[],times:[],loading:false,tableData:[],multipleSelection:[],isCti:this.cti
      }
    },
    watch:{
      show(){
        this.visible=this.show;
      },
      arrdate(){
        this.searchForm.date=formatDate(this.arrdate);
      },
      restaurant(){
        this.searchForm.restaurant = this.restaurant;
      },
      time(){
        this.searchForm.time = this.time;
      },
      cti(){
        this.isCti = this.cti;
      }
    },
    mounted: function(){
      select_data({"keyname":"restaurant,restaurant_time"}).then(response => {
        this.restaurants = select_res(response.data,"restaurant");
        this.times = select_res(response.data,"restaurant_time");
      });
    },
    methods:{
      initDialog(){
        if(this.searchForm.restaurant.length>0 && this.searchForm.time.length>0){
          this.loadTable();
        }
      },
      typeFormatter(row){
        if(row.type=='B'){
          return '包厢';
        }
        return '餐桌';
      },
      statusFormatter(row){
        if(row.status=='0'){
          return '空闲';
        }
        return '占用';
      },
      loadTable(){
        this.loading = true;
        query_tablestatus(this.searchForm).then(response => {
          this.tableData = response.data.records;
        }).finally(() => { this.loading = false;})
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
        if(this.saveForm.type=='T'){
          this.saveForm.num = this.multipleSelection.length<=0?1:this.multipleSelection.length//按选择的餐桌数来算
        }
      },
      changeType(val){
        if(val=='T'){
          this.saveForm.num = this.multipleSelection.length<=0?1:this.multipleSelection.length//按选择的餐桌数来算
        }else{
          this.saveForm.num = 1;
        }
      },
      handleQuery(){
        if(this.searchForm.restaurant.length<=0){
          this.$message({ message: '请选择餐厅!', type: 'warning'});
        }else if(this.searchForm.time.length<=0){
          this.$message({ message: '请选择餐段!', type: 'warning'});
        }else{
          this.loadTable();
        }
      },
      createRes(){
        //TODO 如果价格为0.不给通过添加?
        if(this.saveForm.price<=0){
          this.$message({ message: '请输入预订价格', type: 'warning'});
          this.$refs.priceinput.focus();
          return;
        }
        let products='';
        this.multipleSelection.forEach(selectValue => {
          products = products.length<=0?selectValue.code:products+","+selectValue.code;
        });
        let productsdesc = products;
        if(products.length<=0){//如果没有选中数据 就给个默认值
            let restaurantObj = this.restaurants.find((item)=>{
                return item.code === this.searchForm.restaurant;
            });
            productsdesc = restaurantObj!=null?restaurantObj.desc:'';
        }
        let items=[];
        let cateritem={
          ratecode: '',
          // product: products,
          tableno: products, //这里填桌号,包厢号 product这里填餐标
          productdesc: productsdesc,
          arrdate: this.searchForm.date,
          deptdate: this.searchForm.date, //如果是客房产品.根据房晚数计算离店日期.
          producttype: 'C',
          num: this.saveForm.num,
          price: this.saveForm.price,
          period: this.searchForm.time,
          restaurant:this.searchForm.restaurant,
          catertype:this.saveForm.type,
          lfix:1 // 1为固定价格
        };
        items.push(cateritem);

        if(items.length>0){
          this.$emit("selected", items);
          this.$emit('update:show',false);
        }
      },
      selectValue(){
        let tablebox='';
        this.multipleSelection.forEach(selectValue => {
          tablebox = tablebox.length<=0?selectValue.code:tablebox+","+selectValue.code;
        });
        let items = {
          tablebox: tablebox,
          restaurant: this.searchForm.restaurant,
          time: this.searchForm.time,
          date: this.searchForm.date
        };
        this.$emit("selected", items);
        this.$emit('update:show',false);
      }
    }
  }
</script>

<style scoped>

</style>
