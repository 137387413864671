<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style>
body {
  margin-top: 0;
}

.el-dialog__body {
  padding: 0 20px 30px !important;
}

.el-dialog__header {
  padding-bottom: 20px !important;
}
</style>
