import request from "@/utils/request";

export function select_data(data) {
    return request({
        url: '/api/custom/select_data',
        method: 'post',
        data
    })
}

export function calc_price(data) {
    return request({
        url: '/api/custom/calc_price',
        method: 'post',
        data
    })
}

export function calc_price_detail(data) {
    return request({
        url: '/api/custom/calc_price_detail',
        method: 'post',
        data
    })
}

export function attr_default() {
    return request({
        url: '/api/custom/attr_default',
        method: 'post'
    })
}
