/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true            是否在左侧菜单栏可见.一般404页面啊.或者一些权限的才隐藏       if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true     一般只有首页才会设置成总是可见          if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect      是否允许调回这个面包屑导航     if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'            必填项.跳转路径必须的  the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']   哪些角色可以显示本菜单  control the page roles (you can set multiple roles)
    title: 'title'        显示在面包屑跟左侧菜单栏的名字       the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'          左侧图标 id   the icon show in the sidebar
    noCache: true          是否缓存页面内容       if set true, the page will no be cached(default is false)
    affix: true         是否固定页签         if set true, the tag will affix in the tags-view
    breadcrumb: false        是否显示在面包屑导航    if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  高亮的菜单路径  if set path, the sidebar will highlight the path you set
  }
 */
const confSysRouter = {
  path: '/confsys',
  component: Layout,
  redirect: 'noRedirect',  //点击这个面包屑时.定位回哪个路径
  name: 'confsys',
  meta: {
    title: '系统设置',
    right:['6000']
    // icon: 'chart'
  },
  children: [ // 二级菜单
    {
      path: 'interfaceconf',
      component: () => import('@/views/conf/sys/interfaceconf'),
      name: 'IntefaceConf',
      meta: { title: '接口配置',right:['6001'] }
    },
    // {
    //   path: 'interfacedata',
    //   component: () => import('@/views/conf/sys/interfacedata'),
    //   name: 'IntefaceData',
    //   meta: { title: '接口数据' }
    // },//移到prepay节点
    {
      path: 'userlog',
      component: () => import('@/views/conf/sys/userlog'),
      name: 'UserLog',
      meta: { title: '用户日志查询',right:['6005'] }
    },
    {
      path: 'interfacelog',
      component: () => import('@/views/conf/sys/interfacelog'),
      name: 'InterfaceLog',
      meta: { title: '接口日志查询',right:['6006'] }
    },
    {
      path: 'msglog',
      component: () => import('@/views/conf/sys/msglog'),
      name: 'MsgLog',
      meta: { title: '短信发送日志',right:['6009'] }
    },
    {
      path: 'nightaudit',
      component: () => import('@/views/conf/sys/nightaudit'),
      name: 'NightAudit',
      meta: { title: '夜审管理',right:['6007'] }
    },
    {
      path: 'datafix',
      component: () => import('@/views/conf/sys/datafix'),
      name: 'DataFix',
      meta: { title: '数据修复',right:['6008'] }
    },
    {
      path:'userconfig',
      component: () => import('@/views/conf/users/index'),
      name: 'UserNode',
      meta: { title: '用户管理',right:['6002'] },
      // redirect:'/confsys/userconfig/users', //节点默认显示路径:
      children:[
        {
          path: 'users',
          component: () => import('@/views/confadv/users/user-list'),
          name: 'UserList',
          meta: { title: '用户',right:['6003'] }
        },
        // {
        //   path: 'rights',
        //   component: () => import('@/views/conf/users/rights/'),
        //   name: 'Configrights',
        //   meta: { title: '权限' }
        // },
        {
          path: 'roles',
          component: () => import('@/views/confadv/roles/role-list'),
          name: 'RoleList',
          meta: { title: '权限角色管理' ,right:['6004']}
        }
      ]
    }
  ]
}

export default confSysRouter
