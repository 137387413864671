<template>
  <div class="colguide">
    <div style="margin-bottom: 10px">
      <el-button type="primary" icon="el-icon-edit-outline" :disabled="history" @click="openGuide(0)">新增</el-button>
      <el-button icon="el-icon-refresh" @click="refresh(true)">刷新</el-button>
    </div>
    <el-table v-loading="loading" :data="tableData" style="width: 100%" :summary-method="tableSummary" :show-summary="summary!=''"
              :row-class-name="tableRowClassName" max-height="450" border @sort-change="handleSortChange">
      <el-table-column prop="status" label="状态" min-width="80" sortable="custom" :formatter="statusFormatter"/>
      <el-table-column prop="bookingid" label="订单号" min-width="150"/>
      <el-table-column prop="name" label="姓名" min-width="120" sortable="custom"/>
      <el-table-column prop="telephone" label="电话" min-width="120"/>
      <el-table-column prop="arrival" label="接团日期" min-width="120" :formatter="arrivalFormatter" sortable="custom"/>
      <el-table-column prop="departure" label="送团日期" min-width="120" :formatter="departureFormatter" sortable="custom"/>
      <el-table-column prop="guides" label="导游" min-width="140" :show-overflow-tooltip="true"/>
      <el-table-column prop="totalprice" label="价格" min-width="70"/>
      <el-table-column prop="num" label="导游数" min-width="60"/>
      <el-table-column prop="person" label="人数" min-width="50"/>
      <el-table-column prop="subchannel" label="渠道" min-width="100" sortable="custom"/>
      <el-table-column prop="colno" label="综合订单号" min-width="150"/>
      <el-table-column fixed="right" prop="operation" label="操作" min-width="160">
        <template slot-scope="operatescope">
          <el-button size="mini" type="primary" @click="openGuide(operatescope.row.sqlid)">编辑</el-button>
          <el-button size="mini" type="danger" :disabled="(operatescope.row.status!='N'&&operatescope.row.status!='P')||form.history"
                     :loading="operatescope.row.cancelLoading" @click="handleCancel(operatescope.row)">取消</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :current-page="form.pages.currentpage" :page-size="form.pages.pagesize"
                   layout="total, prev, pager, next, jumper" :total="form.pages.total" style="margin-top: 10px;float: right;" background
                   @current-change="handleCurrentChange"/>
    <!-- Dialog -->
    <el-dialog v-dialogDrag :visible.sync="showGuideDetailDialog" :close-on-click-modal="false" width="80%" top="7vh" append-to-body>
      <Guidedetail ref="guidedetail" :guideid="guideid" :history="form.history" :colno="form.bookingid" :visible.sync="showGuideDetailDialog" @refresh="refresh(false)"/>
    </el-dialog>
  </div>
</template>

<script>
    import {guide_list,cancel_guides} from '@/api/order/guide';
    import {getTicketStatus} from '@/utils/status-format';
    import Guidedetail from '@/views/order/guide/guidedetail';

    export default {
        name: "ColGuide",
        components: {
            Guidedetail
        },
        props: {
            colno:{ type: String,default: ''},
            // profileid:{ type: String,default: ''},
            custom:{ type: String,default: ''},//不为空可以自定义搜索框
            summary:{ type: String,default: ''},//不为空可以进行统计
            // cancel:{ type: Boolean,default: false},
            history:{ type: Boolean,default: false}
        },
        data() {
            return {
                form:{
                    // usedate:formatDate(new Date()), name:'',  status:['N'],
                    // subchannel:'',profileid:this.profileid,
                    bookingid:this.colno,history:this.history,
                    pages:{total:0, currentpage:1, pagesize:20, sortname:'arrival', sortorder:'ascending'}
                },
                // pickerOptions: {
                //     shortcuts: [{ text: '今天', onClick(picker) { picker.$emit('pick', new Date()); } }]
                // },
                // subchannels:[],showAdvance:false,
                tableData: [], loading:false, showGuideDetailDialog:false, guideid:0
            }
        },
        watch: {
            colno: function(val){ this.form.bookingid = val; },
            // profileid: function(val){
            //     this.form.profileid = val;
            //     if(val!=null && val.length>0){
            //         this.form.status = ['N','F'];
            //         this.form.usedate = '';
            //     }
            // },
            // cancel: function(val){
            //     if(this.profileid.length>0){//是否包含取消这个只有在档案里用到
            //         if(val){
            //             this.form.status = [];
            //         }else{
            //             this.form.status = ['N','F'];
            //         }
            //     }
            // },
            history: function(val){ this.form.history = val; }
        },
        mounted: function(){
            // if(this.custom==''){
            //     this.$refs.bookingidinput.focus();
            //     select_data({"keyname":"subchannel"}).then(response => {
            //         this.subchannels = select_res(response.data,"subchannel");
            //     });
            // }
            this.loadTable();
        },
        methods: {
            statusFormatter(row){
                return getTicketStatus(row.status);
            },
            arrivalFormatter(row){
                if(row.arrivaltime!=null && row.arrivaltime.length>0){
                    return row.arrival+' '+row.arrivaltime;
                }
                return row.arrival;
            },
            departureFormatter(row){
                if(row.departuretime!=null && row.departuretime.length>0){
                    return row.departure+' '+row.departuretime;
                }
                return row.departure;
            },
            tableSummary(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    if (column.property=='num'||column.property=='person'||column.property=='totalprice') {
                        const values = data.map(function(item){
                            if(item['status']!='C'){
                                return Number(item[column.property]);
                            }else{
                                return 0;
                            }
                        });
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        if (column.property=='totalprice') {//总价列合计保留两位小数
                            sums[index] = sums[index].toFixed(2);
                        }
                    }
                });
                return sums;
            },
            tableRowClassName({row}){
                if(row.status==='C'){
                    return 'cancel-row';
                }else if(row.status==='F'){
                    return 'finish-row';
                }
                return null;
            },
            handleCurrentChange(val){
                this.form.pages.currentpage = val;
                this.loadTable();
            },
            handleSortChange(column){
                this.form.pages.sortname = column.prop;
                this.form.pages.sortorder = column.order;
                this.loadTable();
            },
            loadTable(){
                this.loading = true;
                guide_list(this.form).then(response => {
                    this.tableData = response.data.records;
                    this.form.pages.total = response.data.total;
                }).finally(() => { this.loading = false;})
            },
            openGuide(id) {
                this.guideid = id;
                this.showGuideDetailDialog = true;
            },
            refresh(search){//刷新的时候也把col_rs刷一下
                if(search||!this.form.history){
                    this.loadTable();
                    this.$emit('refreshCol');
                }
            },
            handleCancel(row){
                this.$confirm('确定取消?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
                    this.$set(row, 'cancelLoading', true);
                    cancel_guides({"sqlid":row.sqlid}).then(() => {
                        this.$message({ message: '取消成功!', type: 'success'});
                        this.loadTable();
                        this.$emit('refreshCol');
                    }).finally(()=>{
                        this.$set(row, 'cancelLoading', false);
                    });
                }).catch(() => {});
            }
        }
    }
</script>

<style scoped>
    .el-table .cancel-row {
        background: #FFEBEE;
    }
    .el-table .finish-row {
        background: #E8F5E9;
    }
</style>