import { render, staticRenderFns } from "./orderlogs.vue?vue&type=template&id=1df6ea4b&scoped=true"
import script from "./orderlogs.vue?vue&type=script&lang=js"
export * from "./orderlogs.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1df6ea4b",
  null
  
)

export default component.exports