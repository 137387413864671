/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true            是否在左侧菜单栏可见.一般404页面啊.或者一些权限的才隐藏       if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true     一般只有首页才会设置成总是可见          if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect      是否允许调回这个面包屑导航     if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'            必填项.跳转路径必须的  the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']   哪些角色可以显示本菜单  control the page roles (you can set multiple roles)
    title: 'title'        显示在面包屑跟左侧菜单栏的名字       the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'          左侧图标 id   the icon show in the sidebar
    noCache: true          是否缓存页面内容       if set true, the page will no be cached(default is false)
    affix: true         是否固定页签         if set true, the tag will affix in the tags-view
    breadcrumb: false        是否显示在面包屑导航    if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  高亮的菜单路径  if set path, the sidebar will highlight the path you set
  }
 */
const prepayRouter = {
    path: '/prepay',
    component: Layout,
    redirect: 'noRedirect',
    name: 'prepay',
    meta: {
        title: '预付款管理',
        right: ['7000']
        // icon: 'chart'
    },
    children: [
        {
            path: 'pay',
            component: () => import('@/views/prepay/paylist'),
            name: 'PayList',
            meta: {title: ' 预付款列表',right:['7001']}
        }
    ]
}

export default prepayRouter
