<template>
  <div class="discountDiv">
    <el-table v-loading="discountLoading" :data="discountData" style="width: 100%" :summary-method="fixchargeSummary" show-summary
              max-height="450" border>
      <el-table-column prop="type" label="类型" min-width="80" :formatter="typeFormatter"/>
      <el-table-column prop="discountname" label="名称" min-width="200"/>
      <el-table-column prop="date" label="使用日期" min-width="100"/>
      <el-table-column prop="amount" label="价格" min-width="100"/>
      <el-table-column prop="discountno" label="优惠券码" min-width="120"/>
      <el-table-column prop="serialno" label="流水号" min-width="150"/>
    </el-table>
  </div>
</template>

<script>
  import {discount_list} from '@/api/order/discount';

  export default {
        name: "Discount",
        props: {
            colno:{ type: String,default: ''}
        },
        data(){
            return {
                discountLoading:false,discountData: []
            }
        },
        watch: {
            colno: function(val){ this.colno = val; }
        },
        mounted: function(){
          this.loadTable();
        },
        methods:{
            typeFormatter(row){
              if(row.type=="10"){
                return "满减";
              }else if(row.type=="20"){
                return "折扣";
              }
              return row.type;
            },
            loadTable(){
                this.discountLoading = true;
                discount_list({"colno":this.colno}).then(response => {
                    this.discountData = response.data.records;
                }).finally(() => { this.discountLoading = false;})
            },
            fixchargeSummary(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    if (column.property=='amount') {
                        const values = data.map(item => Number(item[column.property]));
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    }
                });
                return sums;
            }
        }
    }
</script>

<style scoped>

</style>