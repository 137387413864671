<template>
  <div>
    <el-input ref="nameinput" v-model="name" maxlength="60">
      <i slot="suffix" class="el-input__icon el-icon-postcard" @click="showProfileDialog = true"/>
    </el-input>

    <el-dialog v-dialogDrag title="选择档案"
               :visible.sync="showProfileDialog"
               width="85%"
               append-to-body
    >
      <ProfileSelect ref="profileSelect" :profileid="profileno" :name="name" :telno="phone" :type="[ptype]"
                     :visible.sync="showProfileDialog" @selectValue="selectProfile"/>
      <div slot="footer" class="dialog-footer"/>

    </el-dialog>
  </div>
</template>

<script>
    import ProfileSelect from "@/views/common/profile-select";

    export default {
        name: "ZjProfileselect",
        components: {ProfileSelect},
        props: {
            no: { type: String, default: "" },
            ptype:{type:String,default:""}, // :type="[ptype]"
            // type:{ type: Array,default: () => []},
            telno:{type:String,default:""},
            value: {
                type: String,
                default:""
            }
        },
        data() {
            return {
                name: this.value,
                phone: this.telno,
                profileno: this.no,
                showProfileDialog: false
            };
        },
        watch:{
            value(newVal) {
                this.name = newVal
            },
            telno(newVal) {
                this.phone = newVal
            },
            no(newVal) {
                this.profileno = newVal
            },
            name(newVal) {//TODO 后期增加校验..手动输入的 value 是否跟档案号是匹配的
                this.$emit('input', newVal)
            }
        },
        methods: {
            selectProfile(selectValue) {
                this.showProfileDialog = false;
                this.name = selectValue.name;
                this.profileno = selectValue.profileid;
                this.$emit('selectprofile',selectValue);
                this.$emit('update:no', selectValue.profileid);
            },
            getProfileinfo(){
                return {
                    name:this.name,
                    profileno:this.profileno
                };
            }
        }
    }
</script>

<style scoped></style>
