import Vue from 'vue'
import keymaster from 'keymaster'

const bindKeyHandler = fn => {
    return () => {
        fn();
        return false;
    }
};

export const bindKey = {
    bind: (key, func) => keymaster(key, bindKeyHandler(func)),
    ...keymaster
};

keymaster.filter = function(){
  return true;//不过滤条件，焦点在控件上也执行
};

Vue.prototype.$quickkey = bindKey;