<template>
  <!-- 因为不能互相注入 所以这里新增一个文件 -->
  <!-- 这个是会议详情里的资源 -->
  <div>
    <el-form :inline="true" :model="searchForm" class="search-form">
      <el-form-item label="会场">
        <el-select v-model="searchForm.site" placeholder="请选择会场" clearable filterable>
          <el-option v-for="item in meetingsites" :key="item.code" :label="item.desc" :value="item.code">
            <span style="float: left">{{ item.code }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="会议室">
        <el-select v-model="searchForm.room" placeholder="请选择会议室" clearable filterable>
          <el-option v-for="item in meetingrooms" :key="item.code" :label="item.desc" :value="item.code">
            <span style="float: left">{{ item.code }}</span>
            <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker v-model="searchDate" type="daterange" align="right" unlink-panels
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        value-format="yyyy-MM-dd" :clearable="false"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="refreshTable">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="tabledata" style="width: 100%" max-height="400" border>
      <el-table-column v-for="(item,index) in tablehead" :key="index" :prop="item.colcode" :label="item.colname" :min-width="item.colwidth"
                       align="center" :fixed="index<=1" :show-overflow-tooltip="true">
        <template v-if="item.childcol">
          <el-table-column v-for="(subitem,subindex) in item.childcol" :key="subindex" :prop="subitem.colcode"
                           :label="subitem.colname" :min-width="subitem.colwidth" :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <el-button v-if="meetingid>0 && scope.row.data[(index-2)*timesize+subindex].sqlid==meetingid" size="mini" type="warning">当前</el-button>
              <el-button v-else-if="scope.row.data[(index-2)*timesize+subindex].status!=''" size="mini" type="danger" disabled>占用</el-button>
              <el-button v-else size="mini" :disabled="checkNewDate(scope.row.data[(index-2)*timesize+subindex].date)"
                         @click="handleSelect(scope.row.data[(index-2)*timesize+subindex])">空闲</el-button>
            </template>
          </el-table-column>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :current-page="searchForm.pages.currentpage" :page-size="searchForm.pages.pagesize"
                   layout="total, prev, pager, next, jumper" :total="searchForm.pages.total" style="margin-top: 10px;float: right;" background
                   @current-change="handleCurrentChange"/>
  </div>
</template>

<script>
    import {meeting_resoucelist} from '@/api/order/meeting';
    import {formatDate, reckonDayStr, dateIsExpire} from '@/utils/calcdate';
    import {select_data} from '@/api/common/custom-data';
    import {select_res} from "@/utils/select_data_res";

    export default {
        name: "meetingResource2",
        props: {
            searchroom:{ type: String,default: ''},//会议室
            searchdate:{ type: String,default: formatDate(new Date())},//会议日期
            meetingid:{ type: Number,default: 0},//会议室订单唯一id
            visible:{type: Boolean,default: true}
        },
        data: function(){
            return{
                searchForm:{
                    site:'',room:this.searchroom,startdate:this.searchdate,enddate:reckonDayStr(this.searchdate,6),
                    pages:{total:0, currentpage:1, pagesize:20}
                },
                loading:false,tabledata:[],tablehead:[],timesize:1,
                room:'',date:formatDate(new Date()),time:'',meetingrooms:[],meetingsites:[]
            }
        },
        computed:{
            searchDate:{
                get:function(){
                    return this.searchForm.startdate!=null && this.searchForm.enddate!=null?[this.searchForm.startdate,this.searchForm.enddate]:[];
                },
                set:function(dateValue){
                    this.searchForm.startdate = dateValue!=null&&dateValue.length>0?dateValue[0]:'';
                    this.searchForm.enddate = dateValue!=null&&dateValue.length>1?dateValue[1]:'';
                }
            }
        },
        watch: {
            searchroom: function(val){
                this.searchForm.room = val;
            },
            searchdate: function(val){
              this.searchForm.startdate = val;
              this.searchForm.enddate = reckonDayStr(val,6);//默认显示7天
            },
            visible: function(val){
                if(val){
                    this.refreshTable();
                }
            }
        },
        mounted: function(){
            select_data({"keyname":"meeting_room,meeting_site"}).then(response => {
                this.meetingrooms = select_res(response.data,"meeting_room");
                this.meetingsites = select_res(response.data,"meeting_site");
            });
            this.loadTable();
        },
        methods:{
            refreshTable(){
                this.searchForm.pages.currentpage = 1;
                this.loadTable();
            },
            loadTable(){
                this.loading = true;
                meeting_resoucelist(this.searchForm).then(response => {
                    this.tabledata = response.data.tableData;
                    this.tablehead = response.data.tableHead;
                    this.searchForm.pages.total = response.data.total;
                    this.timesize = response.data.timeSize;
                }).finally(() => { this.loading = false;})
            },
            handleCurrentChange(val){
                this.searchForm.pages.currentpage = val;
                this.loadTable();
            },
            checkNewDate(date){
                return dateIsExpire(date);
            },
            handleSelect(row){
                this.$emit('selectValue', row);
            }
        }
    }
</script>

<style scoped>

</style>