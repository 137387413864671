import request from "@/utils/request";

export function get_trace_list(data) {
    return request({
        url: '/api/trace/get_trace_list',
        method: 'post',
        data
    })
}

export function apply_trace(data) {
    return request({
        url: '/api/trace/apply_trace',
        method: 'post',
        data
    })
}

export function finish_trace(data) {
    return request({
        url: '/api/trace/finish_trace',
        method: 'post',
        data
    })
}