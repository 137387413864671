<template>
  <div class="main-div">
    <el-container>
      <el-header height="40px">{{ showTitle }}<span style="float:right">{{ showStatus }}</span></el-header>
      <el-main>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px" class="base-form">
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="联系人" prop="name">
                <el-input ref="nameinput" v-model="form.name" maxlength="60" @blur="checkNameExist">
                  <i slot="suffix" class="el-input__icon el-icon-s-grid" @click="showProfileDialog=true"/>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="电话号码" prop="telephone">
                <el-input ref="telinput" v-model="form.telephone" maxlength="20" clearable/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="渠道" prop="subchannel">
                <el-select v-model="form.subchannel" placeholder="请选择渠道" filterable>
                  <el-option v-for="item in subchannels" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="车船代码" prop="ticket">
                <el-select v-model="form.ticket" placeholder="请选择车船代码" filterable @change="calcPrice">
                  <el-option v-for="item in tickets" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="数量" prop="num">
                <el-input-number v-model="form.num" :min="1" @change="changeNum"/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="车船价格">
                <el-input v-model="form.price" :readonly="!form.fix" @blur="changePrice">
                  <template slot="append">
                    <el-checkbox v-model="form.fix" @change="calcPrice">固定价</el-checkbox>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="使用日期" prop="date">
                <el-date-picker v-model="form.date" align="right" type="date" :picker-options="pickerOptions"
                                :clearable="false" value-format="yyyy-MM-dd"/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="人数">
                <el-input-number v-model="form.person" :min="0"/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="总价">
                <span class="pricelabel">{{ form.totalprice }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="付款方式">
                <el-select v-model="form.payment" placeholder="请选择付款方式" filterable>
                  <el-option v-for="item in payments" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="来源">
                <el-select v-model="form.scode" placeholder="请选择来源" clearable filterable>
                  <el-option v-for="item in scodes" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="创建信息">
                <el-input v-model="createDateTime" readonly/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="24" :sm="24" :xs="24">
              <el-form-item label="备注">
                <el-input v-model="form.info" type="textarea" :rows="3" placeholder="请输入备注内容"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
      <el-footer height="40px">
        <el-button type="primary" icon="el-icon-edit-outline" :loading="buttonLoading"
                   :disabled="form.status!='N'||history" @click="handleSave">保存</el-button>
        <el-button type="danger" icon="el-icon-warning-outline" :loading="buttonLoading"
                   :disabled="form.sqlid<=0||(form.status!='N'&&form.status!='P')||history" @click="handleCancel">取消</el-button>
        <el-button v-if="gotocol" type="primary" icon="el-icon-refresh-left" :disabled="form.sqlid<=0||form.colno==''"
                   @click="gotoCol">综合预定</el-button>
      </el-footer>
    </el-container>
    <!-- 窗口Dialog -->
    <el-dialog v-dialogDrag title="选择档案" :visible.sync="showProfileDialog" :close-on-click-modal="false" width="85%" append-to-body>
      <ProfileSelect :profileid="form.profileid" :name="form.name" :telno="form.telephone" :type="['']" :visible="showProfileDialog" @selectValue="selectProfile"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
  </div>
</template>

<script>
import {cancel_boats,save_boats,load_boats} from '@/api/order/boat';
import {select_data} from '@/api/common/custom-data';
import {name_exist} from '@/api/profile/profile';
import {getTicketStatus} from '@/utils/status-format';
import {select_res} from "@/utils/select_data_res";
import '@/styles/pagecontainer.scss';
import ProfileSelect from "@/views/common/profile-select";

export default {
  name:'Boatdetail',
  components: {ProfileSelect},
  props: {
    colno:{ type: String,default: ''},
    boatid:{ type: Number,default: 0},
    history:{type: Boolean,default: false},
    visible:{type: Boolean,default: true},
    gotocol:{type: Boolean,default: false}//是否显示跳转综合预定按钮
  },
  data() {
    return {
      form:{},
      pickerOptions: {
        disabledDate(time) { return time.getTime() < Date.now() - 8.64e7; },//不能选之前的日期
        shortcuts: [{ text: '今天', onClick(picker) { picker.$emit('pick', new Date()); } }]
      },
      rules: {
        name: [{ required: true, message: '请输入联系人姓名', trigger: 'change' }],
        telephone: [{ required: true, message: '请输入电话号码', trigger: 'change' }],
        ticket: [{ required: true, message: '请选择票务代码', trigger: 'change' }],
        subchannel: [{ required: true, message: '请选择渠道', trigger: 'change' }],
        date: [{ required: true, message: '请选择游玩日期', trigger: 'change' }],
        num: [{ required: true, message: '请选择正确的票数', trigger: 'change' }]
      },
      subchannels:[],tickets:[],payments:[],scodes:[],
      showProfileDialog:false,buttonLoading:false,orgPrice:0
    }
  },
  computed:{
    showTitle(){ return this.form.sqlid!=null&&this.form.sqlid>0?'编辑车船订单  订单号: '+this.form.bookingid+' 综合订单号: '+this.form.colno+
            (this.form.interfaceid!=''?'  线下订单号: '+this.form.interfaceid:''):'新建车船订单'},
    showStatus(){ return this.form.sqlid!=null&&this.form.sqlid>0?getTicketStatus(this.form.status):''},
    createDateTime:{
      get:function(){
        return this.form.createdate==null||this.form.createdate=='1900-01-01'?this.form.creator
                :this.form.createdate+' '+this.form.createtime+ ' '+this.form.creator;
      }
    }
  },
  watch:{
    visible: function(val){
      if(val){
        this.initDialog();
      }
    }
  },
  mounted: function(){
    this.$refs.nameinput.focus();
    select_data({"keyname":"subchannel,boatticket,payment,source"}).then(response => {
      this.subchannels = select_res(response.data,"subchannel");
      this.tickets = select_res(response.data,"boatticket");
      this.payments = select_res(response.data,"payment");
      this.scodes = select_res(response.data,"source");
    });
    this.initDialog();
  },
  methods: {
    initDialog(){
      load_boats({"sqlid":this.boatid,"colno":this.colno,"history":this.history}).then(response => {
        this.form = response.data;
        this.orgPrice = this.form.price;
      }).catch(() => {
        this.$emit('update:visible',false);
      })
    },
    checkNameExist(){
      if(this.form.profileid==''&&this.form.name.length>0){
        name_exist({"name":this.form.name}).then(response => {
          if(response.data){
            this.showProfileDialog = true;
          }
        })
      }
    },
    selectProfile(selectValue){
      this.showProfileDialog = false;
      this.form.profileid = selectValue.profileid;
      this.form.name = selectValue.name;
      this.form.telephone = selectValue.mobile;
      this.form.idnumber = selectValue.idnum;
    },
    changeNum(val){
      if(!this.form.fix){
        this.form.totalprice = (val*this.form.price).toFixed(2);
      }
    },
    // changeFix(){
    //   if(!this.form.fix){
    //     this.form.price = this.orgPrice;
    //     this.form.totalprice = this.form.price * this.form.num;
    //   }
    // },
    changePrice(){
      if(this.form.fix){
        this.form.totalprice = this.form.price;
      }
    },
    calcPrice(){
      if(!this.form.fix) {
        let price = 0.00;
        let boatTicket = this.form.ticket;
        if(boatTicket.length>0){
          this.tickets.forEach(function(val){
            if(val.code==boatTicket){
              price = val.group;
            }
          });
        }
        this.orgPrice = price;
        this.form.price = price;
        this.form.totalprice = price * this.form.num;
      }
    },
    handleSave(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.buttonLoading = true;
          save_boats(this.form).then(response => {
            this.$msgbox({ title: '消息', message: '保存成功! 订单号:'+response.data.bookingid, confirmButtonText: '关闭'}).catch(() => {});
            this.$emit('refresh');
            if(!this.gotocol){//如果可以跳转到综合预定,保存后界面不关
              this.$emit('update:visible', false);
            }else{
              this.form = response.data;
            }
          }).finally(()=>{
            this.buttonLoading = false;
          })
        } else {
          return false;
        }
      });
    },
    handleCancel(){
      this.$confirm('确定取消?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
        this.buttonLoading = true;
        cancel_boats({"sqlid":this.form.sqlid}).then(() => {
          this.$message({ message: '取消成功!', type: 'success'});
          this.$emit('refresh');
          this.$emit('update:visible',false);
        }).finally(()=>{
          this.buttonLoading = false;
        })
      }).catch(() => {});
    },
    gotoCol(){
      this.$emit('update:rscolno',this.form.colno);
      this.$emit('gotoCol');
    }
  }
}
</script>

<style>
  .pricelabel{
    color:#c11a25;
    font-size: 22px;
    font-weight: bolder;
  }
</style>
