import request from '@/utils/request'

export  function spa_list(data){
    return request({
        url:'/api/spa/spa_list',
        method:'post',
        data
    })
}

export  function  load_spas(data){
    return request({
        url:'/api/spa/load_spas',
        method:'post',
        data
    })
}

export  function  save_spas(data){
    return request({
        url:'/api/spa/save_spas',
        method:'post',
        data
    })
}

export  function  cancel_spas(data){
    return request({
        url:'/api/spa/cancel_spas',
        method:'post',
        data
    })
}

export  function  ticket_list(data){
    return request({
        url:'/api/spasetup/ticket_list',
        method:'post',
        data
    })
}
