import request from "@/utils/request";

export function log_list(data) {
    return request({
        url: '/api/interfacelog/log_list',
        method: 'post',
        data
    })
}

export function type_list(data) {
    return request({
        url: '/api/interfacelog/type_list',
        method: 'post',
        data
    })
}

export function orderlog_list(data) {
    return request({
        url: '/api/interfacelog/orderlog_list',
        method: 'post',
        data
    })
}

export function resend_asyncorder(data) {
    return request({
        url: '/ota/order/addasync_resend',//不用addasync,不然有redis缓存
        method: 'post',
        data
    })
}

export function resend_asynccancel(data) {
    return request({
        url: '/ota/order/cancelasync',
        method: 'post',
        data
    })
}