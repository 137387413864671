<template>
  <div class="fixchargeDiv">
    <el-table v-loading="fixchargeLoading" :data="fixChargeData" style="width: 100%" :summary-method="fixchargeSummary" show-summary
              :row-class-name="tableRowClassName" max-height="450" border>
      <el-table-column prop="status" label="状态" min-width="100" :formatter="statusFormatter"/>
      <el-table-column prop="code" label="编码" min-width="100"/>
      <el-table-column prop="kitname" label="名称" min-width="200"/>
      <el-table-column prop="anz" label="数量" min-width="80"/>
      <el-table-column prop="totalprice" label="总价" min-width="150" :formatter="priceFormatter"/>
      <el-table-column fixed="right" prop="operation" label="操作" min-width="100">
        <template slot-scope="operatescope">
          <el-button size="mini" type="danger" :disabled="operatescope.row.status!==''||history"
                     @click="handleCancel(operatescope.row)">取消</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
  import {fixcharge_list, cancel_fixcharge} from '@/api/order/fixcharge';

  export default {
        name: "Fixcharge",
        props: {
            colno:{ type: String,default: ''},
            history:{ type: Boolean,default: false}
        },
        data(){
            return {
                fixchargeLoading:false,fixChargeData: []
            }
        },
        watch: {
            colno: function(val){ this.colno = val; },
            history: function(val){ this.history = val; }
        },
        mounted: function(){
          this.loadTable();
        },
        methods:{
            priceFormatter(row){
              return row.price*row.anz;
            },
            statusFormatter(row){
              if(row.status==='C'){
                return '取消';
              }else if(row.status==='F'){
                return '完成';
              }else{
                return '预定';
              }
            },
            loadTable(){
                this.fixchargeLoading = true;
                fixcharge_list({"colno":this.colno,"history":this.history}).then(response => {
                    this.fixChargeData = response.data.records;
                }).finally(() => { this.fixchargeLoading = false;})
            },
            fixchargeSummary(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    if (column.property==='anz'||column.property==='totalprice') {
                        let values = null;
                        if(column.property==='totalprice'){
                          values = data.map(item => Number(item['anz'])*Number(item['price']));
                        }else{
                          values = data.map(item => Number(item[column.property]));
                        }
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                    }
                });
                return sums;
            },
            tableRowClassName({row}){
              if(row.status==='C'){
                return 'cancel-row';
              }else if(row.status==='F'){
                return 'finish-row';
              }
              return null;
            },
            handleCancel(row){
              this.$confirm('确定取消?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
                cancel_fixcharge({"sqlid":row.sqlid}).then(response => {
                  this.$message({ message: '取消成功!', type: 'success'});
                  this.loadTable();
                  this.$emit('refreshCol');
                });
              }).catch(() => {});
            }
        }
    }
</script>

<style scoped>
  .el-table .cancel-row {
    background: #FFEBEE;
  }
  .el-table .finish-row {
    background: #E8F5E9;
  }
</style>