/** When your routing table is too long, you can split it into small modules**/

import Layout from '@/layout'
/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true            是否在左侧菜单栏可见.一般404页面啊.或者一些权限的才隐藏       if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true     一般只有首页才会设置成总是可见          if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect      是否允许调回这个面包屑导航     if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'            必填项.跳转路径必须的  the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']   哪些角色可以显示本菜单  control the page roles (you can set multiple roles)
    title: 'title'        显示在面包屑跟左侧菜单栏的名字       the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'          左侧图标 id   the icon show in the sidebar
    noCache: true          是否缓存页面内容       if set true, the page will no be cached(default is false)
    affix: true         是否固定页签         if set true, the tag will affix in the tags-view
    breadcrumb: false        是否显示在面包屑导航    if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  高亮的菜单路径  if set path, the sidebar will highlight the path you set
  }
 */
const channelRouter = {
  path: '/cha',
  component: Layout,
  redirect: 'noRedirect',
  name: 'channel',
  meta: {
    title: ' 渠道锁房',
    right:['3000']
    // icon: 'chart'
  },
  children: [
    {
      path: 'blocklist',
      component: () => import('@/views/channel/block/blocklist'),
      name: 'block_list',
      meta: { title: '锁房管理',right:['3001']}
    },
    {
      path: 'channelmanager',
      component: () => import('@/views/channel/channels/index'),
      name: 'channel_manager',
      meta: { title: '渠道管控',right:['3002']},
      // redirect:'/cha/channelmanager/channellist', //节点默认显示路径:
      children: [
        {
          path: 'channellist',
          component: () => import('@/views/channel/channels/channellist'),
          name: 'channel_list',
          meta: { title: '渠道列表',right:['3003']}
        },
        {
          path: 'channelstrategy',
          component: () => import('@/views/channel/channels/channelstrategy'),
          name: 'channel_strategy',
          meta: { title: '退款策略',right:['3004']}
        },
        {
          path: 'subchannels',
          component: () => import('@/views/channel/channels/subchannels'),
          name: 'channel_subchannels',
          meta: { title: '二级渠道',right:['3005']}
        },
        {
          path: 'roomstate',
          component: () => import('@/views/channel/channels/roomstatelist.vue'),
          name: 'roomStateList',
          meta: { title: '渠道房态',right:['3006']}
        },
        {
          path: 'channelremark',
          component: () => import('@/views/channel/channels/channelremark.vue'),
          name: 'channelRemark',
          meta: { title: '渠道备注',right:['3007']}
        }
      ]
    }
  ]
};

export default channelRouter
