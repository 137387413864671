import request from '@/utils/request'

export function block_list(data) {
  return request({
    url: '/api/block/block_list',
    method: 'post',
    data
  })
}

export function load_block(data) {
  return request({
    url: '/api/block/load_block',
    method: 'post',
    data
  })
}

export function save_block(data) {
  return request({
    url: '/api/block/save_block',
    method: 'post',
    data
  })
}

export function use_block(data) {
  return request({
    url: '/api/block/use_block',
    method: 'post',
    data
  })
}

export function cancel_block(data) {
  return request({
    url: '/api/block/cancel_block',
    method: 'post',
    data
  })
}
