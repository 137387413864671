<template>
  <div class="colroom">
    <div style="margin-bottom: 10px">
      <el-button type="primary" icon="el-icon-edit-outline" :disabled="history" @click="openRoom(0)">新增</el-button>
      <el-button icon="el-icon-refresh" @click="refresh(true)">刷新</el-button>
    </div>

    <el-table v-loading="loading" :data="tabledata" :summary-method="tableSummary" :show-summary="summary!=''"
              :row-class-name="tableRowClassName" border style="width: 100%" max-height="450" @sort-change="handleSortChange">
      <el-table-column prop="status" label="状态" min-width="80" sortable="custom">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status==='X'? 'danger' : 'primary'" disable-transitions>{{ statusFormatter(scope.row.status) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="guestname" label=" 姓名" min-width="100" :show-overflow-tooltip="true" sortable="custom"/>
      <el-table-column prop="guestphone" label="电话" min-width="100"/>
      <el-table-column prop="hotelcode" label="酒店" min-width="120" :show-overflow-tooltip="true" sortable="custom"/>
      <el-table-column prop="rmtype" label="房型" min-width="180" :show-overflow-tooltip="true" sortable="custom"/>
      <el-table-column prop="anz" label="房间数" min-width="70"/>
      <el-table-column prop="roomno" label="房间号" min-width="85" sortable="custom"/>
      <el-table-column prop="arrdate" label="到店日期" min-width="100" sortable="custom"/>
      <el-table-column prop="deptdate" label="离店日期" min-width="100" sortable="custom"/>
      <el-table-column prop="totalprice" label="总价" min-width="100"/>
      <el-table-column prop="channelsource" label="渠道" min-width="100" sortable="custom"/>
      <el-table-column prop="pmsno" label="PMS订单号" min-width="120" sortable="custom"/>
      <el-table-column prop="colno" label="综合预订号" min-width="150"/>
      <el-table-column fixed="right" prop="operation" label="操作" min-width="160">
        <template slot-scope="operatescope">
          <el-button size="mini" type="primary" @click="openRoom(operatescope.row.sqlid)">编辑</el-button>
          <el-button size="mini" type="danger" :disabled="operatescope.row.status=='X'||form.history"
                     :loading="operatescope.row.cancelLoading" @click="handleCancel(operatescope.row)">取消
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :current-page="form.pages.currentpage" :page-size="form.pages.pagesize"
                   layout="total, prev, pager, next, jumper" :total="form.pages.total" style="margin-top: 10px;float: right;" background
                   @current-change="handleCurrentChange"/>

    <el-dialog v-dialogDrag :visible.sync="showRoomrsDialog" width="80%" top="3vh" :close-on-click-modal="false" append-to-body>
      <roomrs-detail :roomid="rsid" :colno="form.colno" :history="form.history" :visible.sync="showRoomrsDialog" @refresh="refresh(false)"/>
    </el-dialog>
  </div>
</template>

<script>
    import {roomrs_list, cancel_roomrs} from "@/api/order/order";
    import {getRoomRsStatus} from "@/utils/status-format";
    import RoomrsDetail from '@/views/order/room/roomrsdetail';

    export default {
        name: "ColRoom",
        components: {RoomrsDetail},
        props: {
            colno: {type: String, default: ''},
            // profileid: {type: String, default: ''},
            custom: {type: String, default: ''},//根据类型处理表格上方控件
            summary: {type: String, default: ''},//不为空可以进行统计
            // cancel:{ type: Boolean,default: false},
            history:{ type: Boolean,default: false},
            // roomtype: {type: String, default: ''},//在Block预留那边用到
            // block: {type: String, default: ''},//在Block预留那边用到
            // startdate: {type: String, default: ''},//在Block预留那边用到
            // enddate: {type: String, default: ''},//在Block预留那边用到  范围<=startdate & >=enddate
            // inhouse:{ type: Boolean,default: false},
            // subchanneltype:{ type: String,default: ''}
        },
        data() {
            return {
                form: {
                    // arrfrom: new Date(),
                    // arrto: null,
                    // deptfrom: null,
                    // guestname: '',
                    // orderno: '',
                    colno: this.colno,
                    // profileid: this.profileid,//增加档案号
                    // status:['R','C'],
                    // phone:'',
                    // hotel:'',
                    // roomtype:'',
                    // block:'',
                    history: this.history,
                    // inhouse: this.inhouse,
                    // subchanneltype: this.subchanneltype,
                    // pages:{total:0, currentpage:1, pagesize:10, sortname:'arrdate', sortorder:'ascending'}
                    pages:{total:0, currentpage:1, pagesize:10, sortname:'roomno,colno', sortorder:'ascending'}
                },
                tabledata: [],
                // hotels:[],
                rsid: 0,
                showRoomrsDialog: false,
                // showRateQuery: false,
                // showCti:false,
                loading:false
            }
        },
        watch: {
          colno: function (val) {
            this.form.colno = val;
          },
          // profileid: function(val){
          //   this.form.profileid = val;
          //   if(val!=null && val.length>0){
          //     // this.form.status = 'A';
          //     this.form.status = ['R','C','L','N'];
          //     this.form.arrfrom = null;
          //   }
          // },
          // cancel: function(val){
          //   if(this.profileid.length>0){//是否包含取消这个只有在档案里用到
          //     if(val){
          //       // this.form.status = 'A';
          //       this.form.status = [];
          //     }else{
          //       // this.form.status = ',C,L,N';
          //       this.form.status = ['R','C','L','N'];
          //     }
          //   }
          // },
          history: function(val){ this.form.history = val; },
          // inhouse: function(val){ this.form.inhouse = val; },
          // subchanneltype: function(val){ this.form.subchanneltype = val; },
        },
        mounted: function () {
          // if(this.custom==''){
          //   select_data({"keyname": "hotel"}).then(response => {
          //     this.hotels = select_res(response.data,"hotel");
          //   });
          // }
          this.loadTable();
        },
        methods: {
            statusFormatter(val) {
              return getRoomRsStatus(val);
            },
            tableSummary(param) {
                const { columns, data } = param;
                const sums = [];
                columns.forEach((column, index) => {
                    if (index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    if (column.property=='anz'||column.property=='totalprice') {
                        const values = data.map(function(item){
                            if(item['status']!='X'){
                                return Number(item[column.property]);
                            }else{
                                return 0;
                            }
                        });
                        sums[index] = values.reduce((prev, curr) => {
                            const value = Number(curr);
                            if (!isNaN(value)) {
                                return prev + curr;
                            } else {
                                return prev;
                            }
                        }, 0);
                        if (column.property=='totalprice') {//总价列合计保留两位小数
                            sums[index] = sums[index].toFixed(2);
                        }
                    }
                });
                return sums;
            },
            tableRowClassName({row}){
                if(row.status==='X'){
                    return 'cancel-row';
                }else if(row.status==='C'){
                    return 'start-row';
                }else if(row.status==='L'){
                    return 'finish-row';
                }
                return null;
            },
            handleCurrentChange(val) {
              this.form.pages.currentpage = val;
              this.loadTable();
            },
            handleSortChange(column){
              this.form.pages.sortname = column.prop;
              this.form.pages.sortorder = column.order;
              this.loadTable();
            },
            loadTable() {
              // this.initGetData();//初始化查询数据
              this.loading = true;
              roomrs_list(this.form).then(response => {
                this.tabledata = response.data.records;
                this.form.pages.total = response.data.total;
              }).finally(() => { this.loading = false;})
            },
            // initGetData(){
            //   if(this.block!=null && this.block.length>0){//锁房这边默认的搜索条件
            //     this.form.arrto = this.startdate;
            //     if(this.startdate!=null){
            //       this.form.arrfrom = null;
            //     }
            //     this.form.deptfrom = this.enddate;
            //     this.form.roomtype = this.roomtype;
            //     this.form.block = this.block;
            //     this.form.status = [];
            //   }
            //   if((this.block!=null && this.block.length>0)||(this.colno!=null && this.colno.length>0)){//如果是锁房或综合预定界面 按房间号和综合预定号排序
            //     this.form.pages.sortname = 'roomno,colno';
            //   }
            // },
            refresh(search){//刷新的时候也把col_rs刷一下
              if(search||!this.form.history){
                this.loadTable();
                this.$emit('refreshCol');
              }
            },
            openRoom(id) {
              this.rsid = id;
              this.showRoomrsDialog = true;
            },
            handleCancel(row) {
              this.$confirm('确定取消?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
                this.$set(row, 'cancelLoading', true);
                cancel_roomrs({sqlid: row.sqlid}).then(() => {
                  this.$message({ message: '取消成功!', type: 'success'});
                  this.loadTable();
                  this.$emit('refreshCol');
                }).finally(()=>{
                  this.$set(row, 'cancelLoading', false);
                });
              }).catch(() => {});
            }
        }
    }
</script>

<style scoped>
    .el-table .cancel-row {
        background: #FFEBEE;
    }
    .el-table .finish-row {
        background: #E8F5E9;
    }
    .el-table .start-row {
        background: #E3F2FD;
    }
</style>