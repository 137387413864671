<template>
  <div>
    <el-container>
      <el-header height="40px">{{ showTitle }}<span style="float:right">{{ showStatus }}</span></el-header>
      <el-main>
        <el-form ref="form" :model="rsdata" :rules="rules" label-width="80px" class="base-form">
          <el-divider content-position="left">客人信息</el-divider>
          <el-row :gutter="20">
            <el-col :md="6" :sm="6" :xs="24">
              <el-form-item label="客人姓名" prop="guestname">
                <el-input ref="nameinput" v-model="rsdata.guestname" maxlength="60" @blur="checkNameExist">
                  <i slot="suffix" class="el-input__icon el-icon-s-grid" @click="showProfileDialog=true"/>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="6" :xs="24">
              <el-form-item label="电话号码">
                <el-input v-model="rsdata.guestphone" maxlength="20" clearable/>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="6" :xs="24">
              <el-form-item label="证件号码">
                <el-input v-model="rsdata.idnum" maxlength="20" clearable/>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="6" :xs="24">
              <el-form-item label="国籍">
                <el-select v-model="rsdata.country" filterable clearable>
                  <el-option v-for="item in countrys" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :md="6" :sm="6" :xs="24">
              <el-form-item label="公司">
                <zj-profileselect v-model="rsdata.comname" :no.sync="rsdata.companyid" :ptype="'C'"/>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="6" :xs="24">
              <el-form-item label="团队">
                <zj-profileselect v-model="rsdata.groupname" :no.sync="rsdata.groupid" :ptype="'G'"/>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="6" :xs="24">
              <el-form-item label="旅行社">
                <zj-profileselect v-model="rsdata.travelname" :no.sync="rsdata.travelid" :ptype="'T'"/>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="6" :xs="24">
              <el-form-item label="客源">
                <zj-profileselect v-model="rsdata.sourcename" :no.sync="rsdata.sourceid" :ptype="'S'"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider content-position="left">预订信息</el-divider>
          <el-row :gutter="20">
            <el-col :md="9" :xs="24">
              <el-form-item :label="showDays" prop="arrdate">
                <el-date-picker v-model="dateselect" type="daterange" unlink-panels
                                range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                value-format="yyyy-MM-dd"
                                :picker-options="pickerOptions" :clearable="false"
                                style="width: 100%"
                                @change="calcprice"
                />
              </el-form-item>
            </el-col>
            <el-col :md="5" :xs="24">
              <el-form-item label="房间数">
                <el-input-number v-model="rsdata.anz" :min="1" :max="99" @change="calcprice"/>
              </el-form-item>
            </el-col>
            <el-col :md="5" :xs="24">
              <el-form-item label="成人数">
                <el-input-number v-model="rsdata.adult" :min="1" :max="99" @change="calcprice"/>
              </el-form-item>
            </el-col>
            <el-col :md="5" :xs="24">
              <el-form-item label="小孩数">
                <el-input-number v-model="rsdata.child" :min="0" :max="99" @change="calcprice"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :md="6" :sm="6" :xs="24">
              <el-form-item label="价格代码" prop="ratecode">
                <zj-ratecodeselect v-model="rsdata.ratecode" :calcform="rsdata" @selectrate="selectrate" />
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="4" :xs="24">
              <el-form-item label="房型">
                <el-select v-model="rsdata.rmtype" placeholder="请选择房型" clearable filterable @change="calcprice">
                  <el-option v-for="item in roomtypes" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="4" :xs="24">
              <el-form-item label="Block" prop="block">
                <zj-blockselect v-model="rsdata.block" :calcform="rsdata" :products="blockrmtypes"/>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="4" :xs="24">
              <el-form-item label="房间号">
                <el-input ref="nameinput" v-model="rsdata.roomno" maxlength="60" readonly/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :md="6" :sm="6" :xs="24">
              <el-form-item label="单价">
                <el-input v-model="rsdata.price" :readonly="!rsdata.fixrate" @change="calcprice"> <!--光标离开时校验--->
                  <template slot="append"><el-checkbox v-model="rsdata.fixrate" @change="calcprice">固定价</el-checkbox></template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="6" :xs="24">
              <el-form-item label="总价">
                <span class="pricelabel" >{{ rsdata.totalprice }}</span>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="6" :xs="24">
              <el-form-item label="付款方式">
                <el-select v-model="rsdata.payment" filterable clearable>
                  <el-option v-for="item in payments" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="4" :xs="24">
              <el-form-item label="渠道">
                <el-select v-model="rsdata.channelsource" clearable filterable>
                  <el-option v-for="item in subchannels " :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :md="6" :sm="4" :xs="24">
              <el-form-item label="预订类型">
                <el-select v-model="rsdata.restype" placeholder="预订类型" clearable filterable >
                  <el-option v-for="item in restypes" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="4" :xs="24">
              <el-form-item label="市场">
                <el-select v-model="rsdata.market" placeholder="市场码" clearable filterable >
                  <el-option v-for="item in markets" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="4" :xs="24">
              <el-form-item label="来源">
                <el-select v-model="rsdata.scode" clearable filterable>
                  <el-option v-for="item in sources" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="12" :xs="24">
              <el-form-item label="创建信息">
                <el-input v-model="createDateTime" readonly/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :md="24" :sm="24" :xs="24">
              <el-form-item label="备注">
                <el-input v-model="rsdata.remark" type="textarea" :rows="4" placeholder="请输入备注内容"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
    </el-container>
    <el-footer height="40px">
      <el-button type="primary" icon="el-icon-edit-outline" :disabled="history" :loading="buttonLoading" @click="save">保存</el-button>
      <el-button type="danger" icon="el-icon-warning-outline" :disabled="rsdata.sqlid<=0||rsdata.status!==''||history"
                 :loading="buttonLoading" @click="handleCancel">取消</el-button>
      <el-button v-if="gotocol" type="primary" icon="el-icon-refresh-left" :disabled="rsdata.sqlid<=0||rsdata.colno==''"
                 @click="gotoCol">综合预定</el-button>
      <el-dropdown style="margin-left: 10px" @command="handleCommand">
        <el-button type="primary" icon="el-icon-tickets">日志<i class="el-icon-arrow-down el-icon--right"/></el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="U">用户日志</el-dropdown-item>
          <el-dropdown-item command="I">接口日志</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <!--      <el-button icon="el-icon-close" @click="closeDialog">关闭</el-button>-->
    </el-footer>
    <!-- 窗口Dialog -->
    <el-dialog v-dialogDrag title="选择档案" :visible.sync="showProfileDialog" width="85%" append-to-body>
      <!--@opened="openProfileSelect"-->
      <ProfileSelect ref="profileSelect" :profileid="rsdata.profileid"
                     :name="rsdata.guestname" :telno="rsdata.guestphone" :type="['']" :visible.sync="showProfileDialog" @selectValue="selectProfile"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <el-dialog v-dialogDrag title="查看日志" :visible.sync="showLogDialog" :close-on-click-modal="false" width="70%" append-to-body>
      <UserLogs ref="userlogs" :type="'ROOMRS'" :bookingid="rsdata.regno" :visible.sync="showLogDialog"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <el-dialog v-dialogDrag title="查看接口日志" :visible.sync="showInterfaceLogDialog" :close-on-click-modal="false" width="70%" append-to-body>
      <OrderLogs :crsno="rsdata.regno" :otano="rsdata.otano" :pmsno="rsdata.pmsno" :visible.sync="showInterfaceLogDialog"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
  </div>
</template>

<script>
    import {save_roomrs, load_roomrs, cancel_roomrs} from "@/api/order/order";
    import {select_data,calc_price_detail} from '@/api/common/custom-data';
    import {select_res} from "@/utils/select_data_res";
    import {name_exist} from '@/api/profile/profile';
    import '@/styles/pagecontainer.scss';
    import ProfileSelect from "@/views/common/profile-select";
    import ZjProfileselect from "@/views/order/common/ZjProfileselect";
    import {getRoomRsStatus} from "@/utils/status-format";
    import {stringDateDiff} from '@/utils/calcdate';
    import ZjRatecodeselect from "@/views/order/common/ZjRatecodeselect";
    import ZjBlockselect from "@/views/order/common/ZjBlockselect";
    import UserLogs from "@/views/common/userlogs";
    import OrderLogs from "@/views/common/orderlogs";

    export default {
        name: "RoomrsDetail",
        components: { ZjRatecodeselect, ZjProfileselect, ProfileSelect,ZjBlockselect, UserLogs, OrderLogs},
        props: {
            roomid: {type: Number, default: 0},
            colno: {type: String, default: ""},
            visible: {type: Boolean, default: true},
            viewonly:{type:Boolean,default:false},
            arrdate: {type: String, default:null},
            rmtype: {type: String, default: ""},
            history:{type: Boolean,default: false},
            block: {type: String, default: ""},
            deptdate: {type: String, default: null},
            ratecode: {type: String, default: ""},
            gotocol:{type: Boolean,default: false}//是否显示跳转综合预定按钮
        },
        data() {
            return {
                // sqlid: this.id,
                rsdata: {
                    adult :1,
                    anz: 1,
                    arrdate: this.arrdate,
                    block: this.block,
                    channel: '',
                    channelsource: '',
                    child: 1,
                    colno: '',
                    companyid: '',
                    comname: '',
                    deptdate: this.deptdate,
                    fixrate: false,
                    groupid: '',
                    groupname: '',
                    guestname: '',
                    guestphone: '',
                    hotelcode: '',
                    idnum: '',
                    itemid: 0,
                    kitcode: '',
                    payment: '',
                    pmscode: '',
                    policycode: '',
                    price: 0,
                    pricedetail: '',
                    profileid: '',
                    ratecode: this.ratecode,
                    regno: '',
                    remark: '',
                    rmtype: this.rmtype,
                    roomno: '',
                    shareid: '',
                    sourceid: '',
                    sourcename: '',
                    sqlid: 1,
                    status: '',
                    totalprice: 100,
                    travelid: '',
                    travelname: '',
                    restype:'',
                    market:'',
                    scode:''
                },
                // show:this.visible,
                pickerOptions: {
                    disabledDate(time) { return time.getTime() < Date.now() - 8.64e7; }
                },
                rules: {
                    guestname: [{required: true, message: '请输入客人姓名', trigger: 'change'}],
                    ratecode: [{required: true, message: '请选择价格代码', trigger: 'change'}],
                    roomtype: [{ required: true, message: '请选择房型', trigger: 'change' }],
                    arrdate: [{required: true, message: '请选择日期', trigger: 'change'}]
                },
                rmtops:{
                    value:'code',
                    label:'desc'
                },
                testrange:[],
                channels: [], hotels: [], roomtypes: [],g_hotelrmt:[],countrys:[],
                payments: [], markets: [], sources: [], blocks: [],restypes:[],subchannels:[],
                showProfileDialog: false,showLogDialog:false,showInterfaceLogDialog:false,
                showRateQuery: false, buttonLoading:false
            }
        },
        computed: {
            showTitle(){ return this.rsdata.sqlid!=null&&this.rsdata.sqlid>0?'编辑客房订单  订单号: '+this.rsdata.regno+' 综合订单号: '+this.rsdata.colno+
                  (this.rsdata.pmsno!=''?'  线下订单号: '+this.rsdata.pmsno:''):'新建客房订单'},
            showStatus(){ return this.rsdata.sqlid!=null&&this.rsdata.sqlid>0?getRoomRsStatus(this.rsdata.status):''},
            showPrice() {
                return this.rsdata.price;
            },
            dateselect:{
                get:function(){
                    return this.rsdata.arrdate!=null && this.rsdata.deptdate!=null?[this.rsdata.arrdate,this.rsdata.deptdate]:[];
                },
                set:function(dateValue){
                    this.rsdata.arrdate = dateValue!=null&&dateValue.length>0?dateValue[0]:'';
                    this.rsdata.deptdate = dateValue!=null&&dateValue.length>1?dateValue[1]:'';
                }
            },
            blockrmtypes(){
               return this.rsdata.rmtype!=null&&this.rsdata.rmtype!=''? [this.rsdata.rmtype]:[];
            },
            showDays: function(){
                let nights=stringDateDiff(this.rsdata.deptdate,this.rsdata.arrdate);
                if(nights==0){
                    nights=1;
                }
                return nights+ '晚';
            },
            createDateTime:{
              get:function(){
                return this.rsdata.createdate==null||this.rsdata.createdate=='1900-01-01 00:00:00'?this.rsdata.createuserid
                        :this.rsdata.createdate+' '+this.rsdata.createuserid
              }
            }
        },
        watch: {
            visible: function(val){
                if(val){
                    this.initDialog();
                }
            }
        },
        mounted: function () {
            select_data({"keyname": "channel,subchannel,ratecode,payment,block,g_hotelrmt,roomtype,country,restype,market,source"}).then(response => {
                this.channels = select_res(response.data,"channel");
                this.subchannels = select_res(response.data,"subchannel");
                this.ratecodes = select_res(response.data,"ratecode");
                this.payments = select_res(response.data,"payment");
                this.blocks = select_res(response.data,"block");
                this.g_hotelrmt = select_res(response.data,"g_hotelrmt");
                this.roomtypes = select_res(response.data,"roomtype");
                this.countrys=select_res(response.data,"country");

                this.restypes=select_res(response.data,"restype");
                this.markets=select_res(response.data,"market");
                this.sources=select_res(response.data,"source");

            });
            this.initDialog();

        },
        methods: {
            initDialog() {
              load_roomrs({
                "sqlid": this.roomid,
                "colno": this.colno,
                "arrdate": this.arrdate,
                "rmtype": this.rmtype,
                "history": this.history,
                "deptdate": this.deptdate,
                "ratecode": this.ratecode,
                "block": this.block
              }).then(response => {
                this.rsdata = response.data;
                }).catch(() => {
                    this.$emit('update:visible',false);
                });
            },
            checkNameExist(type){
              if(this.rsdata.profileid==''&&this.rsdata.guestname.length>0){
                name_exist({"name":this.rsdata.guestname,"type":''}).then(response => {
                  if(response.data){
                    this.showProfileDialog = true;
                  }
                })
              }
            },
            selectProfile(selectValue) {
                this.showProfileDialog = false;
                this.rsdata.profileid = selectValue.profileid;
                this.rsdata.guestname = selectValue.name;
                this.rsdata.guestphone = selectValue.mobile;
                this.rsdata.idnum = selectValue.idnum;
            },
            selectrate(selectValue) {
                // ratecode: this.tableCols[index].prop,
                //         product: row.pcode,
                this.rsdata.ratecode=selectValue.ratecode;
                this.rsdata.rmtype=selectValue.product;
                // setTimeout(this.calcprice,0);
                // this.calcprice();
                this.calcprice();
            },
            calcprice(){
                if(this.rsdata.rmtype!=''&&this.rsdata.ratecode!=''&&this.rsdata.arrdate!=''){
                    calc_price_detail({'from':this.rsdata.arrdate,'to':this.rsdata.deptdate,'product':this.rsdata.rmtype,
                        'ratecode':this.rsdata.ratecode,
                        'fixrate':this.rsdata.fixrate,
                        'price':this.rsdata.price,
                        'pmscode':this.rsdata.pmscode,'type':'R',
                        'person':this.rsdata.adult,
                        'anz':this.rsdata.anz,
                        'child':this.rsdata.child,'extrabad':this.rsdata.extrabad}).then(response => {
                        this.rsdata.price = response.data.one.toFixed(2);
                        this.rsdata.totalprice = response.data.total.toFixed(2);
                    }).catch(() => {
                        this.rsdata.price = this.rsdata.totalprice = 0;
                    })
                }else{
                    this.rsdata.price = this.rsdata.totalprice = 0;
                }

            },
            save() {
                this.$refs.form.validate((valid) => {
                    if (!valid) {
                        return false;
                    }
                    if(this.id<=0){
                        this.rsdata.colno=this.colno;  //综合预订传入的新建
                    }
                    this.buttonLoading = true;
                    save_roomrs(this.rsdata).then((response) => {
                      this.$message({ message: '保存成功!', type: 'success'});
                      this.$emit('refresh');
                      if(!this.gotocol){//如果可以跳转到综合预定,保存后界面不关
                        this.$emit('update:visible', false);
                      }else{
                        this.rsdata = response.data;
                      }
                    }).finally(()=>{
                      this.buttonLoading = false;
                    });
                })
            },
            handleCommand(command) {
              if(command==='U'){
                this.showLogDialog = true;
              }else{
                this.showInterfaceLogDialog = true;
              }
            },
            gotoCol(){
              this.$emit('update:rscolno',this.rsdata.colno);
              this.$emit('gotoCol');
            },
            handleCancel(){
              let deleteform = {sqlid: this.rsdata.sqlid};
              this.$confirm('确定取消?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
                this.buttonLoading = true;
                cancel_roomrs(deleteform).then(() => {
                  this.$message({ message: '取消成功!', type: 'success'});
                  this.$emit('refresh');
                  this.$emit('update:visible', false);
                }).finally(()=>{
                  this.buttonLoading = false;
                });
              }).catch(() => {});
            }
        }
    }
</script>

<style scoped>
    .pricelabel{
        color:#c11a25;
        font-size: 22px;
        font-weight: bolder;
    }
</style>
