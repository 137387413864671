<template>
  <div class="block-list-div">
    <el-form ref="form" :model="form" :inline="true" class="search-form">
      <div v-if="showAdvance" class="advance-div">
        <el-form-item label="渠道">
          <el-select v-model="form.channel" placeholder="请选择渠道" clearable filterable>
            <el-option v-for="item in channels" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
              <span style="float: left">{{ item.code }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="价格代码">
          <el-select v-model="form.ratecode" placeholder="请选择价格代码" clearable filterable>
            <el-option v-for="item in ratecodes" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
              <span style="float: left">{{ item.code }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态">
          <el-select v-model="form.status" placeholder="请选择状态" multiple collapse-tags>
            <el-option label="初始" value="I"/>
            <el-option label="启用" value="O"/>
            <el-option label="取消" value="C"/>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="日期">
        <el-date-picker v-model="form.date" align="right" type="date" placeholder="选择日期"
                        value-format="yyyy-MM-dd" :picker-options="pickerOptions"/>
      </el-form-item>
      <el-form-item label="锁房">
        <el-input ref="codeinput" v-model="form.code"
                  placeholder="请输入锁房代码或描述" clearable @keyup.enter.native="refreshTable"/>
      </el-form-item>
      <el-form-item label="线上">
        <el-switch v-model="form.online" active-color="#13ce66" inactive-color="#ff4949" @change="refreshTable"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="refreshTable">查询</el-button>
        <el-button :icon="showAdvance?'el-icon-caret-top':'el-icon-caret-bottom'" @click="showAdvance=!showAdvance">{{ showAdvance?'收起':'更多' }}</el-button>
        <el-button type="primary" icon="el-icon-tickets" @click="showLogDialog = true">日志</el-button>
      </el-form-item>
      <el-form-item style="float: right">
        <el-button type="primary" icon="el-icon-plus" @click="handleNew">新增</el-button>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="tableData" style="width: 100%" :row-class-name="tableRowClassName"
              max-height="500" border @sort-change="handleSortChange">
      <el-table-column prop="code" label="锁房" min-width="80" sortable="custom"/>
      <el-table-column prop="desc" label="描述" min-width="150" :show-overflow-tooltip="true"/>
      <el-table-column prop="startdate" label="开始日期" min-width="100" sortable="custom"/>
      <el-table-column prop="enddate" label="结束日期" min-width="100" sortable="custom"/>
      <el-table-column prop="channel" label="渠道" min-width="120" sortable="custom"/>
      <el-table-column prop="ratecodes" label="价格代码" min-width="150" sortable="custom"/>
      <el-table-column prop="status" label="状态" min-width="80" sortable="custom">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status==='C'? 'danger' : 'primary'" disable-transitions>{{ statusFormatter(scope.row.status) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" prop="operation" label="操作" min-width="220">
        <template slot-scope="operatescope">
          <el-button size="mini" type="primary" @click="handleEdit(operatescope.row)">编辑</el-button>
          <el-button size="mini" type="danger" :loading="operatescope.row.cancelLoading" @click="handleCancel(operatescope.row)">{{ operatescope.row.status==='C'?'恢复':'取消' }}</el-button>
          <el-button size="mini" type="primary" :disabled="operatescope.row.status!=='O'" @click="handleGrid(operatescope.row)">预留</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination :current-page="form.pages.currentpage" :page-size="form.pages.pagesize"
                   layout="sizes,total, prev, pager, next, jumper" :total="form.pages.total" style="margin-top: 10px;float: right;"
                   background @current-change="handleCurrentChange" @size-change="handleSizeChange"/>
    <!-- Dialog -->
    <el-dialog v-dialogDrag :visible.sync="showBlockDialog" :close-on-click-modal="false" append-to-body width="70%">
      <Block ref="block" :blockid="blockid" :visible.sync="showBlockDialog" @refresh="refreshTable"/>
    </el-dialog>

    <el-dialog v-dialogDrag :title="gridTitle" :visible.sync="showGridDialog" :close-on-click-modal="false" append-to-body width="90%" top="3vh">
      <Grid ref="grid" :blockid="gridid" :visible.sync="showGridDialog"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>

    <el-dialog v-dialogDrag title="查看日志" :visible.sync="showLogDialog" :close-on-click-modal="false" width="70%" top="7vh" append-to-body>
      <UserLogs ref="userlogs" :type="'BLOCK'" :showall="true" :visible.sync="showLogDialog"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
  </div>
</template>

<script>
  import {block_list, cancel_block} from '@/api/channel/block';
  import {select_data} from '@/api/common/custom-data';
  import {select_res} from "@/utils/select_data_res";
  import {getBlockStatus} from '@/utils/status-format';
  import {formatDate} from '@/utils/calcdate';
  import Block from '@/views/channel/block/block';
  import Grid from '@/views/channel/block/grid';
  import UserLogs from "@/views/common/userlogs";

  export default {
    name:'block_list',
    components: {
      Grid,Block,UserLogs
    },
    data(){
      return {
        form:{
          date:formatDate(new Date()), code:'', channel:'', ratecode:'', status:['I','O'], online:true,
          pages:{total:0, currentpage:1, pagesize:20, sortname:'startdate', sortorder:'ascending'}
        },
        pickerOptions: {
          shortcuts: [{ text: '今天', onClick(picker) { picker.$emit('pick', new Date()); } }]
        },
        loading:false,tableData:[],showAdvance:false,channels:[],ratecodes:[],
        gridid:0,gridTitle:'',showGridDialog:false,blockid:0,showBlockDialog:false,showLogDialog:false
      }
    },
    mounted: function(){
      this.$refs.codeinput.focus();
      select_data({"keyname":"channel,ratecode"}).then(response => {
        this.channels = select_res(response.data,"channel");
        this.ratecodes = select_res(response.data,"ratecode");
      });
      this.loadTable();
    },
    methods:{
      statusFormatter(val){
        return getBlockStatus(val);
      },
      refreshTable(){
        this.form.pages.currentpage = 1;
        this.loadTable();
      },
      loadTable(){
        this.loading = true;
        block_list(this.form).then(response => {
          this.tableData = response.data.records;
          this.form.pages.total = response.data.total;
        }).finally(() => { this.loading = false;})
      },
      tableRowClassName({row}){
        if(row.status==='C'){
          return 'cancel-row';
        }
        return null;
      },
      handleSortChange(column){
        this.form.pages.sortname = column.prop;
        this.form.pages.sortorder = column.order;
        this.loadTable();
      },
      handleCurrentChange(val){
        this.form.pages.currentpage = val;
        this.loadTable();
      },
      handleSizeChange(val){
        this.form.pages.pagesize = val;
        this.refreshTable();
      },
      handleNew() {
        this.blockid = 0;
        this.showBlockDialog = true;
      },
      handleEdit(row) {
        this.blockid = row.sqlid;
        this.showBlockDialog = true;
      },
      handleCancel(row){
        let cancel = row.status!=='C';
        this.$confirm('确定'+(cancel?'取消':'恢复')+'?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
          this.$set(row, 'cancelLoading', true);
          cancel_block({"sqlid":row.sqlid, "status":cancel?'C':'I'}).then(() => {
            this.$message({ message: (cancel?'取消':'恢复')+'成功!', type: 'success'});
            this.refreshTable();
          }).finally(()=>{
            this.$set(row, 'cancelLoading', false);
          });
        }).catch(() => {});
      },
      handleGrid(row){
        this.gridid = row.sqlid;
        this.gridTitle = '渠道 '+row.desc+' 预留';
        this.showGridDialog = true;
      }
    }
  }
</script>

<style>
  .el-table .cancel-row {
    background: #FFEBEE;
  }
</style>
