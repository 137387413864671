<template>
  <div class="cateringdetaildiv">
    <el-container>
      <el-header height="40px">{{ showTitle }}<span style="float:right">{{ showStatus }}</span></el-header>
      <el-main>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px" class="base-form">
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="联系人" prop="name">
                <el-input ref="nameinput" v-model="form.name" maxlength="60" @blur="checkNameExist">
                  <i slot="suffix" class="el-input__icon el-icon-s-grid" @click="showProfileDialog=true"/>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="联系电话" prop="telephone">
                <el-input ref="telinput" v-model="form.telephone" maxlength="20" clearable/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="渠道" prop="subchannel">
                <el-select v-model="form.subchannel" placeholder="请选择渠道" filterable @change="getPrice">
                  <el-option v-for="item in subchannels" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="餐厅" prop="restaurant">
                <el-select v-model="form.restaurant" placeholder="请选择餐厅" filterable @change="changeRestaurant">
                  <el-option v-for="item in restaurants" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="餐段" prop="time">
                <el-select v-model="form.time" placeholder="请选择餐段" filterable>
                  <el-option v-for="item in times" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="餐标">
                <el-select v-model="form.tisch" placeholder="请选择餐标" filterable @change="getPrice">
                  <el-option v-for="item in tisches" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="就餐日期" prop="date">
                <el-date-picker v-model="form.date" align="right" type="date" :picker-options="pickerOptions"
                                :clearable="false" value-format="yyyy-MM-dd" @change="getPrice"/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="餐桌/包厢">
                <el-select v-model="tableboxSelect" placeholder="请选择餐桌/包厢" filterable clearable multiple collapse-tags @change="changeTables">
                  <el-option v-for="item in boxes" :key="item.code" :label="item.code" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="锁房">
                <el-select v-model="form.block" placeholder="请选择锁房" filterable>
                  <el-option v-for="item in blocks" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="价格代码">
                <el-select v-model="form.ratecode" placeholder="请选择价格代码" filterable @change="getPrice">
                  <el-option v-for="item in ratecodes" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="付款方式">
                <el-select v-model="form.payment" placeholder="请选择付款方式" filterable>
                  <el-option v-for="item in payments" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="套餐代码">
                <el-input v-model="form.kitcode" readonly/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="价格">
                <el-input v-model="form.price" :readonly="!form.fix" @change="changeSinglePrice">
                  <template slot="append">
                    <el-select v-model="form.type" style="width: 90%" @change="changeType">
                      <el-option value="T" label="/桌">/桌</el-option>
                      <el-option value="P" label="/人">/人</el-option>
                    </el-select>
                    <el-checkbox v-model="form.fix" @change="getPrice">固定价&nbsp;&nbsp;</el-checkbox>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item v-if="form.type=='P'" label="人数" prop="persons">
                <el-input-number v-model="form.persons" :min="1" @change="changeNum"/>
                <label style="margin-left: 20px; font-weight: bold">总价: </label>
                <span class="pricelabel">{{ form.totalprice }}</span>
              </el-form-item>
              <el-form-item v-else label="桌数" prop="occtables">
                <el-input-number v-model="form.occtables" :min="1" @change="changeNum"/>
                <label style="margin-left: 20px; font-weight: bold">总价: </label>
                <span class="pricelabel">{{ form.totalprice }}</span>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="创建信息">
                <el-input v-model="createDateTime" readonly/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="24" :sm="24" :xs="24">
              <el-form-item label="备注">
                <el-input v-model="form.info" type="textarea" :rows="3" placeholder="请输入备注内容"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
      <el-footer height="40px">
        <el-button type="primary" icon="el-icon-edit-outline" :disabled="form.status!='N'||dateIsExpire||history"
                   :loading="buttonLoading" @click="handleSave">保存</el-button>
        <el-button type="danger" icon="el-icon-warning-outline" :disabled="form.sqlid<=0||form.status=='C'||history"
                   :loading="buttonLoading" @click="handleCancel">取消</el-button>
        <el-button type="primary" icon="el-icon-s-grid" :disabled="form.status!='N'||history" @click="showCaterResource=true">餐桌资源</el-button>
        <el-button v-if="gotocol" type="primary" icon="el-icon-refresh-left" :disabled="form.sqlid<=0||form.colno==''"
                   @click="gotoCol">综合预定</el-button>
        <!--
        <el-button type="primary" icon="el-icon-refresh" :disabled="form.sqlid<=0" @click="showInterfaceDialog=true">接口</el-button>
        -->
        <el-button type="primary" icon="el-icon-tickets" @click="showLogDialog = true">日志</el-button>
      </el-footer>
    </el-container>
    <!-- 窗口Dialog -->
    <el-dialog v-dialogDrag title="选择档案" :visible.sync="showProfileDialog" :close-on-click-modal="false" width="85%" append-to-body>
      <ProfileSelect :profileid="form.profileid" :name="form.name" :telno="form.telephone" :type="['']" :visible="showProfileDialog" @selectValue="selectProfile"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <el-dialog v-dialogDrag title="查看日志" :visible.sync="showLogDialog" :close-on-click-modal="false" width="70%" append-to-body>
      <UserLogs ref="userlogs" :type="'CATERING'" :bookingid="form.bookingid" :visible.sync="showLogDialog"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <cti-cater v-if="showCaterResource" :show.sync="showCaterResource" :arrdate="resDate" :restaurant="form.restaurant" :time="form.time" @selected="handleSelectResource"/>
  </div>
</template>

<script>
    import {load_cateringrs, save_cateringrs, cancel_cateringrs} from '@/api/order/catering';
    import {name_exist} from '@/api/profile/profile';
    import {select_data,calc_price} from '@/api/common/custom-data';
    import {select_res, filterValue, filterValueArray} from "@/utils/select_data_res";
    import {getCateringStatus} from '@/utils/status-format';
    import {dateIsExpire, stringToDate, formatDate} from '@/utils/calcdate';
    import {arrayToString, stringToArray} from '@/utils/string-util';
    import '@/styles/pagecontainer.scss';
    import ProfileSelect from "@/views/common/profile-select";
    import UserLogs from "@/views/common/userlogs";
    import CtiCater from '@/views/order/cti/cti-cater';

    export default {
        name: "CateringDetail",
        components: {ProfileSelect,UserLogs,CtiCater},
        props: {
            colno:{ type: String,default: ''},
            cateringid:{ type: Number,default: 0},
            history:{type: Boolean,default: false},
            visible:{type: Boolean,default: true},
            gotocol:{type: Boolean,default: false}//是否显示跳转综合预定按钮
        },
        data() {
            return {
                form:{
                  date: formatDate(new Date()),restaurant:'',time:'',name:'',telephone:''
                },
                pickerOptions: {
                    disabledDate(time) { return time.getTime() < Date.now() - 8.64e7; },//不能选之前的日期
                    shortcuts: [{ text: '今天', onClick(picker) { picker.$emit('pick', new Date()); } }]
                },
                rules: {
                    name: [{ required: true, message: '请输入联系人姓名', trigger: 'change' }],
                    telephone: [{ required: true, message: '请输入联系电话', trigger: 'change' }],
                    restaurant: [{ required: true, message: '请选择餐厅', trigger: 'change' }],
                    // tisch: [{ required: true, message: '请选择餐标', trigger: 'change' }],
                    time: [{ required: true, message: '请选择餐段', trigger: 'change' }],
                    // block: [{ required: true, message: '请选择锁房', trigger: 'change' }],
                    // channel: [{ required: true, message: '请选择渠道', trigger: 'change' }],
                    subchannel: [{ required: true, message: '请选择渠道', trigger: 'change' }],
                    date: [{ required: true, message: '请选择用餐日期', trigger: 'change' }],
                    // tables: [{ required: true, message: '请选择正确的份数', trigger: 'change' }],
                    occtables: [{ required: true, message: '请选择正确的桌数', trigger: 'change' }],
                    persons: [{ required: true, message: '请选择正确的人数', trigger: 'change' }]
                    // ratecode: [{ required: true, message: '请选择价格代码', trigger: 'change' }]
                },
                showProfileDialog:false,showLogDialog:false,showInterfaceDialog:false,showCaterResource:false,
                restaurants:[],tisches:[],blocks:[],subchannels:[],payments:[],
                ratecodes:[],times:[],boxes:[], nums:1, buttonLoading:false
            }
        },
        computed:{
            showTitle(){ return this.form.sqlid!=null&&this.form.sqlid>0?'编辑餐饮订单  订单号: '+this.form.bookingid+' 综合订单号: '+this.form.colno
                    +(this.form.interfaceid!=''?'  线下订单号: '+this.form.interfaceid:''):'新建餐饮订单'},
            showStatus(){ return this.form.sqlid!=null&&this.form.sqlid>0?getCateringStatus(this.form.status):''},
            // createinfo(){ return this.form.createdate==null||this.form.createdate=='1900-01-01'?'':this.form.createdate+'  '+this.form.createtime+'  '+this.form.creator},
            // modifyinfo(){ return this.form.modifydate==null||this.form.modifydate=='1900-01-01'?'':this.form.modifydate+'  '+this.form.modifytime+'  '+this.form.modify},
            dateIsExpire() { return dateIsExpire(this.form.date) },
            tableboxSelect:{
              get:function(){ return stringToArray(this.form.tablebox,','); },
              set:function(tableboxValue){ this.form.tablebox = arrayToString(tableboxValue,',');}
            },
            createDateTime:{
              get:function(){
                return this.form.createdate==null||this.form.createdate=='1900-01-01'?this.form.creator
                        :this.form.createdate+' '+this.form.createtime+' '+this.form.creator;
              }
            },
            resDate() { return stringToDate(this.form.date) }
        },
        watch:{
            visible: function(val){
              if(val){
                this.initDialog();
              }
            }
        },
        mounted: function(){
            this.$refs.nameinput.focus();
            select_data({"keyname":"subchannel,payment,tisch,restaurant,ratecode,block,restaurant_time,cater_table"}).then(response => {
                this.subchannels = select_res(response.data,"subchannel");
                this.payments = select_res(response.data,"payment");
                this.restaurants = select_res(response.data,"restaurant");
                this.ratecodes = select_res(response.data,"ratecode");
                this.blocks = select_res(response.data,"block");
                this.times = select_res(response.data,"restaurant_time");
            });
            this.initDialog();
        },
        methods:{
            initDialog(){
                load_cateringrs({"sqlid":this.cateringid,"colno":this.colno,"history":this.history}).then(response => {
                    this.form = response.data;
                    if(this.form.restaurant.length>0){
                      this.changeRestaurant(this.form.restaurant);
                    }else{
                      this.tisches = this.boxes = [];
                    }
                }).catch(() => {
                    this.$emit('update:visible',false);
                })
            },
            changeRestaurant(val){
                select_data({"keyname":"tisch,cater_table","params":[{'key':'tisch','value':val},{'key':'cater_table','value':val}]}).then(response => {
                    this.tisches = select_res(response.data,"tisch");
                    this.boxes = select_res(response.data,"cater_table");
                    this.form.tisch = filterValue(this.tisches,this.form.tisch);
                    this.form.tablebox = filterValueArray(this.boxes,this.form.tablebox);
                })
            },
            checkNameExist(){
              if(this.form.profileid==''&&this.form.name.length>0){
                name_exist({"name":this.form.name}).then(response => {
                  if(response.data){
                    this.showProfileDialog = true;
                  }
                })
              }
            },
            selectProfile(selectValue){
                this.showProfileDialog = false;
                this.form.profileid = selectValue.profileid;
                this.form.name = selectValue.name;
                this.form.telephone = selectValue.mobile;
                this.form.idnumber = selectValue.idnum;
            },
            changeNum(val){
                this.form.totalprice = (val*this.form.price).toFixed(2);
                this.nums = val;
            },
            changeSinglePrice(val){
              if(!isNaN(val)){
                this.form.totalprice = (val*this.nums).toFixed(2);
              }else{
                this.form.totalprice = 0;
              }
            },
            changeType(val){
                if(val=='T'){
                  this.form.persons = 1;
                }else{
                  this.form.occtables = 1;
                }
                this.changeNum(1);
            },
            changeTables(){
                let tableBoxLen = this.tableboxSelect.length;
                if(this.form.type=='T'){
                  this.form.occtables = tableBoxLen<=1?1:tableBoxLen;
                  this.changeNum(this.form.occtables);
                }
            },
            getPrice(){
              if(!this.form.fix){
                if((this.form.tisch!==''||this.form.restaurant!=='')&&this.form.ratecode!==''&&this.form.date!==''){
                  calc_price({'from':this.form.date,'to':this.form.date,'product':this.form.tisch!==''?'@@'+this.form.tisch:this.form.restaurant,
                    'ratecode':this.form.ratecode,'type':'C','channelcode':this.form.channel,'channelid':this.form.subchannel}).then(response => {
                    this.form.price = response.data;
                    this.form.totalprice = this.nums*this.form.price;
                  }).catch(() => {
                    this.form.price = this.form.totalprice = 0;
                  })
                }else{
                  this.form.price = this.form.totalprice = 0;
                }
              }
            },
            handleSave(){
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.buttonLoading = true;
                        save_cateringrs(this.form).then(response => {
                            this.$msgbox({ title: '消息', message: '保存成功! 订单号:'+response.data.bookingid, confirmButtonText: '关闭'}).catch(() => {});
                            this.$emit('refresh');
                            if(!this.gotocol){//如果可以跳转到综合预定,保存后界面不关
                              this.$emit('update:visible', false);
                            }else{
                              this.form = response.data;
                            }
                        }).finally(()=>{
                          this.buttonLoading = false;
                        });
                    } else {
                        return false;
                    }
                });
            },
            handleCancel(){
                this.$confirm('确定取消?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
                    this.buttonLoading = true;
                    cancel_cateringrs({"sqlid":this.form.sqlid}).then(() => {
                        this.$message({ message: '取消成功!', type: 'success'});
                        this.$emit('refresh');
                        this.$emit('update:visible',false);
                    }).finally(()=>{
                      this.buttonLoading = false;
                    });
                }).catch(() => {});
            },
            handleSelectResource(selectValue){
                if(selectValue.restaurant.length>0){
                  this.form.date = selectValue.date;
                  this.form.time = selectValue.time;
                  this.form.tablebox = selectValue.tablebox;
                  this.changeTables();
                  if(this.form.restaurant!=selectValue.restaurant){
                    this.form.restaurant = selectValue.restaurant;
                    this.changeRestaurant(this.form.restaurant);
                  }
                }
            },
            gotoCol(){
              this.$emit('update:rscolno',this.form.colno);
              this.$emit('gotoCol');
            }
        }
    }
</script>

<style>
  .pricelabel{
    color:#c11a25;
    font-size: 22px;
    font-weight: bolder;
  }
</style>