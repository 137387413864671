import request from '@/utils/request'

export function grid_list(data) {
  return request({
    url: '/api/grid/grid_list',
    method: 'post',
    data
  })
}

export function setup_alotm(data) {
  return request({
    url: '/api/grid/setup_alotm',
    method: 'post',
    data
  })
}

export function delete_alotm(data) {
  return request({
    url: '/api/grid/delete_alotm',
    method: 'post',
    data
  })
}

export function cutoff_alotm(data) {
  return request({
    url: '/api/grid/cutoff_alotm',
    method: 'post',
    data
  })
}

export function sync_alotm(data) {
  return request({
    url: '/api/grid/sync_alotm',
    method: 'post',
    data
  })
}

export function sync_list(data) {
  return request({
    url: '/api/grid/sync_list',
    method: 'post',
    data
  })
}

export function reset_list(data) {
  return request({
    url: '/api/grid/reset_list',
    method: 'post',
    data
  })
}

export function reset_grid(data) {
  return request({
    url: '/api/grid/reset_grid',
    method: 'post',
    data
  })
}

export function notify_price(data) {
  return request({
    url: '/api/grid/notify_price',
    method: 'post',
    data
  })
}

export function notify_rooms(data) {
  return request({
    url: '/api/grid/notify_rooms',
    method: 'post',
    data
  })
}
