import request from '@/utils/request'

export function profile_list(data) {
    return request({
        url: '/api/profile/profile_list',
        method: 'post',
        data
    })
}

export function load_profile(data) {
    return request({
        url: '/api/profile/load_profile',
        method: 'post',
        data
    })
}

export function save_profile(data) {
    return request({
        url: '/api/profile/save_profile',
        method: 'post',
        data
    })
}

export function delete_profile(data) {
    return request({
        url: '/api/profile/delete_profile',
        method: 'post',
        data
    })
}

export function merge_profile(data) {
    return request({
        url: '/api/profile/merge_profile',
        method: 'post',
        data
    })
}

export function statistic_list(data) {
    return request({
        url: '/api/profile/statistic_list',
        method: 'post',
        data
    })
}

export function select_profile(data) {
    return request({
        url: '/api/profile/select_profile',
        method: 'post',
        data
    })
}

export function change_profile(data) {
    return request({
        url: '/api/profile/change_profile',
        method: 'post',
        data
    })
}

export function name_exist(data) {
    return request({
        url: '/api/profile/name_exist',
        method: 'post',
        data
    })
}