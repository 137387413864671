<template>
  <div>
    <el-input ref="nameinput" v-model="ratecodeValue" type="text" maxlength="60" readonly>
      <i slot="suffix" class="el-input__icon el-icon-s-grid" @click="opendialog"/>
    </el-input>


    <zj-ratequery :calcform="searchForm" :show.sync="showRateQuery" @selectRate="onSelectRate"/>
  </div>
</template>

<script>
    import ZjRatequery from "@/views/order/common/ZjRatequery";

    export default {
        name: "ZjRatecodeselect",
        components:{ZjRatequery},
        props:{
            calcform:{ //初始化计算的对象  外层要用这个对象
                type:Object,
                default:function(){
                    return {
                        block:'',
                        productgroup:'SZ',
                        product:'',
                        type:'R',
                        arrdate:'2020-01-08',
                        deptdate:'2020-01-10',
                        rangetype:1,
                        reqnum:1,
                        pricefrom:0,
                        priceto:9999,
                        policy:'',
                        person:1,
                        child:0,
                        extrabad:0,
                        pkgs:''
                    }
                }
            },
            value: {
                type: [String, Number],
                required: true
            }
        },
        data(){
            return {
                searchForm:this.calcform,
                showDialog:false,
                showRateQuery:false,
                ratecodeValue:this.value
            }
        },
        watch:{
            value(newVal) {
                this.ratecodeValue = newVal
            },
            //监听myValue，如果子组件中的内容变化了，通知父级组件，将新的值告诉父级组件，我更新了，父级组件接受到值后页就跟着变了
            //参考官网：https://cn.vuejs.org/v2/guide/components-custom-events.html#%E8%87%AA%E5%AE%9A%E4%B9%89%E7%BB%84%E4%BB%B6%E7%9A%84-v-model
            ratecodeValue(newVal) {
                // this.$emit('input', newVal)
                this.$emit("input", this.ratecodeValue);
            },
            calcform(){
                this.searchForm=this.calcform;
            }
        },
        methods:{
            opendialog:function(){
                // if(this.showRateQuery==undefined){
                //     this.showRateQuery=true
                // }
                this.searchForm.product=this.calcform.rmtype;
                this.showRateQuery=true
            },
            onSelectRate(selects){
                if(selects.ratecode!=null){
                    this.ratecodeValue=selects.ratecode;  //选择的内容更新到文本区域
                    this.$emit('selectrate',selects);
                }
            },
            clean(){
                this.ratecodeValue='';
            }
        }
    }
</script>

<style scoped>

</style>
