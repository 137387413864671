import request from '@/utils/request'

export function col_list(data) {
    return request({
        url: '/api/col/col_list',
        method: 'post',
        data
    })
}

export function load_col(data) {
    return request({
        url: '/api/col/load_col',
        method: 'post',
        data
    })
}

export function save_col(data) {
    return request({
        url: '/api/col/save_col',
        method: 'post',
        data
    })
}

export function cancel_col(data) {
    return request({
        url: '/api/col/cancel_col',
        method: 'post',
        data
    })
}

export function finish_col(data) {
    return request({
        url: '/api/col/finish_col',
        method: 'post',
        data
    })
}

export function refresh_col(data) {
    return request({
        url: '/api/col/refresh_col',
        method: 'post',
        data
    })
}

export function resend_col(data) {
    return request({
        url: '/api/col/resend_col',
        method: 'post',
        data
    })
}

export function resend_crm(data) {
    return request({
        url: '/api/col/resend_crm',
        method: 'post',
        data
    })
}

export function resend_status(data) {
    return request({
        url: '/api/col/resend_status',
        method: 'post',
        data
    })
}

export function load_account(data) {
    return request({
        url: '/api/col/load_account',
        method: 'post',
        data
    })
}