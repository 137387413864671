<template>
  <div class="main-div">
    <el-container>
      <el-header height="40px">{{ showTitle }}<span style="float:right">{{ showStatus }}</span></el-header>
      <el-main>
        <el-form ref="form" :model="form" :rules="rules" label-width="80px" class="base-form">
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="联系人" prop="name">
                <el-input ref="nameinput" v-model="form.name" maxlength="60" @blur="checkNameExist">
                  <i slot="suffix" class="el-input__icon el-icon-s-grid" @click="showProfileDialog=true"/>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="电话号码" prop="telephone">
                <el-input ref="telinput" v-model="form.telephone" maxlength="20" clearable/>
              </el-form-item>
            </el-col>
            <!--
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="证件号码">
                <el-input v-model="form.idnumber" maxlength="60" clearable/>
              </el-form-item>
            </el-col>
            -->
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="渠道" prop="subchannel">
                <el-select v-model="form.subchannel" placeholder="请选择渠道" filterable>
                  <el-option v-for="item in subchannels" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="接团日期" prop="arrival">
                <el-date-picker v-model="form.arrival" align="right" type="date" :picker-options="pickerOptions"
                                :clearable="false" value-format="yyyy-MM-dd"/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="接团时间">
                <el-time-select v-model="form.arrivaltime" :picker-options="{ start: '00:00', step: '00:01', end: '23:59' }"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="送团日期" prop="departure">
                <el-date-picker v-model="form.departure" align="right" type="date" :picker-options="pickerOptions"
                                :clearable="false" value-format="yyyy-MM-dd"/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="送团时间">
                <el-time-select v-model="form.departuretime" :picker-options="{ start: '00:00', step: '00:01', end: '23:59' }"/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="付款方式">
                <el-select v-model="form.payment" placeholder="请选择付款方式" filterable>
                  <el-option v-for="item in payments" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="16" :sm="16" :xs="24">
              <el-form-item label="导游" prop="guides">
                <el-input v-model="form.guides" maxlength="200" readonly>
                  <i slot="suffix" class="el-input__icon el-icon-s-grid" @click="showGuidesDialog=true"/>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="价格">
                <el-input v-model="form.totalprice" :readonly="!form.fix">
                  <template slot="append">
                    <el-checkbox v-model="form.fix" @change="fixChange">固定价</el-checkbox>
                  </template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="导游数">
                <el-input-number v-model="form.num" :min="0"/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="人数">
                <el-input-number v-model="form.person" :min="0"/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="总价">
                <span class="pricelabel">{{ form.totalprice }}</span>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="导游等级">
                <el-select v-model="form.level" placeholder="请选择等级" clearable>
                  <el-option label="初级" value="L"/>
                  <el-option label="高级" value="H"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="导游语言">
                <el-select v-model="form.language" placeholder="请选择语言" clearable>
                  <el-option label="国语" value="C"/>
                  <el-option label="英语" value="E"/>
                  <el-option label="日语" value="J"/>
                  <el-option label="韩语" value="K"/>
                  <el-option label="法语" value="F"/>
                  <el-option label="其它" value="O"/>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="团队类型">
                <el-select v-model="form.gtype" placeholder="请选择类型" clearable>
                  <el-option v-for="item in guideTypes" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="市场">
                <el-select v-model="form.market" placeholder="请选择市场" clearable filterable>
                  <el-option v-for="item in markets" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="来源">
                <el-select v-model="form.scode" placeholder="请选择来源" clearable filterable>
                  <el-option v-for="item in scodes" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="8" :xs="24">
              <el-form-item label="创建信息">
                <el-input v-model="createDateTime" readonly/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :md="24" :sm="24" :xs="24">
              <el-form-item label="备注">
                <el-input v-model="form.info" type="textarea" :rows="3" placeholder="请输入备注内容"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
      <el-footer height="40px">
        <el-button type="primary" icon="el-icon-edit-outline" :loading="buttonLoading" :disabled="form.status!='N'||history" @click="handleSave">保存</el-button>
        <el-button type="danger" icon="el-icon-warning-outline" :loading="buttonLoading"
                   :disabled="form.sqlid<=0||(form.status!='N'&&form.status!='P')||history" @click="handleCancel">取消</el-button>
        <el-button v-if="gotocol" type="primary" icon="el-icon-refresh-left" :disabled="form.sqlid<=0||form.colno==''"
                   @click="gotoCol">综合预定</el-button>
      </el-footer>
    </el-container>
    <!-- 窗口Dialog -->
    <el-dialog v-dialogDrag title="选择档案" :visible.sync="showProfileDialog" :close-on-click-modal="false" width="85%" append-to-body>
      <ProfileSelect :profileid="form.profileid" :name="form.name" :telno="form.telephone" :type="['']" :visible="showProfileDialog" @selectValue="selectProfile"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>

    <el-dialog v-dialogDrag title="选择导游" :visible.sync="showGuidesDialog" :close-on-click-modal="false" width="70%"
               append-to-body @opened="openGuidesDialog">
      <el-form :inline="true" :model="guideTicketSearchForm" class="search-guide-form">
        <el-form-item>
          <el-input ref="guideinput" v-model="guideTicketSearchForm.code" placeholder="请输入代码或描述" clearable @keyup.enter.native="refreshGuideTicketTable"/>
        </el-form-item>
        <el-form-item>
          <el-select v-model="guideTicketSearchForm.language" placeholder="请选择语言" clearable>
            <el-option label="国语" value="C"/>
            <el-option label="英语" value="E"/>
            <el-option label="日语" value="J"/>
            <el-option label="韩语" value="K"/>
            <el-option label="法语" value="F"/>
            <el-option label="其它" value="O"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="guideTicketSearchForm.level" placeholder="请选择等级" clearable>
            <el-option label="初级" value="L"/>
            <el-option label="高级" value="H"/>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-select v-model="guideTicketSearchForm.type" placeholder="请选择类型" clearable>
            <el-option v-for="item in guideTypes" :key="item.code" :label="item.desc" :value="item.code">
              <span style="float: left">{{ item.code }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="refreshGuideTicketTable">查询</el-button>
        </el-form-item>
      </el-form>
      <el-table ref="multipleTable" v-loading="guideTicketLoading" :data="guideData"
                style="width: 100%" max-height="300" border @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"/>
        <el-table-column prop="code" label="导游编号" min-width="80"/>
        <el-table-column prop="desc" label="导游姓名" min-width="150"/>
        <el-table-column prop="language" label="语言" min-width="80" :formatter="languageFormatter"/>
        <el-table-column prop="level" label="等级" min-width="80" :formatter="levelFormatter"/>
        <el-table-column prop="sex" label="性别" min-width="80" :formatter="sexFormatter"/>
        <el-table-column prop="type" label="类型" min-width="80" :formatter="typeFormatter"/>
        <el-table-column prop="price" label="价格" min-width="80"/>
        <!-- <el-table-column prop="stock" label="库存" min-width="80"/> -->
      </el-table>
      <el-pagination :current-page="guideTicketSearchForm.pages.currentpage" :page-size="guideTicketSearchForm.pages.pagesize"
                     layout="total, prev, pager, next, jumper" :total="guideTicketSearchForm.pages.total" style="margin-top: 10px;float: right;" background
                     @current-change="handleCurrentChange"/>
      <div style="margin-top: 10px">
        <el-button type="primary" @click="selectGuides">选择</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import {load_guides,save_guides,cancel_guides} from '@/api/order/guide';
import {guide_list} from '@/api/conf/guidesetup';
import {name_exist} from '@/api/profile/profile';
import {select_data} from '@/api/common/custom-data';
import {getTicketStatus} from '@/utils/status-format';
import {select_res} from "@/utils/select_data_res";
import '@/styles/pagecontainer.scss';
import ProfileSelect from "@/views/common/profile-select";

export default {
  name:'Guidedetail',
  components: {ProfileSelect},
  props: {
    colno:{ type: String,default: ''},
    guideid:{ type: Number,default: 0},
    history:{type: Boolean,default: false},
    visible:{type: Boolean,default: true},
    gotocol:{type: Boolean,default: false}//是否显示跳转综合预定按钮
  },
  data() {
    return {
      form:{},
      pickerOptions: {
        disabledDate(time) { return time.getTime() < Date.now() - 8.64e7; },//不能选之前的日期
        shortcuts: [{ text: '今天', onClick(picker) { picker.$emit('pick', new Date()); } }]
      },
      rules: {
        name: [{ required: true, message: '请输入联系人姓名', trigger: 'change' }],
        telephone: [{ required: true, message: '请输入电话号码', trigger: 'change' }],
        subchannel: [{ required: true, message: '请选择渠道', trigger: 'change' }],
        arrival: [{ required: true, message: '请选择接团日期', trigger: 'change' }],
        departure: [{ required: true, message: '请选择送团日期', trigger: 'change' }],
        guides: [{ required: true, message: '请选择导游', trigger: 'change' }]
      },
      subchannels:[],payments:[],
      markets:[],scodes:[],buttonLoading:false,showProfileDialog:false,calcPrice:0,
      showGuidesDialog:false,guideTicketLoading:false,guideData:[],guideTypes:[],multipleSelection:[],
      guideTicketSearchForm: {
        code:'',language:'',level:'',type:'',
        pages:{total:0, currentpage:1, pagesize:20,sortname:'level,code', sortorder:'ascending'}
      }
    }
  },
  computed:{
    showTitle(){ return this.form.sqlid!=null&&this.form.sqlid>0?'编辑导游订单  订单号: '+this.form.bookingid+' 综合订单号: '+this.form.colno+
            (this.form.interfaceid!=''?'  线下订单号: '+this.form.interfaceid:''):'新建导游订单'},
    showStatus(){ return this.form.sqlid!=null&&this.form.sqlid>0?getTicketStatus(this.form.status):''},
    createDateTime:{
      get:function(){
        return this.form.createdate==null||this.form.createdate=='1900-01-01'?this.form.creator
                :this.form.createdate+' '+this.form.createtime+ ' '+this.form.creator;
      }
    }
  },
  watch:{
    visible: function(val){
      if(val){
        this.initDialog();
      }
    }
  },
  mounted: function(){
    this.$refs.nameinput.focus();
    select_data({"keyname":"subchannel,payment,guidetyp,market,source"}).then(response => {
      this.subchannels = select_res(response.data,"subchannel");
      this.payments = select_res(response.data,"payment");
      this.guideTypes = select_res(response.data,"guidetyp");
      this.markets = select_res(response.data,"market");
      this.scodes = select_res(response.data,"source");
    });
    this.initDialog();
  },
  methods: {
    initDialog(){
      load_guides({"sqlid":this.guideid,"colno":this.colno,"history":this.history}).then(response => {
        this.form = response.data;
        this.calcPrice = this.form.totalprice;
      }).catch(() => {
        this.$emit('update:visible',false);
      })
    },
    checkNameExist(){
      if(this.form.profileid==''&&this.form.name.length>0){
        name_exist({"name":this.form.name}).then(response => {
          if(response.data){
            this.showProfileDialog = true;
          }
        })
      }
    },
    languageFormatter(row){
      let newLanuage = '';
      row.language.split(",").forEach(function(language){
        if(language=='C'){
          newLanuage += ',国语';
        }else if(language=='E'){
          newLanuage += ',英语';
        }else if(language=='J'){
          newLanuage += ',日语';
        }else if(language=='K'){
          newLanuage += ',韩语';
        }else if(language=='F'){
          newLanuage += ',法语';
        }else if(language=='O'){
          newLanuage += ',其它';
        }
      });
      return newLanuage.length>0?newLanuage.substr(1):'国语';
    },
    levelFormatter(row){
      if(row.level=='L'){
        return '初级';
      }else if(row.level=='H'){
        return '高级';
      }
      return row.level;
    },
    sexFormatter(row){
      if(row.sex=='M'){
        return '男';
      }else if(row.sex=='F'){
        return '女';
      }
      return '';
    },
    typeFormatter(row){
      for(let i=0; i<this.guideTypes.length; i++){
        if(this.guideTypes[i].code==row.type){
          return this.guideTypes[i].desc;
        }
      }
      return row.type;
    },
    refreshGuideTicketTable(){
      this.guideTicketSearchForm.pages.currentpage = 1;
      this.loadGuideTicketTable();
    },
    loadGuideTicketTable(){
      this.guideTicketLoading = true;
      guide_list(this.guideTicketSearchForm).then(response => {
        this.guideData = response.data.records;
        this.guideTicketSearchForm.pages.total = response.data.total;
        //选中之前选的记录
        let selectCodes = this.form.guides;
        let tableData = this.guideData;
        if(selectCodes!=null && selectCodes.length>0 && tableData!=null && tableData.length>0){
          let table = this.$refs.multipleTable;
          this.$nextTick(() => {
              tableData.forEach(function(row){
                table.toggleRowSelection(row,selectCodes.indexOf(row.code)>=0);
              });
          });
        }
      }).finally(() => { this.guideTicketLoading = false;})
    },
    handleCurrentChange(val){
      this.guideTicketSearchForm.pages.currentpage = val;
      this.loadGuideTicketTable();
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    openGuidesDialog(){
      this.$refs.guideinput.focus();
      this.guideTicketSearchForm.code = this.form.guides;
      if(this.guideTicketSearchForm.code.length<=0){ //如果code没有,就按其它条件搜索
        this.guideTicketSearchForm.language = this.form.language;
        this.guideTicketSearchForm.level = this.form.level;
        this.guideTicketSearchForm.type = this.form.gtype;
      }
      this.refreshGuideTicketTable();
    },
    fixChange(){
      if(!this.form.fix){
        this.form.totalprice = this.calcPrice;
      }
    },
    selectGuides(){
      this.showGuidesDialog = false;
      let guides = '';
      let price = 0.00;
      let len = this.multipleSelection.length;
      let level = '';
      let language = '';
      let type = '';
      if(len>0){
        this.multipleSelection.forEach(function(val){
          guides += ','+val.code;
          price += val.price;
          if(level.length<=0){
            level = val.level;
          }
          if(language.length<=0){
            language = val.language;
          }
          if(type.length<=0){
            type = val.type;
          }
        });
      }
      this.form.guides = guides.length>0?guides.substr(1):guides;
      this.form.num = len;
      this.calcPrice = price;
      if(!this.form.fix){
        this.form.totalprice = price;
      }
      if(this.form.level.length<=0){
        this.form.level = level;
      }
      if(this.form.language.length<=0){
        this.form.language = language;
      }
      if(this.form.gtype.length<=0){
        this.form.gtype = type;
      }
    },
    selectProfile(selectValue){
      this.showProfileDialog = false;
      this.form.profileid = selectValue.profileid;
      this.form.name = selectValue.name;
      this.form.telephone = selectValue.mobile;
      this.form.idnumber = selectValue.idnum;
    },
    handleSave(){
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.buttonLoading = true;
          save_guides(this.form).then(response => {
            this.$msgbox({ title: '消息', message: '保存成功! 订单号:'+response.data.bookingid, confirmButtonText: '关闭'}).catch(() => {});
            this.$emit('refresh');
            if(!this.gotocol){//如果可以跳转到综合预定,保存后界面不关
              this.$emit('update:visible', false);
            }else{
              this.form = response.data;
            }
          }).finally(()=>{
            this.buttonLoading = false;
          })
        } else {
          return false;
        }
      });
    },
    handleCancel(){
      this.$confirm('确定取消?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
        this.buttonLoading = true;
        cancel_guides({"sqlid":this.form.sqlid}).then(() => {
          this.$message({ message: '取消成功!', type: 'success'});
          this.$emit('refresh');
          this.$emit('update:visible',false);
        }).finally(()=>{
          this.buttonLoading = false;
        })
      }).catch(() => {});
    },
    gotoCol(){
      this.$emit('update:rscolno',this.form.colno);
      this.$emit('gotoCol');
    }
  }
}
</script>

<style>
  .pricelabel{
    color:#c11a25;
    font-size: 22px;
    font-weight: bolder;
  }
</style>
