//Date类型转String类型 格式yyyy-mm-dd
export function formatDate(date){
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10){
        month = "0" + month;
    }
    let day = date.getDate();
    if (day < 10){
        day = "0" + day;
    }
    return (year + "-" + month + "-" + day);
}
//Date类型转String类型 格式yyyy-mm-dd(昨天)
export function lastDate(date){
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    if (month < 10){
        month = "0" + month;
    }
    let day = date.getDate() - 1;
    if (day < 10){
        day = "0" + day;
    }
    return (year + "-" + month + "-" + day);
}

export function lastDateStr(){
    return formatDate(reckonDay(new Date(),-1));
}

//String类型转Date类型
export function stringToDate(dateStr){
    if(dateStr!=null){
        return new Date(Date.parse(dateStr.replace(/-/g,"/")));
    }
    return null;
}

//两个日期相差多少天 Date类型
export function dateDiff(date1,  date2){
    let iDays  =  parseInt((date1  -  date2)  /  1000  /  60  /  60  /24);
    return iDays;
}

//获取时分秒为0的当前日期
export function todayZeroHMS(){
    let strDay = formatDate(new Date());
    return stringToDate(strDay);
}

//和当前时间比较(时分 time格式HH:mm)
export function compareHMtoday(time){
    let today = new Date();
    let hour = today.getHours();
    let minute = today.getMinutes();
    let compareArray = time.split(":");
    if(compareArray.length>1){
        if(hour!=parseInt(compareArray[0])){
            return false;
        }
        if(minute!=parseInt(compareArray[1])){
            return false;
        }
        return true;
    }
    return false;
}

//两个日期相差多少天 String类型
export function stringDateDiff(date1,  date2){
    let iDays  =  parseInt((stringToDate(date1)  -  stringToDate(date2))  /  1000  /  60  /  60  /24);
    return iDays;
}

//判断日期是否小于今天 String类型
export function dateIsExpire(date){
    let nowDate = stringToDate(formatDate(new Date()));
    let compareDate = stringToDate(date);
    return compareDate<nowDate;
}

//获取增加天数后新的日期
export function reckonDay(date,days){
    let millSeconds = Math.abs(date) + (days * 24 * 60 * 60 * 1000);
    let newDate = new Date(millSeconds);
    return newDate;
}

//获取增加天数后新的日期
export function reckonDayStr(dateStr,days){
    let date = stringToDate(dateStr);
    let newDate = reckonDay(date,days);
    return formatDate(newDate);
}

//同步房量里用
export function syncGridHeadDate(dateStr,days){
    let date = stringToDate(dateStr);
    if(days>0){
        date = reckonDay(date,days);
    }
    let month = date.getMonth() + 1;
    if (month < 10){
        month = "0" + month;
    }
    let day = date.getDate();
    if (day < 10){
        day = "0" + day;
    }
    return month+'/'+day;
}
//string类型date比较
export function biggerThan(date1, date2){
    return stringToDate(date1)>stringToDate(date2);
}
//取去年同一天
export function lastYearDate(){
    let date = new Date();
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    return (y-1) + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
}
//取本月第一天
export function monthFirstDate(){
    let date = new Date();
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    return y + "-" + (m < 10 ? "0" + m : m) + "-01";
}

const weekDays = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六'];

export function formatDateStr(dateStr){
    let date = stringToDate(dateStr);
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    let d = date.getDate();
    return y+'年'+m+'月'+d+'日 '+weekDays[date.getDay()];
}

export function getMonthAndDay(date){
    let m = date.getMonth() + 1;
    let d = date.getDate();
    return m+'月'+d+'日';
}

export function getNowYear(){
    let date = new Date();
    let y = date.getFullYear();
    let m = date.getMonth() + 1;
    return y + "-" + (m < 10 ? "0" + m : m);
}

export function getNowYearOnly(){
    let date = new Date();
    return date.getFullYear();
}

//昨天对应的那个月的第一天
export function lastDateMonthFirstDate(){
    return monthFirstDate(reckonDay(new Date(),-1));
}

export function getShortYear(dateStr, addYear){
    let date = stringToDate(dateStr);
    let year = date.getFullYear()+addYear;
    return year%100;
}
