import request from '@/utils/request'

export function fixcharge_list(data) {
    return request({
        url: '/api/fixcharge/fixcharge_list',
        method: 'post',
        data
    })
}

export function cancel_fixcharge(data) {
    return request({
        url: '/api/fixcharge/cancel_fixcharge',
        method: 'post',
        data
    })
}