<template>
  <div class="orderlogs-div">
    <el-tabs v-model="logForm.type" @tab-click="refreshLogTable">
      <el-tab-pane label="OTA" name="OTA"/>
      <el-tab-pane label="CRS" name="CRS"/>
      <el-tab-pane label="PMS" name="PMS"/>
    </el-tabs>
    <el-table ref="logTable" v-loading="loading" :data="orderlogData" highlight-current-row
              style="width: 100%" max-height="250" border @current-change="tableCurrentChange">
      <el-table-column prop="pushtime" label="入队时间" min-width="100"/>
      <el-table-column prop="consumetime" label="处理时间" min-width="140"/>
      <el-table-column prop="msgtype" label="操作类型" min-width="80"/>
      <el-table-column prop="fail" label="失败" min-width="80" :formatter="failFormatter"/>
    </el-table>
    <!--    <el-pagination :current-page="logForm.pages.currentpage" :page-size="logForm.pages.pagesize"-->
    <!--                   layout="total, prev, pager, next, jumper" :total="logForm.pages.total" style="margin-top: 10px;float: right;" background-->
    <!--                   @current-change="handleCurrentChange"/>-->
    <div style="margin-top: 20px">
      <el-form>
        <el-form-item label="消息内容">
          <el-input v-model="msgStr" type="textarea" :rows="6"/>
        </el-form-item>
        <el-form-item v-show="exceptionStr!=''" label="异常内容">
          <el-input v-model="exceptionStr" type="textarea" :rows="6"/>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
  import {orderlog_list} from '@/api/conf/interfacelog';

  export default {
        name: "OrderLogs",
        props: {
          otano:{ type: String,default: ''},//ota订单号
          crsno:{ type: String,default: ''},//crs订单号
          pmsno:{ type: String,default: ''},//pms订单号
          visible:{type: Boolean,default: true}
        },
        data(){
            return {
              logForm:{
                type:'OTA',regno: '',
                pages:{total:0, currentpage:1, pagesize:30, sortname:'date', sortorder:'descending'}
              },
              loading: false, orderlogData:[],msgStr:'',exceptionStr:''
            }
        },
        watch: {
          visible: function(val){
            if(val){
              this.logForm.type = 'OTA';
              this.refreshLogTable();
            }
          }
        },
        mounted: function(){//调用时自己刷新 防止数据不更新
          this.loadLogTable();
        },
        methods:{
          // handleCurrentChange(val){
          //   this.logForm.pages.currentpage = val;
          //   this.loadLogTable();
          // },
          tableCurrentChange(val){
            if(val!=null){
              this.msgStr = val.msg;
              this.exceptionStr = val.exception;
            }else{
              this.msgStr = this.exceptionStr = '';
            }
          },
          failFormatter(row){
            if(row!=null && (row.exception==null||row.exception.length>0)){//row.exception为null也是失败
              return '失败';
            }
            return '';
          },
          refreshLogTable(){
            this.logForm.pages.currentpage = 1;
            this.loadLogTable();
          },
          loadLogTable(){
            if(this.logForm.type==='OTA'){
              this.logForm.regno = this.otano;
            }else if(this.logForm.type==='CRS'){
              this.logForm.regno = this.crsno;
            }else if(this.logForm.type==='PMS'){
              this.logForm.regno = this.pmsno;
            }else{
              this.logForm.regno = '';
            }
            if(this.logForm.regno.length>0){
              this.loading = true;
              orderlog_list(this.logForm).then(response => {
                this.orderlogData = response.data.records;
                this.logForm.pages.total = response.data.total;
                if(this.orderlogData.length>0){
                  this.$refs.logTable.setCurrentRow(this.orderlogData[0]);
                }else{
                  this.tableCurrentChange(null);
                }
              }).finally(() => { this.loading = false;})
            }else{
              this.orderlogData = [];
              this.logForm.pages.total = 0;
            }
          }
        }
    }
</script>

<style scoped>
</style>