<template>
  <div class="main-div">
    <el-container>
      <el-header height="40px">{{ showTitle }}<span style="float:right">{{ showStatus }}</span></el-header>
      <el-main>
        <el-form ref="form" :model="form" :rules="rules" label-width="auto" class="base-form">
          <el-row :gutter="15">
            <el-col :md="8" :sm="12" :xs="24">
              <el-form-item label="锁房代码" prop="code">
                <el-input ref="codeinput" v-model="form.code" maxlength="20" :readonly="form.sqlid>0"/>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="12" :xs="24">
              <el-form-item label="渠道">
                <el-select v-model="form.channel" placeholder="请选择渠道" filterable clearable @change="changeChannel">
                  <el-option v-for="item in channels" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="12" :xs="24">
              <el-form-item label="PMS锁房">
                <el-input v-model="form.pmscode" :readonly="userid!=='admin'"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :md="8" :sm="12" :xs="24">
              <el-form-item label="锁房描述" prop="desc">
                <el-input ref="descinput" v-model="form.desc" maxlength="60" clearable/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :md="24" :sm="24" :xs="24">
              <el-form-item label="日期从" prop="startdate">
                <el-date-picker v-model="form.startdate" placeholder="请选择开始日期" :picker-options="pickerOptions"
                                align="right" type="date" value-format="yyyy-MM-dd" style="width: 30%"/>
                &nbsp;&nbsp;至&nbsp;&nbsp;
                <el-date-picker v-model="form.enddate" placeholder="请选择结束日期" :picker-options="pickerOptions"
                                align="right" type="date" value-format="yyyy-MM-dd" style="width: 30%"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :md="8" :sm="12" :xs="24">
              <el-form-item label="价格代码" prop="ratecodes">
                <el-select v-model="ratecodeSelect" placeholder="请选择价格代码" filterable multiple collapse-tags>
                  <el-option v-for="item in ratecodes" :key="item.code" :label="item.code" :value="item.code">
                    <span style="float: left">{{ item.code }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="8" :sm="12" :xs="24">
              <el-form-item label="确认天数">
                <el-input-number v-model="form.cutdays" :min="0"/>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="15">
            <el-col :md="8" :sm="12" :xs="24">
              <el-form-item label="产品" prop="product">
                <el-select v-model="productSelect" placeholder="请选择产品" multiple collapse-tags>
                  <el-option label="客房" value="R"/>
                  <el-option label="票务" value="T"/>
                  <el-option label="餐饮" value="C"/>
                  <el-option label="套餐" value="P"/>
                </el-select>
              </el-form-item>
            </el-col>
            <!--            <el-col :md="8" :sm="12" :xs="24">-->
            <!--              <el-switch v-model="form.caterstrategy" active-text="餐标预留" inactive-text="餐位预留" active-value="0"-->
            <!--                         inactive-value="1" :disabled="form.sqlid>0"/>-->
            <!--            </el-col>-->
            <!--
            <el-col :md="6" :sm="12" :xs="24">
              <el-form-item label="创建信息">
                <el-input v-model="createinfo" readonly/>
              </el-form-item>
            </el-col>
            <el-col :md="6" :sm="12" :xs="24">
              <el-form-item label="取消信息">
                <el-input v-model="cancelinfo" readonly/>
              </el-form-item>
            </el-col>
            -->
          </el-row>
          <el-row :gutter="15">
            <el-col :md="24" :sm="24" :xs="24">
              <el-form-item label="备注">
                <el-input v-model="form.info" type="textarea" :rows="3" placeholder="请输入备注内容"/>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-main>
      <el-footer height="40px">
        <el-button type="primary" icon="el-icon-edit-outline" :disabled="form.status=='C'"
                   :loading="buttonLoading" @click="handleSave">保存</el-button>
        <el-button type="primary" icon="el-icon-s-flag" :disabled="form.sqlid<=0||form.status!='I'"
                   :loading="buttonLoading" @click="hanleStatusChange('')">启用</el-button>
        <el-button type="danger" icon="el-icon-warning-outline" :disabled="form.sqlid<=0||form.status=='C'"
                   :loading="buttonLoading" @click="handleCancel">取消</el-button>
        <el-button type="primary" icon="el-icon-s-grid" :disabled="form.sqlid<=0||form.status!='O'" @click="handleGrid">预留</el-button>
        <!--
        <el-button type="primary" icon="el-icon-refresh" :disabled="form.sqlid<=0" @click="showIntefaceDialog=true">接口</el-button>
        -->
      </el-footer>
    </el-container>
    <!-- 窗口Dialog -->
    <!--
    <el-dialog title="锁房接口" :visible.sync="showIntefaceDialog" :close-on-click-modal="false" width="70%" append-to-body>
      <InterfaceOp :logtype="'BLOCK'" :type="'BLOCK'"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    -->
    <el-dialog v-dialogDrag :title="gridTitle" :visible.sync="showGridDialog" :close-on-click-modal="false" width="90%" top="3vh" append-to-body>
      <Grid ref="grid" :blockid="gridid" :visible.sync="showGridDialog"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
  </div>
</template>

<script>
  import {cancel_block, use_block, load_block, save_block} from '@/api/channel/block';
  import {load_channel} from '@/api/channel/channels';
  import {select_data} from '@/api/common/custom-data';
  import {select_res} from "@/utils/select_data_res";
  import {getBlockStatus} from '@/utils/status-format';
  import {arrayToString, stringToArray} from '@/utils/string-util';
  import '@/styles/pagecontainer.scss';
  import Grid from '@/views/channel/block/grid';

  export default {
        name:'Block',
        components: {/*InterfaceOp, */Grid},
        props: {
          blockid:{ type: Number,default: 0},
          visible:{type: Boolean,default: true}
        },
        data() {
            return {
                userid: this.$store.getters.userid,
                form:{},
                pickerOptions: {
                    disabledDate(time) { return time.getTime() < Date.now() - 8.64e7; }
                },
                rules: {
                    code: [{ required: true, message: '请输入锁房代码', trigger: 'change' }],
                    desc: [{ required: true, message: '请输入锁房描述', trigger: 'change' }],
                    startdate: [{ required: true, message: '请选择开始日期', trigger: 'change' }],
                    ratecodes: [{ required: true, message: '请选择价格代码', trigger: 'change' }],
                    product: [{ required: true, message: '请选择产品', trigger: 'change' }]
                },
                channels:[],ratecodes:[],
                showIntefaceDialog:false,showGridDialog:false,gridid:0,gridTitle:'', buttonLoading:false
            }
        },
        computed:{
            showTitle(){ return this.form.sqlid!=null&&this.form.sqlid>0?'编辑锁房':'新建锁房'},
            showStatus(){ return this.form.sqlid!=null&&this.form.sqlid>0?getBlockStatus(this.form.status):''},
            // createinfo(){ return this.form.created==null||this.form.created=='1900-01-01'?'':this.form.created+'  '+this.form.createtime+'  '+this.form.creator},
            // cancelinfo(){ return this.form.status!='C'||this.form.modifydate==null||this.form.modifydate=='1900-01-01'?'':this.form.modifydate+'  '+this.form.modifytime+'  '+this.form.modify},
            productSelect:{
                get:function(){ return stringToArray(this.form.product,','); },
                set:function(productValue){ this.form.product = arrayToString(productValue,',');}
            },
            ratecodeSelect:{
                get:function(){ return stringToArray(this.form.ratecodes,','); },
                set:function(rateCodeValue){ this.form.ratecodes = arrayToString(rateCodeValue,',');}
            },
        },
        watch:{
          visible: function(val){
            if(val){
              this.initDialog();
            }
          }
        },
        mounted: function(){
          select_data({"keyname":"channel,ratecode"}).then(response => {
                this.channels = select_res(response.data,"channel");
                this.ratecodes = select_res(response.data,"ratecode");
            });
            this.initDialog();
        },
        methods: {
            initDialog(){
              load_block({"sqlid":this.blockid}).then(response => {
                this.form = response.data;
                if(this.form.sqlid>0){
                  this.$refs.descinput.focus();
                }else{
                  this.$refs.codeinput.focus();
                }
                this.flagValue = this.form.status=='O'?'停用':'启用';
              }).catch(() => {
                this.$emit('update:visible',false);
              })
            },
            changeChannel(val){
              load_channel({'code':val}).then(response => {
                  this.form.ratecodes = response.data.ratecodes;
              })
            },
            handleSave(){
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                      let isNew = this.form.sqlid<=0;
                      this.buttonLoading = true;
                      save_block(this.form).then(response => {
                            this.form = response.data;
                            if(isNew){
                              this.hanleStatusChange('保存成功,');
                            }else{
                              this.$message({ message: '保存成功!', type: 'success'});
                              this.$emit('refresh');
                              this.$emit('update:visible',false);
                            }
                        }).finally(()=>{
                          this.buttonLoading = false;
                        })
                    } else {
                        return false;
                    }
                });
            },
            hanleStatusChange(saveStr){
              this.$confirm(saveStr+'是否启用该锁房?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
                this.buttonLoading = true;
                use_block({"sqlid":this.form.sqlid}).then(() => {
                  this.$message({ message: '启用成功!', type: 'success'});
                  this.$emit('refresh');
                  this.$emit('update:visible',false);
                }).finally(()=>{
                  this.buttonLoading = false;
                })
              }).catch(() => {
                  this.$emit('refresh');
                  this.$emit('update:visible',false);
              });
            },
            handleCancel(){
              this.$confirm('确定取消?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
                this.buttonLoading = true;
                cancel_block({"sqlid":this.form.sqlid}).then(() => {
                    this.$message({ message: '取消成功!', type: 'success'});
                    this.$emit('refresh');
                    this.$emit('update:visible',false);
                }).finally(()=>{
                  this.buttonLoading = false;
                })
              }).catch(() => {});
            },
            handleGrid(){
              // this.$router.push({
              //   name:'grid',
              //   params:{block:this.form.code}
              // });
              this.gridid = this.form.sqlid;
              this.gridTitle = '渠道 '+this.form.desc+' 预留';
              this.showGridDialog = true;
            }
        }
    }
</script>

<style scoped>

</style>
