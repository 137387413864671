<template>
  <div>
    <el-dialog v-dialogDrag title="产品资源价格查询"
               :visible.sync="visible"
               :show="show"
               width="90%"
               top="1vh"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               :modal-append-to-body="false"
               :append-to-body="true"
               @close="$emit('update:show', false)"
               @opened="initDialog"
    >
      <el-form :inline="true" :model="searchForm" class="search-form">
        <el-form-item label="日期">
          <el-date-picker
            v-model="dateselect"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            :clearable="false"
          />
        </el-form-item>
        <!--                <el-form-item label="组">-->
        <!--                    <el-input v-model="searchForm.productgroup" clearable />-->
        <!--                </el-form-item>-->

        <el-form-item v-if="type==='R'" label="锁房">
          <el-select v-model="searchForm.block" clearable filterable @change="loadTable">
            <el-option v-for="item in blocks" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
              <span style="float: left">{{ item.code }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="type==='R'" label="酒店">
          <el-select v-model="tabindexName" filterable @change="gotoTab" >
            <el-option v-for="item in hotels" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
              <span style="float: left">{{ item.code }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="type==='R'">
          <el-checkbox v-model="searchForm.isAvailable">仅查看有库存房型</el-checkbox>
        </el-form-item>



        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="loadTable">查询</el-button>
          <el-button type="primary" @click="closeDialog">退出</el-button>
          <!--                <el-button type="primary" icon="el-icon-download" @click="syncAvailRooms">同步</el-button>-->
        </el-form-item>
      </el-form>

      <el-tabs v-model="tabindexName" type="border-card" @tab-click="tabClick">
        <el-tab-pane v-for="item in tabData" :key="item.code" :label="item.label" :name="item.code" >
          <el-table v-if="tabindexName==item.code" v-loading="loading" :data="tableData" max-height="450" :show-overflow-tooltip="true" border>
            <el-table-column prop="product" label="产品" width="200" fixed :show-overflow-tooltip="true"/>
            <el-table-column label="资源信息">
              <el-table-column prop="totalnum" label="总数" width="60" fixed />
              <el-table-column prop="avlnum" label="可用数" width="60" fixed />
              <el-table-column prop="bookingRate" label="出租率" width="100" fixed >
                <template slot-scope="scope">
                  <span>{{ scope.row.bookingRate.toFixed(2) }}%</span>
                </template>
              </el-table-column>
            </el-table-column>
            <el-table-column label="价格信息">
              <template v-for="(tableCol,index) in tableCols">
                <el-table-column :key="index" :prop="tableCol.prop" :label="tableCol.label">
                  <template slot-scope="scope">
                    <span v-if="scope.row[ tableCols[index].prop]==-1"/>
                    <el-button v-else type="text" :disabled="scope.row.avlnum<=0" @click="selectRate(scope.row,index)">
                      {{ scope.row[ tableCols[index].prop] }}
                    </el-button>
                  </template>
                </el-table-column>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>

    </el-dialog>
  </div>
</template>

<script>
import { formatDate } from "@/utils/calcdate";
import {queryrp,queryrptabs} from "@/api/order/order";
import { select_data } from "@/api/common/custom-data";
import {select_res} from "@/utils/select_data_res";
import {debounce} from '@/utils/debouncefuc';
export default {
  name: "ZjRatequery",
  components: {},
  props: {
    calcform: {
      type: Object,
      default: ()=>({
        block:'',
        productgroup:'',
        type:'R',
        arrdate:'2020-01-08',
        deptdate:'2020-01-10',
        rangetype:1,
        num:1,
        pricefrom:0,
        priceto:9999,
        policy:'',
        person:1,
        child:0,
        extrabad:0
      })
    },
    block:{type:String,defult:''},
    product:{type:String,default:''},
    type:{type:String,default:'R'},
    policy:{type:String,default:''},
    arrdate:{type:String},
    deptdate:{type:String},
    person:{type:Number,default:1},
    child:{type:Number,default:0},
    extrabad: {type:Number,default:0},

    show: {type: Boolean, default: false}
  },
  data() {
    return {
      searchForm:{
        product:this.calcform.rmtype==undefined?'':this.calcform.rmtype,
        productgroup:'', //需要定位的页签
        block:this.block,
        type:this.type,
        arrdate:this.arrdate,
        deptdate:this.deptdate,
        rangetype:1,
        isAvailable:false, //只显示可卖
        num:1,
        pricefrom:0,
        priceto:9999,
        policy:this.policy,
        person:this.person,
        child:this.child,
        extrabad:this.extrabad
      },//Object.assign(this.defaultform,this.calcform), //
      visible:this.show,
      hotels: [],
      tabData:[],
      tableData:[],
      tabindexName:'',
      tableCols:[],
      blocks:[],
      loading:false
    };
  },
  computed:{
    dateselect:{
      get:function(){
        return this.searchForm.arrdate!=null && this.searchForm.deptdate!=null?[this.searchForm.arrdate,this.searchForm.deptdate]:[];
      },
      set:function(dateValue){
        this.searchForm.arrdate = dateValue!=null&&dateValue.length>0?dateValue[0]:'';
        this.searchForm.deptdate = dateValue!=null&&dateValue.length>1?dateValue[1]:'';
      }
    }
  },
  watch:{
    // tabindexName: function (val) {
    //     this.searchForm.productgroup=val;
    // },
    calcform(){
      this.searchForm=this.calcform;
    },
    arrdate(){
      this.searchForm.arrdate=this.arrdate;
    },
    deptdate(){
      this.searchForm.deptdate=this.deptdate;
    },
    type(){
      this.searchForm.type=this.type;
    },
    block(){
      this.searchForm.block=this.block;
    },
    product(){
      this.searchForm.product=this.product;
    },
    person(){
      this.searchForm.person=this.person;
    },
    child(){
      this.searchForm.child=this.child;
    },
    extrabad(){
      this.searchForm.extrabad=this.extrabad;
    },
    show: function () {
      this.visible=this.show;
    }
  },
  mounted: function() {
    // this.loadTable();
    // this.reindex();
  },
  methods: {
    initDialog(){
      this.loadTab();
      this.initGroup();
      // this.loadTable();
    },
    initGroup(){
      select_data({"keyname": "block,hotel"}).then(response => {
        this.blocks = select_res(response.data,"block");
        this.hotels=select_res(response.data,"hotel");
      })
    },
    reindex(){
      if(this.searchForm.productgroup===''||this.searchForm.productgroup===undefined){
        this.searchForm.productgroup=this.tabData[0].code;
        if(this.searchForm.productgroup!=undefined){ //重新定位光标  默认显示第一个页签
          this.tabindexName=this.searchForm.productgroup;
        }
      }
    },
    loadTab(){
      queryrptabs(this.searchForm)
          .then(response=>{
            this.tabData=response.data.datas;
            this.tabindexName=response.data.index;
            // this.reindex();
            this.loadTable();
          })
    },
    tabChange(newtab, oldtab){
      if(newtab!=oldtab){
        this.tabindexName=newtab;
        this.loadTable();
      }else{
        throw new Error('no need change')
      }
    },
    tabClick(){
      this.loadTable();
    },
    gotoTab(){
      // this.tabindexName=this.searchForm.productgroup;
      this.loadTable();
    },
    loadTable() {
      let _searchForm={...this.searchForm};
      _searchForm.productgroup=this.tabindexName;
      // startLoading('请等待.查询中');
      this.loading = true;
      queryrp(_searchForm).then(
          response=>{
            this.tableData=response.data.datas;
            this.tableCols=response.data.headers;
            // this.$forceUpdate();
          }
      ).finally(() => {
        // hideLoading();
        this.loading = false;
      });
    },
    selectRate: debounce(function (row,index) {//返回查询的日期范围.产品.价格码
      // alert(row.pcode+''+this.tableCols[index].prop);
      let _arrdate= this.searchForm.arrdate instanceof Date?formatDate(this.searchForm.arrdate):this.searchForm.arrdate;
      let _deptdate=this.searchForm.deptdate instanceof Date?formatDate(this.searchForm.deptdate):this.searchForm.deptdate;

      let pushitem={
        ratecode: this.tableCols[index].prop,
        product: row.pcode,
        productdesc:row.product,
        arrdate:_arrdate==undefined?new Date():_arrdate,
        deptdate:_deptdate==undefined?new Date():_deptdate,
        producttype:this.type,
        num:1, //暂时设置为1.先调试
        block:this.searchForm.block==undefined?'':this.searchForm.block,
        price: Math.round(row[this.tableCols[index].prop] * 100) / 100 //期望返回数字，而不是字符串，toFixed返回的是字符串
      };
      this.$emit("selectRate", pushitem);
      this.$emit('update:show',false);
    },400),
    closeDialog(){
      // this.$emit('update:visible',false);
      this.visible=false; //触发dialog 关闭的同时. 也触发外层变量关闭
      // this.visible=false;
      this.$emit('update:show',false);
    }
  }
};
</script>

<style scoped>

</style>
