//字符串转数组 value-字符串 splitSign-分割符
export function stringToArray(value,splitSign){
    if(value!=null && value.length>0){
        return value.split(splitSign);
    }else{
        return [];
    }
}
//数组转字符串
export function arrayToString(arrays,contractSign){
    let value = '';
    if(arrays!=null&&arrays.length>0){
        arrays.forEach(function(val){
            value = value.length<=0?val:value+contractSign+val;
        });
    }
    return value;
}