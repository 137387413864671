<template>
  <div class="grid-class">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="客房" name="rooms" :disabled="blockValue.product!=null&&blockValue.product.length>0&&blockValue.product.indexOf('R')<0"/>
      <el-tab-pane label="票务" name="tickets" :disabled="blockValue.product!=null&&blockValue.product.length>0&&blockValue.product.indexOf('T')<0"/>
      <el-tab-pane label="餐饮" name="caters" :disabled="blockValue.product!=null&&blockValue.product.length>0&&blockValue.product.indexOf('C')<0"/>
      <el-tab-pane label="套餐" name="packages" :disabled="blockValue.product!=null&&blockValue.product.length>0&&blockValue.product.indexOf('P')<0"/>
    </el-tabs>
    <el-form :inline="true" :model="form" class="search-form">
      <el-form-item>
        <el-switch v-model="form.setup" active-text="设置" inactive-text="查看" @change="loadTable"/>
      </el-form-item>
      <el-form-item v-show="activeName==='caters'">
        <el-switch v-model="form.postype" active-text="餐厅" inactive-text="餐标" @change="changePosType"/>
      </el-form-item>
      <el-form-item :label="codelabel">
        <el-input ref="codeinput" v-model="form.code" @keyup.enter.native="refreshTable">
          <i slot="suffix" class="el-input__icon el-icon-s-grid" @click="showCodeDialog=true"/>
        </el-input>
      </el-form-item>
      <!--
      <el-form-item :label="codelabel">
        <el-input v-model="form.code" clearable/>
      </el-form-item>
      -->
      <el-form-item label="查询日期">
        <el-date-picker v-model="searchDate" type="daterange" align="right" unlink-panels
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        :picker-options="pickerOptions" value-format="yyyy-MM-dd" :clearable="false"/>
      </el-form-item>
      <el-form-item v-show="activeName==='rooms' && !form.setup" label="价格">
        <el-switch v-model="form.showprice" @change="loadTable"/>
      </el-form-item>
      <el-form-item v-show="activeName==='packages' && !form.setup" label="子库存">
        <el-switch v-model="form.showsublimit" @change="loadTable"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="refreshTable">查询</el-button>
        <el-button v-show="activeName==='rooms'" type="primary" icon="el-icon-tickets" @click="showLogDialog = true">日志</el-button>
        <el-dropdown v-show="!form.setup" style="margin-left: 10px" @command="handleCommand">
          <el-button type="primary">
            操作<i class="el-icon-arrow-down el-icon--right"/>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-show="activeName==='rooms'" command="syncRooms">同步库存</el-dropdown-item>
            <el-dropdown-item v-show="activeName==='rooms'" command="cutoffRooms">释放库存</el-dropdown-item>
            <el-dropdown-item command="notifyPrice">通知价格</el-dropdown-item>
            <el-dropdown-item command="notifyRooms">通知库存</el-dropdown-item>
            <el-dropdown-item v-show="activeName==='rooms'" command="viewReservations">查看预定</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!--        <el-dropdown v-show="activeName==='packages' && !form.setup" style="margin-left: 10px" @command="handlePackageCommand">-->
        <!--          <el-button type="primary">-->
        <!--            通知<i class="el-icon-arrow-down el-icon&#45;&#45;right"/>-->
        <!--          </el-button>-->
        <!--          <el-dropdown-menu slot="dropdown">-->
        <!--            <el-dropdown-item command="notifyPackagePrice">价格通知</el-dropdown-item>-->
        <!--            <el-dropdown-item command="notifyPackageRooms">库存通知</el-dropdown-item>-->
        <!--          </el-dropdown-menu>-->
        <!--        </el-dropdown>-->
        <!--
        <el-button v-show="activeName=='rooms' && !form.setup" type="primary" @click="syncAlotm">同步</el-button>
        <el-button v-show="activeName=='rooms' && !form.setup" type="danger" @click="cutoffAlotm">释放</el-button>
        -->
      </el-form-item>
    </el-form>
    <el-form v-show="form.setup" :inline="true" :model="setupform" class="setup-form">
      <el-form-item :label="codelabel">
        <el-input v-model="setupform.code">
          <i slot="suffix" class="el-input__icon el-icon-s-grid" @click="setupCodeClick"/>
        </el-input>
      </el-form-item>
      <el-form-item label="设置日期">
        <el-date-picker v-model="setupDate" type="daterange" align="right" unlink-panels
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        :picker-options="pickerOptions" value-format="yyyy-MM-dd" :clearable="false"/>
      </el-form-item>
      <el-form-item label="预留数">
        <el-input-number v-model="setupform.num" :min="0"/>
      </el-form-item>
      <el-form-item v-show="activeName=='packages'" label="范围">
        <el-select v-model="setupform.scope" placeholder="请选择" multiple collapse-tags>
          <el-option label="星期一" value="1"/>
          <el-option label="星期二" value="2"/>
          <el-option label="星期三" value="3"/>
          <el-option label="星期四" value="4"/>
          <el-option label="星期五" value="5"/>
          <el-option label="星期六" value="6"/>
          <el-option label="星期天" value="0"/>
        </el-select>
      </el-form-item>
      <el-form-item v-show="activeName==='caters'" label="范围">
        <el-select v-model="setupform.scope" placeholder="请选择" multiple collapse-tags>
          <el-option v-for="item in restaurantTimes" :key="item.code" :label="item.desc" :value="item.code"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="buttonloading" @click="setAlotm">设置</el-button>
        <el-button type="danger" :loading="buttonloading" @click="deleteAlotm">删除</el-button>
        <el-button v-show="activeName==='rooms'" type="primary" @click="resetAlotm">调房</el-button>
      </el-form-item>
    </el-form>
    <hot-table id="gridTable" ref="textHot" v-loading="loading" :settings="hotSettings" license-key="non-commercial-and-evaluation"/>
    <el-pagination :current-page="form.pages.currentpage" :page-size="form.pages.pagesize" :page-sizes="[15, 30, 50, 100, 200, 500]"
                   layout="sizes,total, prev, pager, next, jumper" :total="form.pages.total" style="margin-top: 10px;float: right;" background
                   @current-change="handleCurrentChange" @size-change="handleSizeChange"/>
    <!-- Dialog -->
    <el-dialog v-dialogDrag :title="codelabel" :visible.sync="showCodeDialog" :close-on-click-modal="false" width="50%" append-to-body @opened="refreshCodeData">
      <el-form :inline="true" class="search-form">
        <el-form-item :label="codelabel">
          <el-input v-model="searchCode" clearable/>
        </el-form-item>
        <el-form-item v-show="grouplabel!==''" :label="grouplabel">
          <el-select v-model="searchGroup" filterable clearable>
            <el-option v-for="item in groups" :key="item.code" :label="item.code" :value="item.code">
              <span style="float: left">{{ item.code }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-show="activeName==='packages'" label="仅线上套餐">
          <el-switch v-model="showOnlinePackage" active-color="#13ce66" inactive-color="#ff4949"/>
        </el-form-item>
      </el-form>
      <el-table :data="codeData.filter(data => (!searchCode || data.code.toLowerCase().startsWith(searchCode.toLowerCase()) || data.desc.toLowerCase().indexOf(searchCode.toLowerCase())>=0) &&
                  (!searchGroup || data.group===searchGroup) && (!showOnlinePackage || data.group==='1'))"
                style="width: 100%" max-height="350" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"/>
        <el-table-column property="code" label="代码" width="150"/>
        <el-table-column property="desc" label="描述" width="200"/>
        <el-table-column property="group" label="组" width="150" :formatter="productkitFormatter"/>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="selectCode">选择</el-button>
        <el-button @click="showCodeDialog=false">退出</el-button>
      </div>
    </el-dialog>
    <el-dialog v-dialogDrag title="查看日志" :visible.sync="showLogDialog" :close-on-click-modal="false" width="70%" append-to-body>
      <GridLogs ref="gridlogs" :block="form.block" :visible.sync="showLogDialog"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
    <el-dialog v-dialogDrag title="锁房同步库存" :visible.sync="showSyncDialog" :close-on-click-modal="false" width="70%" append-to-body @opened="openSyncTable">
      <el-table v-loading="loadingSyncTable" :data="syncData" style="width: 100%" max-height="400" border :cell-style="cellStyle">
        <el-table-column property="roomtype" label="房型" width="100" fixed/>
        <el-table-column property="desc" label="描述" width="150"/>
        <el-table-column v-for="date in syncColumns" :key="date" :property="date" :label="date" :formatter="syncCellFormatter"/>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="syncAlotm">同步</el-button>
        <el-button @click="showSyncDialog=false">退出</el-button>
      </div>
    </el-dialog>
    <el-dialog v-dialogDrag title="调房" :visible.sync="showResetDialog" :close-on-click-modal="false" width="60%" append-to-body @opened="openRetsetDialog">
      <el-form :inline="true" :model="resetForm">
        <el-form-item label="锁房从">
          <el-input v-model="resetForm.fromblock" readonly/>
        </el-form-item>
        <el-form-item label="到">
          <el-select v-model="resetForm.toblock" filterable @change="loadResetTable">
            <el-option v-for="item in blocks" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
              <span style="float: left">{{ item.code }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="调整预留">
          <el-tooltip class="item" effect="dark" content="预留变化量以第一天为准" placement="top">
            <el-input-number v-model="resetForm.fromalotm" :min="orgFromPickup" :max="orgToAllow-orgToPickup+orgFromAllow" @change="handleResetAlotmChange"/>
          </el-tooltip>
        </el-form-item>
        <el-form-item>
          <span class="resetValueLabel">{{ showResetText }}</span>
        </el-form-item>
        <el-table v-loading="resetLoading" :data="resetData" style="width: 100%" max-height="400" :span-method="roomtypeSpan" border>
          <el-table-column property="block" label="锁房" width="100" fixed/>
          <el-table-column property="blockdesc" label="锁房描述" width="150"/>
          <el-table-column property="roomtype" label="房型" width="150"/>
          <el-table-column v-for="date in reSetColumns" :key="date" :property="date" :label="date" :formatter="syncCellFormatter"/>
        </el-table>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="doResetAlotm">确定</el-button>
        <el-button @click="showResetDialog=false">退出</el-button>
      </div>
    </el-dialog>
    <el-dialog v-dialogDrag title="锁房预定" :visible.sync="showRsDialog" :close-on-click-modal="false" width="80%" append-to-body>
      <RoomList v-if="showRsDialog" ref="roomlist" :block="form.block" :startdate="form.resstartdate" :enddate="form.resenddate" :roomtype="form.code" :custom="'GRID'" @refreshCol="refreshTable"/>
      <div slot="footer" class="dialog-footer"/>
    </el-dialog>
  </div>
</template>

<script>
  import {cutoff_alotm, delete_alotm, grid_list, setup_alotm, sync_alotm,
    notify_price, notify_rooms, sync_list, reset_list, reset_grid} from '@/api/channel/grid';
  import {load_block} from '@/api/channel/block';
  import {formatDate, reckonDay, stringDateDiff, syncGridHeadDate, reckonDayStr} from '@/utils/calcdate';
  import {select_data} from '@/api/common/custom-data';
  import {select_res} from "@/utils/select_data_res";
  import {HotTable} from '@handsontable/vue';
  import GridLogs from "@/views/common/gridlogs";
  import RoomList from '@/views/order/room/roomrs-list';
  import 'handsontable/dist/handsontable.full.css';
  import Handsontable from 'handsontable';

  export default {
    name:'Grid',
    components: {
      HotTable,GridLogs,RoomList
    },
    props: {
      blockid:{ type: Number,default: 0},
      visible:{type: Boolean,default: true}
    },
    data: function() {
      return {
        activeName:'rooms',buttonloading:false,showCodeDialog:false,showLogDialog:false,showSyncDialog:false,loadingSyncTable:false,
        codeData:[],multipleSelection:[],tableCols:[],restaurantTimes:[],syncData:[],syncColumns:[],reSetColumns:[],
        blockValue:{},searchCode:'',searchGroup:'',groups:[],fromSetup:false,showResetDialog:false,showRsDialog:false,
        resetData:[],blocks:[],orgFromAllow:0,orgFromPickup:0,orgToAllow:0,orgToPickup:0,showOnlinePackage:false,
        resetForm:{fromblock:'',toblock:'',fromalotm:0,toalotm:0,changeNum:0,from:'',to:'',roomtype:''},showResetText:'+0',
        loading:false,resetLoading:false,
        form:{
          type:'rooms',block:'',
          code:'',startdate:formatDate(new Date()),enddate:formatDate(reckonDay(new Date(),18)),
          resstartdate:'', resenddate:'',
          setup:false,showprice:false,showsublimit:false,postype:true,
          // total:0, currentpage:1, pagesize:20,
          pages:{total:0, currentpage:1, pagesize:15}
        },
        setupform:{
          type:'rooms',block:'',code:'',startdate:'',enddate:'',num:0,scope:[], postype:true
        },
        pickerOptions: {
          shortcuts: [{ text: '今天', onClick(picker) { picker.$emit('pick', [new Date(), new Date()]); } },{
            text: '一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 6);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 29);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        hotSettings:{
          data: [],
          rowHeaders:[],
          // colHeaders:[],
          nestedHeaders:[["日期"],["代码"]],
          fixedColumnsLeft:1,
          width: '100%',
          height: 400,
          readOnly: true,
          autoColumnSize: true,
          // manualColumnResize: true,
          selectionMode: 'range',
          stretchH: 'all',
          renderer: function(instance, td, row, col, prop, value, cellProperties) {
            Handsontable.renderers.TextRenderer.apply(this, arguments);
            if (col>0 && value && value.indexOf("/")>=0) {
              let splitValue = value.split("/");
              if(splitValue[0]===(splitValue[1].indexOf("\n")>=0?splitValue[1].substring(0,splitValue[1].indexOf("\n")):splitValue[1])){
                td.style.backgroundColor = '#ffd8d8';
              }else{
                td.style.backgroundColor = '#daf7d7';
              }
            }
          },
          afterSelectionEnd: (row, column, row2, column2) => {
            if(row>=0 && row2>=0){
              let selectCode = '';
              let startRow = row>row2?row2:row;
              let endRow = row>row2?row:row2;
              for(let i=startRow; i<=endRow; i++){
                selectCode = selectCode.length<=0?this.hotSettings.rowHeaders[i]:selectCode+','+this.hotSettings.rowHeaders[i];
              }
              if(this.form.setup) {
                this.setupform.code = selectCode;
              }else{
                this.form.code = selectCode;
              }
            }
            if (column >= 0 && column2 >= 0) {
              column = column == 0 ? 1 : column;//index=0是描述
              column2 = column2 == 0 ? 1 : column2;
              let start = this.tableCols[column - 1];
              if (start != null && start.length > 0) {
                if(this.form.setup){
                  this.setupform.startdate = start;
                }else{
                  this.form.resstartdate = start;
                }
              }
              let end = this.tableCols[column2 - 1];
              if (end != null && end.length > 0) {
                if(this.form.setup){
                  this.setupform.enddate = end;
                }else{
                  this.form.resenddate = reckonDayStr(end,1);//查看预定的要加多1天
                }
              }
            }
          },
          afterChange: (changes, source) => {
            if(source=="edit" && changes!=null && changes.length>0){
              this.setupform.num = changes[0][3];
              this.setAlotm();
            }
          }
        }
      };
    },
    computed:{
      codelabel(){
        if(this.activeName=='rooms'){
          return '房型';
        }else if(this.activeName=='tickets'){
          return '票型';
        }else if(this.activeName=='caters'){
          // if(this.blockValue.caterstrategy!=null && this.blockValue.caterstrategy=='1'){
          //   return '餐厅';
          // }
          // return '餐标';
          return this.form.postype?'餐厅':'餐标';
        }else if(this.activeName=='packages'){
          return '套餐';
        }
        return '代码';
      },
      grouplabel(){
        if(this.activeName=='rooms'){
          return '酒店';
        }else if(this.activeName=='tickets'){
          return '票型组';
        }else if(this.activeName=='caters'){
          // if(this.blockValue.caterstrategy==null || this.blockValue.caterstrategy!='1'){
          //   return '餐厅';
          // }
          if(!this.form.postype){
            return '餐厅';
          }
        }
        return '';
      },
      searchDate:{
        get:function(){
          return this.form.startdate!=null && this.form.enddate!=null?[this.form.startdate,this.form.enddate]:[];
        },
        set:function(dateValue){
          this.form.startdate = dateValue!=null&&dateValue.length>0?dateValue[0]:'';
          this.form.enddate = dateValue!=null&&dateValue.length>1?dateValue[1]:'';
        }
      },
      setupDate:{
        get:function(){
          return this.setupform.startdate!=null && this.setupform.enddate!=null?[this.setupform.startdate,this.setupform.enddate]:[];
        },
        set:function(dateValue){
          this.setupform.startdate = dateValue!=null&&dateValue.length>0?dateValue[0]:'';
          this.setupform.enddate = dateValue!=null&&dateValue.length>1?dateValue[1]:'';
        }
      }
    },
    watch:{
      visible: function(val){
        if(val){
          this.$refs.codeinput.focus();
          this.initDialog();
        }
      }
    },
    mounted: function(){
      this.$refs.codeinput.focus();
      select_data({"keyname":"restaurant_time"}).then(response => {
        this.restaurantTimes = select_res(response.data,"restaurant_time");
      });
      this.initDialog();
    },
    methods:{
      productkitFormatter(row){
        if(this.activeName=='packages' && row.group=='1'){
          return '线上';
        }else{
          return row.group;
        }
      },
      initDialog(){
        load_block({"sqlid":this.blockid}).then(response => {
          this.blockValue = response.data;
          this.form.block = this.setupform.block = this.blockValue.code;
          if(this.blockValue.product!=null && this.blockValue.product.length>0){
            if(this.blockValue.product.indexOf('R')>=0){
              this.activeName = "rooms";
            }else if(this.blockValue.product.indexOf('T')>=0){
              this.activeName = 'tickets';
            }else if(this.blockValue.product.indexOf('C')>=0){
              this.activeName = 'caters';
            }else if(this.blockValue.product.indexOf('P')>=0){
              this.activeName = 'packages';
            }else{
              this.activeName = 'rooms';
            }
          }
          this.form.type = this.setupform.type = this.activeName;
          this.codeData = this.multipleSelection = [];
          this.setupform.code = this.form.code = this.searchCode = this.searchGroup = '';
          this.form.setup = false;
          this.refreshTable();
        }).catch(() => {
          this.$emit('update:visible',false);
        })
      },
      handleClick(){
        this.$refs.codeinput.focus();
        this.form.type = this.setupform.type = this.activeName;
        this.codeData = this.multipleSelection = [];
        this.setupform.code = this.form.code = this.searchCode = this.searchGroup = '';
        this.showOnlinePackage = false;
        this.refreshTable();
      },
      setupCodeClick(){
        this.showCodeDialog = true;
        this.fromSetup = true;
      },
      refreshTable(){
        this.form.pages.currentpage = 1;
        this.loadTable();
      },
      loadTable(){
        this.loading = true;
        grid_list(this.form).then(response => {
          this.hotSettings.nestedHeaders = response.data.nestedHeaders;
          this.hotSettings.rowHeaders = response.data.rowheaders;
          this.hotSettings.readOnly = !this.form.setup;
          this.hotSettings.data = response.data.records;
          this.form.pages.total = response.data.total;
          this.tableCols = response.data.dateHeaders;
        }).finally(()=>{
          this.loading = false;
        });
      },
      handleCurrentChange(val){
        this.form.pages.currentpage = val;
        this.loadTable();
      },
      handleSizeChange(val){
        this.form.pages.pagesize = val;
        this.refreshTable();
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      selectCode(){
        this.showCodeDialog = false;
        let selectCode = '';
        this.multipleSelection.forEach(selectValue => {
          selectCode = selectCode.length<=0?selectValue.code:selectCode+","+selectValue.code;
        });
        if(this.fromSetup){
          this.setupform.code = selectCode;
          this.fromSetup = false;
        }else{
          this.form.code = selectCode;
        }
      },
      refreshCodeData(){
        if(this.codeData.length<=0){
          let keywords = '';
          if(this.activeName==='rooms'){
            keywords = 'roomtype,hotel';
          }else if(this.activeName==='tickets'){
            keywords = 'ticket,ticketgroup';
          }else if(this.activeName==='caters'){
            // if(this.blockValue.caterstrategy!=null && this.blockValue.caterstrategy=='1'){
            //   keywords = 'restaurant';
            // }else{
            //   keywords = 'tisch,restaurant';
            // }
            if(this.form.postype){
              keywords = 'restaurant';
            }else{
              keywords = 'tisch,restaurant';
            }
          }else if(this.activeName==='packages'){
            keywords = 'productkit';
          }
          if(keywords.length>0){
            select_data({"keyname":keywords}).then(response => {
              let keyword = keywords;
              let groupword = '';
              if(keyword.indexOf(",")>=0){
                let keywordArray = keyword.split(",");
                keyword = keywordArray[0];
                groupword = keywordArray[1];
              }
              this.codeData = select_res(response.data,keyword);
              this.groups = select_res(response.data,groupword);
            });
          }
        }
      },
      changePosType(){
        this.codeData = [];
        this.refreshCodeData();
        this.loadTable();
      },
      setAlotm(){
        if(!this.setupform.code){
          this.$message({ message: '请选择要设置的产品', type: 'warning'});
        }else{
          this.loading = true;
          this.buttonloading = true;
          this.setupform.postype = this.form.postype;
          setup_alotm(this.setupform).catch(() => {
            this.$message.error('设置预留失败');
          }).finally(() => {
            this.loadTable();
            this.buttonloading = false;
          })
        }
      },
      deleteAlotm(){
        if(!this.setupform.code){
          this.$message({ message: '请选择要删除的产品', type: 'warning'});
        }else{
          this.loading = true;
          this.buttonloading = true;
          this.setupform.postype = this.form.postype;
          delete_alotm(this.setupform).catch(() => {
            this.$message.error('删除预留失败');
          }).finally(() => {
            this.loadTable();
            this.buttonloading = false;
          })
        }
      },
      cutoffAlotm(){
        let cutdays = this.blockValue.cutdays==null?0:this.blockValue.cutdays;
        let cutdayStr = formatDate(reckonDay(new Date(),cutdays));
        this.$confirm('确定释放该锁房'+cutdayStr+'前(含当天)所有预留房?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
          // startLoading('.grid-class','释放预留中,请稍等...');
          this.loading = true;
          cutoff_alotm({"block":this.form.block,"isNa":false}).then(() => {
            this.loadTable();
          }).finally(() => {
            // hideLoading();
            this.loading = false;
          });
        }).catch(() => {});
      },
      syncAlotm(){
        // this.$confirm('确定同步预留?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
          this.showSyncDialog = false;
          // startLoading('.grid-class','同步预留中,请稍等...');
          sync_alotm({"block":this.form.block,"from":this.form.startdate,"to":this.form.enddate,"data":this.syncData}).then(() => {
            // this.loadTable();
            this.$message('异步处理预留同步,请稍后刷新表格');
          });
          // .finally(() => {
          //   hideLoading();
          // });
        // }).catch(() => {});
      },
      notifyPrice(){
        let productType = '';
        let productDesc = '';
        if(this.activeName==='rooms'){
          productType = 'R'
          productDesc = '房型';
        }else if(this.activeName==='tickets'){
          productType = 'T'
          productDesc = '票型';
        }else if(this.activeName==='caters'){
          if(!this.form.postype){
            productType = 'H'
            productDesc = '餐标';
          }else{
            productType = 'C'
            productDesc = '餐厅';
          }
        }else if(this.activeName==='packages'){
          productType = 'Z'
          productDesc = '套餐';
        }
        if(this.form.code.length<=0){
          this.$message({ message: '通知'+productDesc+'为空', type: 'warning'});
        }else{
          notify_price({"block":this.form.block,"productCodes":this.form.code.toUpperCase(),"productType":productType,"from":this.form.startdate,"to":this.form.enddate}).then(() => {
            this.$message({ message: '通知'+this.form.code.toUpperCase()+'价格成功', type: 'success'});
          });
        }
      },
      notifyRooms(){
        let productType = '';
        let productDesc = '';
        if(this.activeName==='rooms'){
          productType = 'R'
          productDesc = '房型';
        }else if(this.activeName==='tickets'){
          productType = 'T'
          productDesc = '票型';
        }else if(this.activeName==='caters'){
          if(!this.form.postype){
            productType = 'H'
            productDesc = '餐标';
          }else{
            productType = 'C'
            productDesc = '餐厅';
          }
        }else if(this.activeName==='packages'){
          productType = 'Z'
          productDesc = '套餐';
        }
        if(this.form.code.length<=0){
          this.$message({ message: '通知'+productDesc+'为空', type: 'warning'});
        }else{
          notify_rooms({"block":this.form.block,"productCodes":this.form.code.toUpperCase(),"productType":productType,"from":this.form.startdate,"to":this.form.enddate}).then(() => {
            this.$message({ message: '通知'+this.form.code.toUpperCase()+'库存成功', type: 'success'});
          });
        }
      },
      handleCommand(command){
        if(command==='syncRooms'){
          this.showSyncDialog = true;
        }else if(command==='cutoffRooms'){
          this.cutoffAlotm();
        }else if(command==='notifyPrice'){
          this.notifyPrice();
        }else if(command==='notifyRooms'){
          this.notifyRooms();
        }else if(command==='viewReservations'){
          if(this.form.code!==''){
            this.showRsDialog = true;
          }else{
            this.$message({ message: '至少选择一个房型', type: 'warning'});
          }
        }
      },
      // handlePackageCommand(command){
      //   if(command==='notifyPackagePrice'){
      //     this.notifyPackagePrice();
      //   }else if(command==='notifyPackageRooms'){
      //     this.notifyPackageRooms();
      //   }
      // },
      // notifyPackagePrice(){
      //   if(this.form.code.length<=0){
      //     this.$message({ message: '通知套餐为空', type: 'warning'});
      //   }else{
      //     notify_price({"block":this.form.block,"packages":this.form.code.toUpperCase(),"from":this.form.startdate,"to":this.form.enddate}).then(() => {
      //       this.$message({ message: '通知'+this.form.code.toUpperCase()+'价格成功', type: 'success'});
      //     });
      //   }
      // },
      // notifyPackageRooms(){
      //   if(this.form.code.length<=0){
      //     this.$message({ message: '通知套餐为空', type: 'warning'});
      //   }else{
      //     notify_rooms({"block":this.form.block,"packages":this.form.code.toUpperCase(),"from":this.form.startdate,"to":this.form.enddate}).then(() => {
      //       this.$message({ message: '通知'+this.form.code.toUpperCase()+'库存成功', type: 'success'});
      //     });
      //   }
      // },
      syncCellFormatter(row,column){
        let values = row.data;
        for(let i=0; i<values.length; i++){
          if(values[i].dateStr == column.label){
            return values[i].value;
          }
        }
        return '';
      },
      cellStyle({row, column}){
        let values = row.data;
        for(let i=0; i<values.length; i++){
          if(values[i].dateStr == column.label){
            if(values[i].diff){
              return 'color:red; font-weight:bold';
            }
          }
        }
        return '';
      },
      openSyncTable(){
        this.syncColumns = [];
        let compareDate = stringDateDiff(this.form.enddate,this.form.startdate)+1;
        for(let i=0; i<compareDate; i++){
          this.syncColumns.push(syncGridHeadDate(this.form.startdate,i));
        }
        this.loadSyncTable();
      },
      loadSyncTable(){
        this.loadingSyncTable = true;
        sync_list({"block":this.form.block,"from":this.form.startdate,"to":this.form.enddate}).then(response => {
          this.syncData = response.data;
        }).finally(() => { this.loadingSyncTable = false;})
      },
      resetAlotm(){
        let roomtypes = this.setupform.code;
        if(roomtypes.length<=0){
          this.$message({ message: '请选择一个房型来调房', type: 'warning'});
        }else if(roomtypes.includes(",")){
          this.$message({ message: '只能选择一个房型来调房', type: 'warning'});
        }else{
          if(this.blocks.length<=0){
            select_data({"keyname":"block"}).then(response => {
              this.blocks = [];
              let tempBlock = select_res(response.data,"block");
              tempBlock.forEach(row=>{
                if(row.code!==this.form.block){
                  this.blocks.push(row);
                }
              });
            });
          }
          if(this.resetForm.toblock===this.form.block){
            this.resetForm.toblock = '';
          }
          this.showResetDialog = true;
        }
      },
      roomtypeSpan({ row, column, rowIndex, columnIndex }) {
        if (columnIndex === 2) { //房型列合并单元格
          if (rowIndex % 2 === 0) {
            return {
              rowspan: 2,
              colspan: 1
            };
          } else {
            return {
              rowspan: 0,
              colspan: 0
            };
          }
        }
      },
      openRetsetDialog(){
        this.reSetColumns = [];
        let compareDate = stringDateDiff(this.setupform.enddate,this.setupform.startdate)+1;
        for(let i=0; i<compareDate; i++){
          this.reSetColumns.push(syncGridHeadDate(this.setupform.startdate,i));
        }
        this.resetForm.fromblock = this.form.block;
        this.resetForm.from = this.setupform.startdate;
        this.resetForm.to = this.setupform.enddate;
        this.resetForm.roomtype = this.setupform.code;
        this.loadResetTable();
      },
      handleResetAlotmChange(val){
        if(isNaN(val)){
          val = 0;
        }
        let compareValue = val - this.orgFromAllow;
        this.showResetText = (compareValue>=0?"+":"")+compareValue;
        this.resetForm.changeNum = compareValue;
      },
      loadResetTable(){
        if(this.resetForm.fromblock.length>0 && this.resetForm.toblock.length>0){
          this.resetLoading = true;
          reset_list(this.resetForm).then(response => {
            this.resetData = response.data.datas;
            this.resetForm.fromalotm = response.data.fromAllow;
            this.orgFromAllow = response.data.fromAllow;
            this.orgFromPickup = response.data.fromPickup;
            this.orgToAllow = response.data.toAllow;
            this.orgToPickup = response.data.toPickup;
          }).finally(() => { this.resetLoading = false;})
        }else{
          this.resetData = [];
          this.resetForm.fromalotm = 0;
          this.orgFromAllow = 0;
        }
        this.handleResetAlotmChange(this.orgFromAllow);
      },
      doResetAlotm(){
        this.showResetDialog = false;
        if(this.resetForm.changeNum!=0){//0就不处理了
          this.resetForm.toalotm = this.orgToAllow-this.resetForm.changeNum;
          // startLoading('.grid-class','设置预留中,请稍等...');
          this.loading = true;
          reset_grid(this.resetForm).then(() => {
            this.refreshTable();
          }).finally(() => {
            // hideLoading();
            this.loading = false;
          });
        }
      }
    }
  }
</script>

<style>
  .resetValueLabel{
    color:#c11a25;
    font-size: 22px;
    font-weight: bolder;
  }
</style>