<template>
  <div>
    <el-form v-show="custom==''" ref="form" :model="form" :inline="true">
      <div v-if="showAdvance" class="advance-div">
        <el-form-item label="到店">
          <el-date-picker v-model="form.arrfrom" align="right" type="date" placeholder="选择到店日期"
                          value-format="yyyy-MM-dd"/>
        </el-form-item>
        <el-form-item label="离店">
          <el-date-picker v-model="form.deptfrom" align="right" type="date" placeholder="选择离店日期"
                          value-format="yyyy-MM-dd"/>
        </el-form-item>
        <el-form-item label="酒店">
          <el-select v-model="form.hotel" placeholder="请选择酒店" filterable clearable>
            <el-option v-for="item in hotels" :key="item.code" :label="item.code+' - '+item.desc" :value="item.code">
              <span style="float: left">{{ item.code }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="订单号">
        <el-input ref="bookingidinput" v-model="form.orderno"
                  placeholder="综合预订号/PMS 订单号" clearable @keyup.enter.native="loadTable"/>
      </el-form-item>
      <el-form-item label="姓名/电话">
        <el-input v-model="form.guestname" placeholder="客人姓名或者电话" clearable @keyup.enter.native="loadTable"/>
      </el-form-item>
      <!--      <el-form-item label="电话">-->
      <!--        <el-input v-model="form.phone" placeholder="电话号码" clearable @keyup.enter.native="loadTable"/>-->
      <!--      </el-form-item>-->
      <el-form-item label="状态">
        <el-select v-model="form.status" placeholder="请选择状态" multiple collapse-tags>
          <el-option label="预抵" value="R"/>
          <el-option label="到店" value="C"/>
          <el-option label="离店" value="L"/>
          <el-option label="取消" value="X"/>
          <el-option label="应到未到" value="N"/>
        </el-select>
      </el-form-item>
      <el-form-item label="历史">
        <el-switch v-model="form.history" active-color="#13ce66" inactive-color="#ff4949" @change="changeHistory"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="loadTable">查询</el-button>
        <el-button :icon="showAdvance?'el-icon-caret-top':'el-icon-caret-bottom'" @click="showAdvance=!showAdvance">{{ showAdvance?'收起':'更多' }}</el-button>
      </el-form-item>

      <el-form-item style="float: right">
        <el-button type="primary" icon="el-icon-plus" :disabled="form.history" @click="openRoom(0)">新建</el-button>
      </el-form-item>


      <!--            <el-form-item>-->
      <!--                <el-button type="primary" icon="el-icon-search" @click="loadTable">搜索</el-button>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item>-->
      <!--                <el-button type="primary" icon="el-icon-plus" @click="openRoom(0)">新建</el-button>-->
      <!--            </el-form-item>-->
      <!--            <el-form-item>-->
      <!--                <el-button type="primary" icon="el-icon-plus" @click="openTest1(-1)">TEST1</el-button>-->
      <!--            </el-form-item>-->
    </el-form>

    <el-table v-loading="loading" :data="tabledata" :summary-method="tableSummary" :show-summary="summary!=''"
              :row-class-name="tableRowClassName" border style="width: 100%" max-height="450" @sort-change="handleSortChange">
      <el-table-column prop="status" label="状态" min-width="80" sortable="custom">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status==='X'? 'danger' : 'primary'" disable-transitions>{{ statusFormatter(scope.row.status) }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="guestname" label="姓名" min-width="100" :show-overflow-tooltip="true" sortable="custom"/>
      <el-table-column prop="guestphone" label="电话" min-width="100"/>
      <el-table-column prop="hotelcode" label="酒店" min-width="120" :show-overflow-tooltip="true" sortable="custom"/>
      <el-table-column prop="rmtype" label="房型" min-width="180" :show-overflow-tooltip="true" sortable="custom"/>
      <el-table-column prop="anz" label="房间数" min-width="70"/>
      <el-table-column prop="roomno" label="房间号" min-width="85" sortable="custom"/>
      <el-table-column prop="arrdate" label="到店日期" min-width="100" sortable="custom"/>
      <el-table-column prop="deptdate" label="离店日期" min-width="100" sortable="custom"/>
      <el-table-column prop="totalprice" label="总价" min-width="100"/>
      <el-table-column prop="channelsource" label="渠道" min-width="90" sortable="custom"/>
      <el-table-column prop="pmsno" label="PMS订单号" min-width="120" sortable="custom"/>
      <el-table-column prop="colno" label="综合预订号" min-width="150"/>
      <el-table-column fixed="right" prop="operation" label="操作" min-width="160">
        <template slot-scope="operatescope">
          <el-button size="mini" type="primary" @click="openRoom(operatescope.row.sqlid)">编辑</el-button>
          <el-button size="mini" type="danger" :disabled="operatescope.row.status=='X'||form.history"
                     :loading="operatescope.row.cancelLoading" @click="handleCancel(operatescope.row)">取消
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="block">
      <el-pagination
        background
        :current-page.sync="form.pages.currentpage"
        :page-size="form.pages.pagesize"
        layout="sizes,total, prev, pager, next, jumper"
        :total="form.pages.total"
        style="margin-top: 10px;float: right;"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <RoomrsDetailCol :history="form.history" :rsid="rsid" :visible.sync="showRoomrs" @refresh="closeRoom"/>

  </div>
</template>

<script>
    import {roomrs_list, cancel_roomrs} from "@/api/order/order";
    import {select_data} from "@/api/common/custom-data";
    import {select_res} from "@/utils/select_data_res";
    import {getRoomRsStatus} from "@/utils/status-format";
    import RoomrsDetailCol from '@/views/order/room/roomrsdetailcol';

    export default {
        name: "RoomrsList",
        components: {RoomrsDetailCol},
        props: {
            colno: {type: String, default: ''},
            profileid: {type: String, default: ''},
            custom: {type: String, default: ''},//不为空可以自定义搜索框
            summary: {type: String, default: ''},//不为空可以进行统计
            cancel:{ type: Boolean,default: false},
            history:{ type: Boolean,default: false},
            roomtype: {type: String, default: ''},//在Block预留那边用到
            block: {type: String, default: ''},//在Block预留那边用到
            startdate: {type: String, default: ''},//在Block预留那边用到
            enddate: {type: String, default: ''},//在Block预留那边用到  范围<=startdate & >=enddate
            inhouse:{ type: Boolean,default: false},
            subchanneltype:{ type: String,default: ''}
        },
        data() {
            return {
                form: {
                    arrfrom: new Date(),
                    arrto: null,
                    deptfrom: null,
                    deptto: null,
                    guestname: '',
                    orderno: '',
                    colno: this.colno,//增加综合预定号
                    profileid: this.profileid,//增加档案号
                    //status:'A',
                    status:['R','C','L'],
                    phone:'',
                    hotel:'',
                    roomtype:'',
                    block:'',
                    history: this.history,
                    inhouse: this.inhouse,
                    subchanneltype: this.subchanneltype,
                    pages:{total:0, currentpage:1, pagesize:20, sortname:'arrdate', sortorder:'ascending'}
                },
                tabledata: [],
                hotels:[],
                rsid: 0,
                showRoomrs: false,
                showRateQuery: false,
                showCti:false,
                loading:false, showAdvance:false
            }
        },
        watch: {
            colno: function (val) {
                this.form.colno = val;
            },
            profileid: function(val){
              this.form.profileid = val;
              if(val!=null && val.length>0){
                // this.form.status = 'A';
                this.form.status = ['R','C','L','N'];
                this.form.arrfrom = null;
              }
            },
            cancel: function(val){
              if(this.profileid.length>0){//是否包含取消这个只有在档案里用到
                if(val){
                  // this.form.status = 'A';
                  this.form.status = [];
                }else{
                  // this.form.status = ',C,L,N';
                  this.form.status = ['R','C','L','N'];
                }
              }
            },
            history: function(val){ this.form.history = val; },
            inhouse: function(val){ this.form.inhouse = val; },
            subchanneltype: function(val){ this.form.subchanneltype = val; },
        },
        mounted: function () {
          if(this.custom==''){
            this.$refs.bookingidinput.focus();
            select_data({"keyname": "hotel"}).then(response => {
              this.hotels = select_res(response.data,"hotel");
            });
          }
          this.loadTable();
        },
        methods: {
            statusFormatter(val) {
                return getRoomRsStatus(val);
            },
            refreshTable(){
                this.form.pages.currentpage = 1;
                this.loadTable();
            },
            changeHistory(){
              if(this.form.history){
                this.form.arrfrom = null;
              }else{
                this.form.arrfrom = new Date();
              }
              this.refreshTable();
            },
            loadTable() {
                this.initGetData();//初始化查询数据
                this.loading = true;
                roomrs_list(this.form).then(response => {
                    this.tabledata = response.data.records;
                    this.form.pages.total = response.data.total;
                }).finally(() => { this.loading = false;})
            },
            tableSummary(param) {
              const { columns, data } = param;
              const sums = [];
              columns.forEach((column, index) => {
                if (index === 0) {
                  sums[index] = '合计';
                  return;
                }
                if (column.property=='anz'||column.property=='totalprice') {
                  const values = data.map(function(item){
                    if(item['status']!='X'){
                      return Number(item[column.property]);
                    }else{
                      return 0;
                    }
                  });
                  sums[index] = values.reduce((prev, curr) => {
                    const value = Number(curr);
                    if (!isNaN(value)) {
                      return prev + curr;
                    } else {
                      return prev;
                    }
                  }, 0);
                  if (column.property=='totalprice') {//总价列合计保留两位小数
                    sums[index] = sums[index].toFixed(2);
                  }
                }
              });
              return sums;
            },
            tableRowClassName({row}){
              if(row.status==='X'){
                return 'cancel-row';
              }else if(row.status==='C'){
                return 'start-row';
              }else if(row.status==='L'){
                return 'finish-row';
              }
              return null;
            },
            handleSortChange(column){
              this.form.pages.sortname = column.prop;
              this.form.pages.sortorder = column.order;
              this.loadTable();
            },
            initGetData(){
                if(this.block!=null && this.block.length>0){//锁房这边默认的搜索条件
                  this.form.arrto = this.startdate;
                  this.form.deptto = this.enddate;
                  if(this.startdate!=null){
                    this.form.arrfrom = null;
                    this.form.deptfrom = null;
                  }
                  this.form.roomtype = this.roomtype;
                  this.form.block = this.block;
                  this.form.status = [];
                }
                if((this.block!=null && this.block.length>0)||(this.colno!=null && this.colno.length>0)){//如果是锁房或综合预定界面 按房间号和综合预定号排序
                    this.form.pages.sortname = 'roomno,colno';
                }
            },
            handleSizeChange(val) {
                this.form.pages.pagesize = val;
                this.refreshTable();
            },
            handleCurrentChange(val) {
                this.form.pages.currentpage = val;
                this.loadTable();
            },
            openRoom(id) {
                this.rsid = id;
                this.showRoomrs = true;
            },
            closeRoom() {
              if(!this.form.history){
                this.refreshTable();
                // this.$emit('refreshCol');
              }
            },
            handleCancel(row) {
                let deleteform = {sqlid: row.sqlid};
                this.$confirm('确定取消?', '提示', { confirmButtonText: '确定', cancelButtonText: '取消' }).then(() => {
                  this.$set(row, 'cancelLoading', true);
                  cancel_roomrs(deleteform).then(() => {
                    this.$message({ message: '取消成功!', type: 'success'});
                    this.loadTable();
                    this.$emit('refreshCol');
                  }).finally(()=>{
                    this.$set(row, 'cancelLoading', false);
                  });
                }).catch(() => {});
            }
        }
    }
</script>

<style>
  .el-table .cancel-row {
    background: #FFEBEE;
  }
  .el-table .finish-row {
    background: #E8F5E9;
  }
  .el-table .start-row {
    background: #E3F2FD;
  }
</style>
