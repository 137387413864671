import request from '@/utils/request'

export function index_data(data) {
    return request({
        url: '/api/statistic/index_data',
        method: 'post',
        data
    })
}

// export function availrooms_data(data) {
//     return request({
//         url: '/api/statistic/availrooms_data',
//         method: 'post',
//         data
//     })
// }

export function sync_availrooms(data) {
    return request({
        url: '/api/statistic/sync_availrooms',
        method: 'post',
        data
    })
}

export function room_status_tabel(data) {
    return request({
        url: '/api/statistic/room_status_tabel',
        method: 'post',
        data
    })
}
// export function room_status_tabel_header(data) {
//     return request({
//         url: '/api/statistic/room_status_tabel_header',
//         method: 'post',
//         data
//     })
// }
//
// export function room_status_tabel_data(data) {
//     return request({
//         url: '/api/statistic/room_status_tabel_data',
//         method: 'post',
//         data
//     })
// }

export function room_status_detail(data) {
    return request({
        url: '/api/statistic/room_status_detail',
        method: 'post',
        data
    })
}

export function index_resdetail_data() {
    return request({
        url: '/api/statistic/index_resdetail_data',
        method: 'post'
    })
}

export function index_pricedetail_data() {
    return request({
        url: '/api/statistic/index_pricedetail_data',
        method: 'post'
    })
}

export function hotelpoint_data(data) {
    return request({
        url: '/api/statistic/hotelpoint_data',
        method: 'post',
        data
    })
}

export function index_hotelhistory_data(data) {
    return request({
        url: '/api/statistic/index_hotelhistory_data',
        method: 'post',
        data
    })
}

export function index_ticket_data(data) {
    return request({
        url: '/api/statistic/index_ticket_data',
        method: 'post',
        data
    })
}

export function index_hotel_data(data) {
    return request({
        url: '/api/statistic/index_hotel_data',
        method: 'post',
        data
    })
}

export function customer_analysis_data(data) {
    return request({
        url: '/api/statistic/customer_analysis_data',
        method: 'post',
        data
    })
}